import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  Select,
  FormControl,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import { MapBrazil } from "react-brazil-map";
import Map from "components/Map/Map";
import BingMapsReact from "bingmaps-react";
import {
  SINAIS_DE_MERACDO_CNES,
  SINAIS_DE_MERACDO_RAIS,
  GET_MUNICIPIOS,
} from "queries";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.pluz.app",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const SinaisDeMercadoTab3 = (props) => {
  const { profession, ufs } = props;

  const [distritc, setDistrict] = useState("");
  const [indicatorColorMap, setIndicatorColorMap] = useState("eti");
  const textColor = useColorModeValue("gray.700", "white");
  const [sinaisDeMercado, setSinaisDeMercado] = useState(null);
  const [ufDefault, setUfDefault] = useState("");
  const [municipioCodSelected, setMunicipioCodSelected] = useState("");
  const [latLong, setLatLong] = useState("");
  const [areaSize, setAreaSize] = useState("");
  const [municipios, setMunicipios] = useState([]);

  const indicatorsColorMap = [
    { k: "eti", v: "N. profissionais em ETI" },
    { k: "razao_1000_hab", v: "Razão por 1.000 hab." },
    { k: "num_empregos_formais", v: "N. de empregos formais" },
    { k: "salario_medio_por_hora", v: "Remuneração média por hora" },
    { k: "participacao_feminina", v: "Participação feminina" },
  ];

  useEffect(async () => {
    var sinaisDeMercadoCnesStart = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          year: "2024",
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;

    var sinaisDeMercadoRaisStart = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          year: "2023",
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;

    var sinaisDeMercadoStart = {
      ...sinaisDeMercadoCnesStart,
      ...sinaisDeMercadoRaisStart,
    };

    setSinaisDeMercado(sinaisDeMercadoStart);
  }, [profession.code]);

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "20px" }}>
      <Grid>
        <Card display="flex" justify="center" align="center" minH="0px">
          <div className="subtitle">Selecione o indicador para o mapa:</div>
          <Select
            variant="main"
            placeholder="- Selecione -"
            color="gray.400"
            fontSize={16}
            onChange={(i) => {
              setIndicatorColorMap(i.target.value);
            }}
            defaultValue={indicatorColorMap}
          >
            {indicatorsColorMap.map((indicatorsColorMap_item) => (
              <option value={indicatorsColorMap_item.k}>
                {indicatorsColorMap_item.v}
              </option>
            ))}
          </Select>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
            gap="20px"
          >
            <div>
              <div className="subtitle">Selecione uma UF:</div>
              <FormControl>
                <Select
                  variant="main"
                  placeholder="Selecione uma UF"
                  color="gray.400"
                  fontSize={16}
                  onChange={async (i) => {
                    setUfDefault(i.target.value);
                    let getMunicipios = (
                      await clientApi.query({
                        variables: {
                          uf: i.target.value,
                        },
                        query: GET_MUNICIPIOS,
                      })
                    ).data.getMunicipios.municipios;
                    let municipiosByCod = [];
                    getMunicipios.map((municio) => {
                      municipiosByCod[municio.cod_ibge7] = municio;
                    });
                    // console.log("municipiosByCod", municipiosByCod);

                    setMunicipios(municipiosByCod);
                    setLatLong("");
                    setAreaSize("");
                  }}
                  defaultValue={ufDefault}
                >
                  <option value={""}>Todas UFs</option>
                  {ufs.map((uf) => (
                    <option value={uf.name} key={uf.code}>
                      {uf.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div>
              <div className="subtitle">Selecione um município:</div>
              {ufDefault === "" && (
                <div className="txt-left">
                  Selecione uma UF para selecionar o município
                </div>
              )}
              {ufDefault !== "" && (
                <FormControl>
                  <Select
                    variant="main"
                    placeholder="Selecione um município"
                    color="gray.400"
                    fontSize={16}
                    onChange={(i) => {
                      let municipioSelected = municipios[i.target.value];
                      // console.log("municipioSelected", municipioSelected);
                      setLatLong(municipioSelected.num_lat_long);
                      setAreaSize(
                        parseInt(municipioSelected.num_area.replace(",", ""))
                      );
                      setMunicipioCodSelected(municipioSelected.cod_ibge7);
                    }}
                    defaultValue={ufDefault}
                  >
                    <option value={""}>Todos municípios</option>
                    {municipios.map((municipio) => (
                      <option
                        value={municipio.cod_ibge7}
                        key={municipio.cod_ibge7}
                      >
                        {municipio.nom_mun_acent}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
          </Grid>
        </Card>
      </Grid>

      <Grid templateColumns={{ sm: "1fr", lg: "4fr 1fr" }} gap="20px">
        <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
          {/* <BingMapsReact
            bingMapsKey="At22jzFiq5bsqM0BNF4Mn8H81lNeq5BwbsIxM54Hg6EK7HWtmrB1CvvH13dNGt_h"
            height="500px"
            width="500px"
          /> */}
          <Card display="flex" justify="center" align="center" minH="0px">
            <Map
              profession={profession}
              indicatorColorMap={indicatorColorMap}
              ufDefault={ufDefault}
              municipioCodSelected={municipioCodSelected}
              latLong={latLong}
              areaSize={areaSize}
            />
          </Card>
        </Grid>

        <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
          <Flex direction="column" justify="space-between" h="100%">
            <Grid
              templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
              gap="20px"
            >
              {sinaisDeMercado !== null && (
                <>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        Dados Brasil
                      </Text>
                    </Flex>
                  </Card>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        {sinaisDeMercado.eti_format}
                      </Text>
                      <Text
                        color="#333"
                        fontSize="md"
                        fontWeight="bold"
                        title="Número de profissionais em ETI ocupados em estabelecimentos de saúde"
                      >
                        N. profissionais em ETI
                      </Text>
                      <Text color="gray.400" fontSize="xs" fontWeight="bold">
                        Dados CNES 2024
                      </Text>
                    </Flex>
                  </Card>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        {sinaisDeMercado.razao_1000_hab_format}
                      </Text>
                      <Text
                        color="#333"
                        fontSize="md"
                        fontWeight="bold"
                        title="Razão do número de profissionais em ETI por 1.000 habitantes"
                      >
                        Razão por 1.000 hab.
                      </Text>
                      <Text color="gray.400" fontSize="xs" fontWeight="bold">
                        Dados CNES 2024
                      </Text>
                    </Flex>
                  </Card>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        {sinaisDeMercado.num_empregos_formais_format}
                      </Text>
                      <Text
                        color="#333"
                        fontSize="md"
                        fontWeight="bold"
                        title="Número de vínculos formais de emprego ativos em 31/12"
                      >
                        N. de empregos formais
                      </Text>
                      <Text color="gray.400" fontSize="xs" fontWeight="bold">
                        Dados RAIS 2022
                      </Text>
                    </Flex>
                  </Card>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        <Text as="span" fontSize="sm">
                          R$
                        </Text>{" "}
                        {sinaisDeMercado.salario_medio_por_hora_format}
                      </Text>
                      <Text color="#333" fontSize="md" fontWeight="bold">
                        Remuneração média por hora
                      </Text>
                      <Text color="gray.400" fontSize="xs" fontWeight="bold">
                        Dados RAIS 2022
                      </Text>
                    </Flex>
                  </Card>
                  <Card
                    display="flex"
                    justify="center"
                    align="center"
                    minH="0px"
                  >
                    <Flex direction="column">
                      <Text color="#333" fontSize="3xl" fontWeight="bold">
                        {sinaisDeMercado.participacao_feminina_format}
                      </Text>
                      <Text color="#333" fontSize="md" fontWeight="bold">
                        Participação feminina
                      </Text>
                      <Text color="gray.400" fontSize="xs" fontWeight="bold">
                        Dados RAIS 2022
                      </Text>
                    </Flex>
                  </Card>
                </>
              )}
            </Grid>
          </Flex>
        </Grid>
      </Grid>
    </Flex>
  );
};

export default SinaisDeMercadoTab3;
