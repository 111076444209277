import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  FormControl,
  Select,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import LineBarChart from "components/Charts/LineBarChart";
import {
  profissionaisEtiData,
  profissionaisEtiOptions,
  salarioHoraData,
  salarioHoraOptions,
} from "variables/charts";
import ReactApexChart from "react-apexcharts";
import {
  GET_INEP_GERAL_COLLECTION,
  GET_INEP_REGIOES,
  GET_INEP_UFS,
  GET_INEP_MUNICIPIOS,
  COUNT_TIPO_ADMISSAO,
  GET_INEP_COURSES,
} from "queries";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { set } from "date-fns";

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.pluz.app",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const nunsChart6 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15];

const InepTab2 = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const textColor = useColorModeValue("gray.700", "white");

  const [titleChart4, setTitleChart4] = useState("");
  const [labelsChart4, setLabelsChart4] = useState([]);
  const [taxaChart4, setTaxaChart4] = useState([]);
  const [qtIngChart4, setQtIngChart4] = useState([]);
  const [qtConcChart4, setQtConcChart4] = useState([]);

  const [inepCourses, setInepCourses] = useState([]);
  const [inepCourseCod, setInepCourseCod] = useState("TOTAL");
  const [inepCourse, setInepCourse] = useState(null);
  const [inepGeralCollection, setInepGeralCollection] = useState({
    y2010: null,
    y2011: null,
    y2012: null,
    y2013: null,
    y2014: null,
    y2015: null,
    y2016: null,
    y2017: null,
    y2018: null,
    y2019: null,
    y2020: null,
    y2021: null,
    y2022: null,
  });

  const [geoTitle, setGeoTitle] = useState("Brasil, ");

  const [inepRegioes, setInepRegioes] = useState([]);
  const [inepRegiao, setInepRegiao] = useState(0);

  const [inepUfs, setInepUfs] = useState([]);
  const [inepUf, setInepUf] = useState(0);

  const [inepMunicipios, setInepMunicipios] = useState([]);
  const [inepMunicipio, setInepMunicipio] = useState(0);

  const [tpRede, setTpRede] = useState(0);
  const [tpCategoriaAdministrativa, setTpCategoriaAdministrativa] = useState(
    "total"
  );
  const [tpModalidadeEnsino, setTpModalidadeEnsino] = useState(0);

  const [countTipoAdmissao2022, setCountTipoAdmissao2022] = useState(0);
  const [countTipoAdmissao2021, setCountTipoAdmissao2021] = useState(0);
  const [countTipoAdmissao2020, setCountTipoAdmissao2020] = useState(0);
  const [countTipoAdmissao2019, setCountTipoAdmissao2019] = useState(0);
  const [countTipoAdmissao2018, setCountTipoAdmissao2018] = useState(0);
  const [countTipoAdmissao2017, setCountTipoAdmissao2017] = useState(0);
  const [countTipoAdmissao2016, setCountTipoAdmissao2016] = useState(0);
  const [countTipoAdmissao2015, setCountTipoAdmissao2015] = useState(0);
  const [countTipoAdmissao2014, setCountTipoAdmissao2014] = useState(0);
  const [countTipoAdmissao2013, setCountTipoAdmissao2013] = useState(0);
  const [countTipoAdmissao2012, setCountTipoAdmissao2012] = useState(0);
  const [countTipoAdmissao2011, setCountTipoAdmissao2011] = useState(0);

  const getTitleByCode = (array, code) => {
    const item = array.find((item) => item.cod === code);
    return item ? item.title : "";
  };

  const _setInepRegiao = async (cod) => {
    if (!cod) {
      setGeoTitle("Brasil, ");
    } else {
      let regiaoTitle = getTitleByCode(inepRegioes, cod);
      setGeoTitle(regiaoTitle + ", ");
    }

    setInepRegiao(cod);
    setInepUfs([]);
    let getInepUfsStart = (
      await clientApi.query({
        query: GET_INEP_UFS,
        variables: {
          cod_regiao: cod,
        },
      })
    ).data.getInepUfs;
    console.log("getInepUfsStart", getInepUfsStart);
    setInepUfs(getInepUfsStart);
  };

  const _setInepUf = async (cod) => {
    if (!cod) {
      setInepUf(0);
      let regiaoTitle = getTitleByCode(inepRegioes, inepRegiao);
      setGeoTitle(regiaoTitle + ", ");
    } else {
      setInepUf(cod);
      let ufTitle = getTitleByCode(inepUfs, cod);
      setGeoTitle(ufTitle + ", ");
      setInepMunicipios([]);
      let getInepMunicipiosStart = (
        await clientApi.query({
          query: GET_INEP_MUNICIPIOS,
          variables: {
            cod_uf: cod,
          },
        })
      ).data.getInepMunicipios;
      setInepMunicipios(getInepMunicipiosStart);
    }
  };

  const _setInepMunicipio = async (cod) => {
    setInepMunicipio(cod);
    if (!cod) {
      setInepMunicipio(0);
      let ufTitle = getTitleByCode(inepUfs, inepUf);
      setGeoTitle(ufTitle + ", ");
    } else {
      let municipioTitle = getTitleByCode(inepMunicipios, cod);
      setGeoTitle(municipioTitle + ", ");
    }
  };

  const dataChart = {
    series: [
      {
        name: "IES",
        type: "column",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : inepGeralCollection.y2010.count_co_ies,
          inepGeralCollection.y2011 === null
            ? 0
            : inepGeralCollection.y2011.count_co_ies,
          inepGeralCollection.y2012 === null
            ? 0
            : inepGeralCollection.y2012.count_co_ies,
          inepGeralCollection.y2013 === null
            ? 0
            : inepGeralCollection.y2013.count_co_ies,
          inepGeralCollection.y2014 === null
            ? 0
            : inepGeralCollection.y2014.count_co_ies,
          inepGeralCollection.y2015 === null
            ? 0
            : inepGeralCollection.y2015.count_co_ies,
          inepGeralCollection.y2016 === null
            ? 0
            : inepGeralCollection.y2016.count_co_ies,
          inepGeralCollection.y2017 === null
            ? 0
            : inepGeralCollection.y2017.count_co_ies,
          inepGeralCollection.y2018 === null
            ? 0
            : inepGeralCollection.y2018.count_co_ies,
          inepGeralCollection.y2019 === null
            ? 0
            : inepGeralCollection.y2019.count_co_ies,
          inepGeralCollection.y2020 === null
            ? 0
            : inepGeralCollection.y2020.count_co_ies,
          inepGeralCollection.y2021 === null
            ? 0
            : inepGeralCollection.y2021.count_co_ies,
          inepGeralCollection.y2022 === null
            ? 0
            : inepGeralCollection.y2022.count_co_ies,
        ],
      },
      {
        name: "Cursos",
        type: "column",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : inepGeralCollection.y2010.qt_curso,
          inepGeralCollection.y2011 === null
            ? 0
            : inepGeralCollection.y2011.qt_curso,
          inepGeralCollection.y2012 === null
            ? 0
            : inepGeralCollection.y2012.qt_curso,
          inepGeralCollection.y2013 === null
            ? 0
            : inepGeralCollection.y2013.qt_curso,
          inepGeralCollection.y2014 === null
            ? 0
            : inepGeralCollection.y2014.qt_curso,
          inepGeralCollection.y2015 === null
            ? 0
            : inepGeralCollection.y2015.qt_curso,
          inepGeralCollection.y2016 === null
            ? 0
            : inepGeralCollection.y2016.qt_curso,
          inepGeralCollection.y2017 === null
            ? 0
            : inepGeralCollection.y2017.qt_curso,
          inepGeralCollection.y2018 === null
            ? 0
            : inepGeralCollection.y2018.qt_curso,
          inepGeralCollection.y2019 === null
            ? 0
            : inepGeralCollection.y2019.qt_curso,
          inepGeralCollection.y2020 === null
            ? 0
            : inepGeralCollection.y2020.qt_curso,
          inepGeralCollection.y2021 === null
            ? 0
            : inepGeralCollection.y2021.qt_curso,
          inepGeralCollection.y2022 === null
            ? 0
            : inepGeralCollection.y2022.qt_curso,
        ],
      },
      {
        name: "Vagas",
        type: "line",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : inepGeralCollection.y2010.qt_vg_total,
          inepGeralCollection.y2011 === null
            ? 0
            : inepGeralCollection.y2011.qt_vg_total,
          inepGeralCollection.y2012 === null
            ? 0
            : inepGeralCollection.y2012.qt_vg_total,
          inepGeralCollection.y2013 === null
            ? 0
            : inepGeralCollection.y2013.qt_vg_total,
          inepGeralCollection.y2014 === null
            ? 0
            : inepGeralCollection.y2014.qt_vg_total,
          inepGeralCollection.y2015 === null
            ? 0
            : inepGeralCollection.y2015.qt_vg_total,
          inepGeralCollection.y2016 === null
            ? 0
            : inepGeralCollection.y2016.qt_vg_total,
          inepGeralCollection.y2017 === null
            ? 0
            : inepGeralCollection.y2017.qt_vg_total,
          inepGeralCollection.y2018 === null
            ? 0
            : inepGeralCollection.y2018.qt_vg_total,
          inepGeralCollection.y2019 === null
            ? 0
            : inepGeralCollection.y2019.qt_vg_total,
          inepGeralCollection.y2020 === null
            ? 0
            : inepGeralCollection.y2020.qt_vg_total,
          inepGeralCollection.y2021 === null
            ? 0
            : inepGeralCollection.y2021.qt_vg_total,
          inepGeralCollection.y2022 === null
            ? 0
            : inepGeralCollection.y2022.qt_vg_total,
        ],
      },
      {
        name: "Ingressantes",
        type: "line",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : inepGeralCollection.y2010.qt_ing,
          inepGeralCollection.y2011 === null
            ? 0
            : inepGeralCollection.y2011.qt_ing,
          inepGeralCollection.y2012 === null
            ? 0
            : inepGeralCollection.y2012.qt_ing,
          inepGeralCollection.y2013 === null
            ? 0
            : inepGeralCollection.y2013.qt_ing,
          inepGeralCollection.y2014 === null
            ? 0
            : inepGeralCollection.y2014.qt_ing,
          inepGeralCollection.y2015 === null
            ? 0
            : inepGeralCollection.y2015.qt_ing,
          inepGeralCollection.y2016 === null
            ? 0
            : inepGeralCollection.y2016.qt_ing,
          inepGeralCollection.y2017 === null
            ? 0
            : inepGeralCollection.y2017.qt_ing,
          inepGeralCollection.y2018 === null
            ? 0
            : inepGeralCollection.y2018.qt_ing,
          inepGeralCollection.y2019 === null
            ? 0
            : inepGeralCollection.y2019.qt_ing,
          inepGeralCollection.y2020 === null
            ? 0
            : inepGeralCollection.y2020.qt_ing,
          inepGeralCollection.y2021 === null
            ? 0
            : inepGeralCollection.y2021.qt_ing,
          inepGeralCollection.y2022 === null
            ? 0
            : inepGeralCollection.y2022.qt_ing,
        ],
      },
      {
        name: "Matrículas",
        type: "line",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : inepGeralCollection.y2010.qt_mat,
          inepGeralCollection.y2011 === null
            ? 0
            : inepGeralCollection.y2011.qt_mat,
          inepGeralCollection.y2012 === null
            ? 0
            : inepGeralCollection.y2012.qt_mat,
          inepGeralCollection.y2013 === null
            ? 0
            : inepGeralCollection.y2013.qt_mat,
          inepGeralCollection.y2014 === null
            ? 0
            : inepGeralCollection.y2014.qt_mat,
          inepGeralCollection.y2015 === null
            ? 0
            : inepGeralCollection.y2015.qt_mat,
          inepGeralCollection.y2016 === null
            ? 0
            : inepGeralCollection.y2016.qt_mat,
          inepGeralCollection.y2017 === null
            ? 0
            : inepGeralCollection.y2017.qt_mat,
          inepGeralCollection.y2018 === null
            ? 0
            : inepGeralCollection.y2018.qt_mat,
          inepGeralCollection.y2019 === null
            ? 0
            : inepGeralCollection.y2019.qt_mat,
          inepGeralCollection.y2020 === null
            ? 0
            : inepGeralCollection.y2020.qt_mat,
          inepGeralCollection.y2021 === null
            ? 0
            : inepGeralCollection.y2021.qt_mat,
          inepGeralCollection.y2022 === null
            ? 0
            : inepGeralCollection.y2022.qt_mat,
        ],
      },
      {
        name: "Concluintes",
        type: "line",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : inepGeralCollection.y2010.qt_conc,
          inepGeralCollection.y2011 === null
            ? 0
            : inepGeralCollection.y2011.qt_conc,
          inepGeralCollection.y2012 === null
            ? 0
            : inepGeralCollection.y2012.qt_conc,
          inepGeralCollection.y2013 === null
            ? 0
            : inepGeralCollection.y2013.qt_conc,
          inepGeralCollection.y2014 === null
            ? 0
            : inepGeralCollection.y2014.qt_conc,
          inepGeralCollection.y2015 === null
            ? 0
            : inepGeralCollection.y2015.qt_conc,
          inepGeralCollection.y2016 === null
            ? 0
            : inepGeralCollection.y2016.qt_conc,
          inepGeralCollection.y2017 === null
            ? 0
            : inepGeralCollection.y2017.qt_conc,
          inepGeralCollection.y2018 === null
            ? 0
            : inepGeralCollection.y2018.qt_conc,
          inepGeralCollection.y2019 === null
            ? 0
            : inepGeralCollection.y2019.qt_conc,
          inepGeralCollection.y2020 === null
            ? 0
            : inepGeralCollection.y2020.qt_conc,
          inepGeralCollection.y2021 === null
            ? 0
            : inepGeralCollection.y2021.qt_conc,
          inepGeralCollection.y2022 === null
            ? 0
            : inepGeralCollection.y2022.qt_conc,
        ],
      },
    ],
    options: {
      colors: [
        "#008ffb",
        "#feb019",
        "#00e396",
        "#9683d9",
        "#00ffff",
        "#ebff8e",
      ],
      chart: {
        height: 350,
        type: "line",
      },
      title: {
        text: `Gráfico 1 – Evolução do número IES, cursos, vagas, ingressantes, matrículas e concluintes dos cursos por Ano - ${geoTitle} 2010 – 2022`,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
      ],
      xaxis: {
        //type: 'datetime'
        type: "category",
      },
      // yaxis: [
      //   {
      //     min: 0,
      //     title: {
      //       text: "N. de IES e de Cursos",
      //     },
      //   },
      //   {
      //     opposite: true,
      //     min: 0,
      //     title: {
      //       text: "N. de Vagas, Ingressatnes, Matriculas e Concluintes",
      //     },
      //   },
      // ],
      yaxis: [
        {
          seriesName: "IES",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Colunas",
          },
        },
        {
          seriesName: "IES",
          show: false,
        },
        {
          opposite: true,
          seriesName: "Vagas",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Linhas",
          },
        },
        {
          seriesName: "Vagas",
          show: false,
        },
        {
          seriesName: "Vagas",
          show: false,
        },
        {
          seriesName: "Vagas",
          show: false,
        },
      ],
    },
  };

  const dataChart2 = {
    series: [
      {
        name: "Preenchimento de vagas",
        type: "column",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : parseFloat(inepGeralCollection.y2010.taxaAproveitamento).toFixed(
                2
              ),

          inepGeralCollection.y2011 === null
            ? 0
            : parseFloat(inepGeralCollection.y2011.taxaAproveitamento).toFixed(
                2
              ),
          inepGeralCollection.y2012 === null
            ? 0
            : parseFloat(inepGeralCollection.y2012.taxaAproveitamento).toFixed(
                2
              ),
          inepGeralCollection.y2013 === null
            ? 0
            : parseFloat(inepGeralCollection.y2013.taxaAproveitamento).toFixed(
                2
              ),
          inepGeralCollection.y2014 === null
            ? 0
            : parseFloat(inepGeralCollection.y2014.taxaAproveitamento).toFixed(
                2
              ),
          inepGeralCollection.y2015 === null
            ? 0
            : parseFloat(inepGeralCollection.y2015.taxaAproveitamento).toFixed(
                2
              ),
          inepGeralCollection.y2016 === null
            ? 0
            : parseFloat(inepGeralCollection.y2016.taxaAproveitamento).toFixed(
                2
              ),
          inepGeralCollection.y2017 === null
            ? 0
            : parseFloat(inepGeralCollection.y2017.taxaAproveitamento).toFixed(
                2
              ),
          inepGeralCollection.y2018 === null
            ? 0
            : parseFloat(inepGeralCollection.y2018.taxaAproveitamento).toFixed(
                2
              ),
          inepGeralCollection.y2019 === null
            ? 0
            : parseFloat(inepGeralCollection.y2019.taxaAproveitamento).toFixed(
                2
              ),
          inepGeralCollection.y2020 === null
            ? 0
            : parseFloat(inepGeralCollection.y2020.taxaAproveitamento).toFixed(
                2
              ),
          inepGeralCollection.y2021 === null
            ? 0
            : parseFloat(inepGeralCollection.y2021.taxaAproveitamento).toFixed(
                2
              ),
          inepGeralCollection.y2022 === null
            ? 0
            : parseFloat(inepGeralCollection.y2022.taxaAproveitamento).toFixed(
                2
              ),
        ],
      },
      {
        name: "Vagas",
        type: "line",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : parseInt(inepGeralCollection.y2010.qt_vg_total),
          inepGeralCollection.y2011 === null
            ? 0
            : parseInt(inepGeralCollection.y2011.qt_vg_total),
          inepGeralCollection.y2012 === null
            ? 0
            : parseInt(inepGeralCollection.y2012.qt_vg_total),
          inepGeralCollection.y2013 === null
            ? 0
            : parseInt(inepGeralCollection.y2013.qt_vg_total),
          inepGeralCollection.y2014 === null
            ? 0
            : parseInt(inepGeralCollection.y2014.qt_vg_total),
          inepGeralCollection.y2015 === null
            ? 0
            : parseInt(inepGeralCollection.y2015.qt_vg_total),
          inepGeralCollection.y2016 === null
            ? 0
            : parseInt(inepGeralCollection.y2016.qt_vg_total),
          inepGeralCollection.y2017 === null
            ? 0
            : parseInt(inepGeralCollection.y2017.qt_vg_total),
          inepGeralCollection.y2018 === null
            ? 0
            : parseInt(inepGeralCollection.y2018.qt_vg_total),
          inepGeralCollection.y2019 === null
            ? 0
            : parseInt(inepGeralCollection.y2019.qt_vg_total),
          inepGeralCollection.y2020 === null
            ? 0
            : parseInt(inepGeralCollection.y2020.qt_vg_total),
          inepGeralCollection.y2021 === null
            ? 0
            : parseInt(inepGeralCollection.y2021.qt_vg_total),
          inepGeralCollection.y2022 === null
            ? 0
            : parseInt(inepGeralCollection.y2022.qt_vg_total),
        ],
      },
      {
        name: "Ingressantes",
        type: "line",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : parseInt(inepGeralCollection.y2010.qt_ing),
          inepGeralCollection.y2011 === null
            ? 0
            : parseInt(inepGeralCollection.y2011.qt_ing),
          inepGeralCollection.y2012 === null
            ? 0
            : parseInt(inepGeralCollection.y2012.qt_ing),
          inepGeralCollection.y2013 === null
            ? 0
            : parseInt(inepGeralCollection.y2013.qt_ing),
          inepGeralCollection.y2014 === null
            ? 0
            : parseInt(inepGeralCollection.y2014.qt_ing),
          inepGeralCollection.y2015 === null
            ? 0
            : parseInt(inepGeralCollection.y2015.qt_ing),
          inepGeralCollection.y2016 === null
            ? 0
            : parseInt(inepGeralCollection.y2016.qt_ing),
          inepGeralCollection.y2017 === null
            ? 0
            : parseInt(inepGeralCollection.y2017.qt_ing),
          inepGeralCollection.y2018 === null
            ? 0
            : parseInt(inepGeralCollection.y2018.qt_ing),
          inepGeralCollection.y2019 === null
            ? 0
            : parseInt(inepGeralCollection.y2019.qt_ing),
          inepGeralCollection.y2020 === null
            ? 0
            : parseInt(inepGeralCollection.y2020.qt_ing),
          inepGeralCollection.y2021 === null
            ? 0
            : parseInt(inepGeralCollection.y2021.qt_ing),
          inepGeralCollection.y2022 === null
            ? 0
            : parseInt(inepGeralCollection.y2022.qt_ing),
        ],
      },
    ],
    options: {
      colors: [
        "#008ffb",
        "#00e396",
        "#9683d9",
        "#feb019",
        "#00ffff",
        "#ff7b8e",
      ],
      chart: {
        height: 350,
        type: "line",
      },
      title: {
        text: `Gráfico 2 - Evolução do número de vagas, ingressos e % de preenchimento de vagas* dos cursos. ${geoTitle} 2010 a 2022.`,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
      ],
      xaxis: {
        //type: 'datetime'
        type: "category",
      },

      yaxis: [
        {
          seriesName: "Preenchimento de vagas",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Colunas",
          },
        },
        {
          opposite: true,
          seriesName: "Vagas",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Linhas",
          },
        },
        {
          seriesName: "Vagas",
          show: false,
        },
      ],
    },
  };

  const dataChart3 = {
    series: [
      {
        name: "Razão Inscritos por vagas",
        type: "column",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : (
                inepGeralCollection.y2010.qt_inscrito_total /
                inepGeralCollection.y2010.qt_vg_total
              ).toFixed(2),

          inepGeralCollection.y2011 === null
            ? 0
            : (
                inepGeralCollection.y2011.qt_inscrito_total /
                inepGeralCollection.y2011.qt_vg_total
              ).toFixed(2),
          inepGeralCollection.y2012 === null
            ? 0
            : (
                inepGeralCollection.y2012.qt_inscrito_total /
                inepGeralCollection.y2012.qt_vg_total
              ).toFixed(2),
          inepGeralCollection.y2013 === null
            ? 0
            : (
                inepGeralCollection.y2013.qt_inscrito_total /
                inepGeralCollection.y2013.qt_vg_total
              ).toFixed(2),
          inepGeralCollection.y2014 === null
            ? 0
            : (
                inepGeralCollection.y2014.qt_inscrito_total /
                inepGeralCollection.y2014.qt_vg_total
              ).toFixed(2),
          inepGeralCollection.y2015 === null
            ? 0
            : (
                inepGeralCollection.y2015.qt_inscrito_total /
                inepGeralCollection.y2015.qt_vg_total
              ).toFixed(2),
          inepGeralCollection.y2016 === null
            ? 0
            : (
                inepGeralCollection.y2016.qt_inscrito_total /
                inepGeralCollection.y2016.qt_vg_total
              ).toFixed(2),
          inepGeralCollection.y2017 === null
            ? 0
            : (
                inepGeralCollection.y2017.qt_inscrito_total /
                inepGeralCollection.y2017.qt_vg_total
              ).toFixed(2),
          inepGeralCollection.y2018 === null
            ? 0
            : (
                inepGeralCollection.y2018.qt_inscrito_total /
                inepGeralCollection.y2018.qt_vg_total
              ).toFixed(2),
          inepGeralCollection.y2019 === null
            ? 0
            : (
                inepGeralCollection.y2019.qt_inscrito_total /
                inepGeralCollection.y2019.qt_vg_total
              ).toFixed(2),
          inepGeralCollection.y2020 === null
            ? 0
            : (
                inepGeralCollection.y2020.qt_inscrito_total /
                inepGeralCollection.y2020.qt_vg_total
              ).toFixed(2),
          inepGeralCollection.y2021 === null
            ? 0
            : (
                inepGeralCollection.y2021.qt_inscrito_total /
                inepGeralCollection.y2021.qt_vg_total
              ).toFixed(2),
          inepGeralCollection.y2022 === null
            ? 0
            : (
                inepGeralCollection.y2022.qt_inscrito_total /
                inepGeralCollection.y2022.qt_vg_total
              ).toFixed(2),
        ],
      },
      {
        name: "Inscritos",
        type: "line",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : inepGeralCollection.y2010.qt_inscrito_total,
          inepGeralCollection.y2011 === null
            ? 0
            : inepGeralCollection.y2011.qt_inscrito_total,
          inepGeralCollection.y2012 === null
            ? 0
            : inepGeralCollection.y2012.qt_inscrito_total,
          inepGeralCollection.y2013 === null
            ? 0
            : inepGeralCollection.y2013.qt_inscrito_total,
          inepGeralCollection.y2014 === null
            ? 0
            : inepGeralCollection.y2014.qt_inscrito_total,
          inepGeralCollection.y2015 === null
            ? 0
            : inepGeralCollection.y2015.qt_inscrito_total,
          inepGeralCollection.y2016 === null
            ? 0
            : inepGeralCollection.y2016.qt_inscrito_total,
          inepGeralCollection.y2017 === null
            ? 0
            : inepGeralCollection.y2017.qt_inscrito_total,
          inepGeralCollection.y2018 === null
            ? 0
            : inepGeralCollection.y2018.qt_inscrito_total,
          inepGeralCollection.y2019 === null
            ? 0
            : inepGeralCollection.y2019.qt_inscrito_total,
          inepGeralCollection.y2020 === null
            ? 0
            : inepGeralCollection.y2020.qt_inscrito_total,
          inepGeralCollection.y2021 === null
            ? 0
            : inepGeralCollection.y2021.qt_inscrito_total,
          inepGeralCollection.y2022 === null
            ? 0
            : inepGeralCollection.y2022.qt_inscrito_total,
        ],
      },
      {
        name: "Vagas",
        type: "line",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : inepGeralCollection.y2010.qt_vg_total,
          inepGeralCollection.y2011 === null
            ? 0
            : inepGeralCollection.y2011.qt_vg_total,
          inepGeralCollection.y2012 === null
            ? 0
            : inepGeralCollection.y2012.qt_vg_total,
          inepGeralCollection.y2013 === null
            ? 0
            : inepGeralCollection.y2013.qt_vg_total,
          inepGeralCollection.y2014 === null
            ? 0
            : inepGeralCollection.y2014.qt_vg_total,
          inepGeralCollection.y2015 === null
            ? 0
            : inepGeralCollection.y2015.qt_vg_total,
          inepGeralCollection.y2016 === null
            ? 0
            : inepGeralCollection.y2016.qt_vg_total,
          inepGeralCollection.y2017 === null
            ? 0
            : inepGeralCollection.y2017.qt_vg_total,
          inepGeralCollection.y2018 === null
            ? 0
            : inepGeralCollection.y2018.qt_vg_total,
          inepGeralCollection.y2019 === null
            ? 0
            : inepGeralCollection.y2019.qt_vg_total,
          inepGeralCollection.y2020 === null
            ? 0
            : inepGeralCollection.y2020.qt_vg_total,
          inepGeralCollection.y2021 === null
            ? 0
            : inepGeralCollection.y2021.qt_vg_total,
          inepGeralCollection.y2022 === null
            ? 0
            : inepGeralCollection.y2022.qt_vg_total,
        ],
      },
    ],
    options: {
      colors: [
        "#008ffb",
        "#9683d9",
        "#00e396",
        "#feb019",
        "#00ffff",
        "#ff7b8e",
      ],
      chart: {
        height: 350,
        type: "line",
      },
      title: {
        text: `Gráfico 3 - Evolução no nº de vagas, inscritos no vestibular, e razão de inscritos por vagas* nos cursos. ${geoTitle} 2010 a 2022.`,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
      ],
      xaxis: {
        //type: 'datetime'
        type: "category",
      },

      yaxis: [
        {
          seriesName: "Razão Inscritos por vagas",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Colunas",
          },
        },
        {
          opposite: true,
          seriesName: "Inscritos",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Linhas",
          },
        },
        {
          seriesName: "Inscritos",
          show: false,
        },
      ],
    },
  };

  const dataChart4 = {
    series: [
      {
        name: "% de Não Concluintes",
        type: "column",
        data: taxaChart4,
      },
      {
        name: "Concluintes",
        type: "line",
        data: qtConcChart4,
      },
      {
        name: "Ingressantes",
        type: "line",
        data: qtIngChart4,
      },
    ],
    options: {
      colors: [
        "#ff7b8e",
        "#ebff8e",
        "#9683d9",
        "#008ffb",
        "#00e396",
        "#feb019",
        "#00ffff",
      ],
      chart: {
        height: 350,
        type: "line",
      },
      title: {
        text: `Gráfico 4 - Evolução no nº de ingressos, de concluintes (%) de não concluintes no tempo previsto. ${geoTitle} ${titleChart4}.`,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: labelsChart4,
      xaxis: {
        //type: 'datetime'
        type: "category",
      },

      yaxis: [
        {
          seriesName: "% de Não Concluintes",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Colunas",
          },
        },
        {
          opposite: true,
          seriesName: "Concluintes",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Linhas",
          },
        },
        {
          seriesName: "Concluintes",
          show: false,
        },
      ],
    },
  };

  const dataChart5 = {
    series: [
      {
        name: "(%) Taxa de Abandono",
        type: "column",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : parseFloat(inepGeralCollection.y2010.taxaAbandono).toFixed(2),
          inepGeralCollection.y2011 === null
            ? 0
            : parseFloat(inepGeralCollection.y2011.taxaAbandono).toFixed(2),
          inepGeralCollection.y2012 === null
            ? 0
            : parseFloat(inepGeralCollection.y2012.taxaAbandono).toFixed(2),
          inepGeralCollection.y2013 === null
            ? 0
            : parseFloat(inepGeralCollection.y2013.taxaAbandono).toFixed(2),
          inepGeralCollection.y2014 === null
            ? 0
            : parseFloat(inepGeralCollection.y2014.taxaAbandono).toFixed(2),
          inepGeralCollection.y2015 === null
            ? 0
            : parseFloat(inepGeralCollection.y2015.taxaAbandono).toFixed(2),
          inepGeralCollection.y2016 === null
            ? 0
            : parseFloat(inepGeralCollection.y2016.taxaAbandono).toFixed(2),
          inepGeralCollection.y2017 === null
            ? 0
            : parseFloat(inepGeralCollection.y2017.taxaAbandono).toFixed(2),
          inepGeralCollection.y2018 === null
            ? 0
            : parseFloat(inepGeralCollection.y2018.taxaAbandono).toFixed(2),
          inepGeralCollection.y2019 === null
            ? 0
            : parseFloat(inepGeralCollection.y2019.taxaAbandono).toFixed(2),
          inepGeralCollection.y2020 === null
            ? 0
            : parseFloat(inepGeralCollection.y2020.taxaAbandono).toFixed(2),
          inepGeralCollection.y2021 === null
            ? 0
            : parseFloat(inepGeralCollection.y2021.taxaAbandono).toFixed(2),
          inepGeralCollection.y2022 === null
            ? 0
            : parseFloat(inepGeralCollection.y2022.taxaAbandono).toFixed(2),
        ],
      },
      {
        name: "Matrículas",
        type: "line",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : inepGeralCollection.y2010.qt_mat,
          inepGeralCollection.y2011 === null
            ? 0
            : inepGeralCollection.y2011.qt_mat,
          inepGeralCollection.y2012 === null
            ? 0
            : inepGeralCollection.y2012.qt_mat,
          inepGeralCollection.y2013 === null
            ? 0
            : inepGeralCollection.y2013.qt_mat,
          inepGeralCollection.y2014 === null
            ? 0
            : inepGeralCollection.y2014.qt_mat,
          inepGeralCollection.y2015 === null
            ? 0
            : inepGeralCollection.y2015.qt_mat,
          inepGeralCollection.y2016 === null
            ? 0
            : inepGeralCollection.y2016.qt_mat,
          inepGeralCollection.y2017 === null
            ? 0
            : inepGeralCollection.y2017.qt_mat,
          inepGeralCollection.y2018 === null
            ? 0
            : inepGeralCollection.y2018.qt_mat,
          inepGeralCollection.y2019 === null
            ? 0
            : inepGeralCollection.y2019.qt_mat,
          inepGeralCollection.y2020 === null
            ? 0
            : inepGeralCollection.y2020.qt_mat,
          inepGeralCollection.y2021 === null
            ? 0
            : inepGeralCollection.y2021.qt_mat,
          inepGeralCollection.y2022 === null
            ? 0
            : inepGeralCollection.y2022.qt_mat,
        ],
      },
      {
        name: "Abandono",
        type: "line",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : inepGeralCollection.y2010.abandono,
          inepGeralCollection.y2011 === null
            ? 0
            : inepGeralCollection.y2011.abandono,
          inepGeralCollection.y2012 === null
            ? 0
            : inepGeralCollection.y2012.abandono,
          inepGeralCollection.y2013 === null
            ? 0
            : inepGeralCollection.y2013.abandono,
          inepGeralCollection.y2014 === null
            ? 0
            : inepGeralCollection.y2014.abandono,
          inepGeralCollection.y2015 === null
            ? 0
            : inepGeralCollection.y2015.abandono,
          inepGeralCollection.y2016 === null
            ? 0
            : inepGeralCollection.y2016.abandono,
          inepGeralCollection.y2017 === null
            ? 0
            : inepGeralCollection.y2017.abandono,
          inepGeralCollection.y2018 === null
            ? 0
            : inepGeralCollection.y2018.abandono,
          inepGeralCollection.y2019 === null
            ? 0
            : inepGeralCollection.y2019.abandono,
          inepGeralCollection.y2020 === null
            ? 0
            : inepGeralCollection.y2020.abandono,
          inepGeralCollection.y2021 === null
            ? 0
            : inepGeralCollection.y2021.abandono,
          inepGeralCollection.y2022 === null
            ? 0
            : inepGeralCollection.y2022.abandono,
        ],
      },
    ],
    options: {
      colors: ["#ff7b8e", "#008ffb", "#00e396", "#feb019", "#00ffff"],
      chart: {
        height: 350,
        type: "line",
      },
      title: {
        text: `Gráfico 5 - Evolução do nº de alunos que abandonaram*, matrículas, e taxa de abandono nos Cursos, por Ano - ${geoTitle} 2010 – 2022.`,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
      ],
      xaxis: {
        //type: 'datetime'
        type: "category",
      },

      yaxis: [
        {
          seriesName: "Taxa de Abandono",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Colunas",
          },
        },
        {
          opposite: true,
          seriesName: "Matrículas",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Linhas",
          },
        },
        {
          seriesName: "Matrículas",
          show: false,
        },
      ],
    },
  };

  const dataChart6 = {
    series: [
      {
        name: "Razão de Absorção",
        type: "column",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : (
                countTipoAdmissao2011 / inepGeralCollection.y2010.qt_curso
              ).toFixed(2),

          inepGeralCollection.y2011 === null
            ? 0
            : (
                countTipoAdmissao2012 / inepGeralCollection.y2011.qt_curso
              ).toFixed(2),

          inepGeralCollection.y2012 === null
            ? 0
            : (
                countTipoAdmissao2013 / inepGeralCollection.y2012.qt_curso
              ).toFixed(2),

          inepGeralCollection.y2013 === null
            ? 0
            : (
                countTipoAdmissao2014 / inepGeralCollection.y2013.qt_curso
              ).toFixed(2),

          inepGeralCollection.y2014 === null
            ? 0
            : (
                countTipoAdmissao2015 / inepGeralCollection.y2014.qt_curso
              ).toFixed(2),

          inepGeralCollection.y2015 === null
            ? 0
            : (
                countTipoAdmissao2016 / inepGeralCollection.y2015.qt_curso
              ).toFixed(2),

          inepGeralCollection.y2016 === null
            ? 0
            : (
                countTipoAdmissao2017 / inepGeralCollection.y2016.qt_curso
              ).toFixed(2),

          inepGeralCollection.y2017 === null
            ? 0
            : (
                countTipoAdmissao2018 / inepGeralCollection.y2017.qt_curso
              ).toFixed(2),

          inepGeralCollection.y2018 === null
            ? 0
            : (
                countTipoAdmissao2019 / inepGeralCollection.y2018.qt_curso
              ).toFixed(2),

          inepGeralCollection.y2019 === null
            ? 0
            : (
                countTipoAdmissao2020 / inepGeralCollection.y2019.qt_curso
              ).toFixed(2),

          inepGeralCollection.y2020 === null
            ? 0
            : (
                countTipoAdmissao2021 / inepGeralCollection.y2020.qt_curso
              ).toFixed(2),

          inepGeralCollection.y2021 === null
            ? 0
            : (
                countTipoAdmissao2022 / inepGeralCollection.y2021.qt_curso
              ).toFixed(2),
        ],
      },
      {
        name: "N. de admissões por primeiro emprego",
        type: "line",
        data: [
          countTipoAdmissao2011.toFixed(2),
          countTipoAdmissao2012.toFixed(2),
          countTipoAdmissao2013.toFixed(2),
          countTipoAdmissao2014.toFixed(2),
          countTipoAdmissao2015.toFixed(2),
          countTipoAdmissao2016.toFixed(2),
          countTipoAdmissao2017.toFixed(2),
          countTipoAdmissao2018.toFixed(2),
          countTipoAdmissao2019.toFixed(2),
          countTipoAdmissao2020.toFixed(2),
          countTipoAdmissao2021.toFixed(2),
          countTipoAdmissao2022.toFixed(2),
        ],
      },
      {
        name: "Egressos",
        type: "line",
        data: [
          inepGeralCollection.y2010 === null
            ? 0
            : inepGeralCollection.y2010.qt_conc,
          inepGeralCollection.y2011 === null
            ? 0
            : inepGeralCollection.y2011.qt_conc,
          inepGeralCollection.y2012 === null
            ? 0
            : inepGeralCollection.y2012.qt_conc,
          inepGeralCollection.y2013 === null
            ? 0
            : inepGeralCollection.y2013.qt_conc,
          inepGeralCollection.y2014 === null
            ? 0
            : inepGeralCollection.y2014.qt_conc,
          inepGeralCollection.y2015 === null
            ? 0
            : inepGeralCollection.y2015.qt_conc,
          inepGeralCollection.y2016 === null
            ? 0
            : inepGeralCollection.y2016.qt_conc,
          inepGeralCollection.y2017 === null
            ? 0
            : inepGeralCollection.y2017.qt_conc,
          inepGeralCollection.y2018 === null
            ? 0
            : inepGeralCollection.y2018.qt_conc,
          inepGeralCollection.y2019 === null
            ? 0
            : inepGeralCollection.y2019.qt_conc,
          inepGeralCollection.y2020 === null
            ? 0
            : inepGeralCollection.y2020.qt_conc,
        ],
      },
    ],
    options: {
      colors: [
        "#008ffb",
        "#00e396",
        "#ff7b8e",
        "#feb019",
        "#00ffff",
        "#ff7b8e",
      ],
      chart: {
        height: 350,
        type: "line",
      },
      title: {
        text: `Gráfico 6 - Evolução no número de egressos do curso no ano, de admissões por primeiro emprego no ano seguinte e da razão de absorção*. ${geoTitle} 2010 – 2022.`,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [
        "10/11",
        "11/12",
        "12/13",
        "13/14",
        "14/15",
        "15/16",
        "16/17",
        "17/18",
        "18/19",
        "19/20",
        "20/21",
        "20/21",
      ],
      xaxis: {
        //type: 'datetime'
        type: "category",
      },
      yaxis: [
        {
          seriesName: "Razão de Absorção",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Colunas",
          },
        },
        {
          opposite: true,
          seriesName: "N. de admissões por primeiro emprego",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: "Linhas",
          },
        },
        {
          seriesName: "N. de admissões por primeiro emprego",
          show: false,
        },
      ],
    },
  };

  const process1 = async () => {
    // LISTA DE CURSOS
    let inepCoursesStart = (
      await clientApi.query({
        query: GET_INEP_COURSES,
      })
    ).data.getInepCourses;
    setInepCourses(inepCoursesStart);

    // GRÁFICOS

    let inepGeralCollectionStart = {};

    let getInepGeralCollectionStart2022 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2022,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2022.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        inepGeralCollectionStart.y2022 = item;
      }
    });

    let getInepGeralCollectionStart2021 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2021,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2021.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2021(item);
        inepGeralCollectionStart.y2021 = item;
      }
    });

    let getInepGeralCollectionStart2020 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2020,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2020.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2020(item);
        inepGeralCollectionStart.y2020 = item;
      }
    });

    let getInepGeralCollectionStart2019 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2019,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2019.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2019(item);
        inepGeralCollectionStart.y2019 = item;
      }
    });

    let getInepGeralCollectionStart2018 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2018,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2018.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2018(item);
        inepGeralCollectionStart.y2018 = item;
      }
    });

    let getInepGeralCollectionStart2017 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2017,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2017.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2017(item);
        inepGeralCollectionStart.y2017 = item;
      }
    });

    let getInepGeralCollectionStart2016 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2016,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2016.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2016(item);
        inepGeralCollectionStart.y2016 = item;
      }
    });

    let getInepGeralCollectionStart2015 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2015,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2015.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2015(item);
        inepGeralCollectionStart.y2015 = item;
      }
    });

    let getInepGeralCollectionStart2014 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2014,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2014.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2014(item);
        inepGeralCollectionStart.y2014 = item;
      }
    });

    let getInepGeralCollectionStart2013 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2013,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2013.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2013(item);
        inepGeralCollectionStart.y2013 = item;
      }
    });

    let getInepGeralCollectionStart2012 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2012,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2012.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2012(item);
        inepGeralCollectionStart.y2012 = item;
      }
    });

    let getInepGeralCollectionStart2011 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2011,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2011.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2011(item);
        inepGeralCollectionStart.y2011 = item;
      }
    });

    let getInepGeralCollectionStart2010 = (
      await clientApi.query({
        query: GET_INEP_GERAL_COLLECTION,
        variables: {
          year: 2010,
          tp_rede: parseInt(tpRede),
          tpCategoriaAdministrativa: tpCategoriaAdministrativa,
          tp_modalidade_ensino: parseInt(tpModalidadeEnsino),
          co_regiao: parseInt(inepRegiao),
          co_uf: parseInt(inepUf),
          co_municipio: parseInt(inepMunicipio),
        },
      })
    ).data.getInepGeralCollection.inepGeral;
    getInepGeralCollectionStart2010.map((item) => {
      if (item.co_cine_rotulo === inepCourseCod) {
        // setInepGeralCollection2010(item);
        inepGeralCollectionStart.y2010 = item;
      }
    });

    setInepGeralCollection(inepGeralCollectionStart);

    // Gráfico 4

    // Tratamento para o gráfico 4
    if (inepCourse !== null && inepCourse.num_anos > 0) {
      let yearStart = 2010;
      let yearLimit = 2022 - (inepCourse.num_anos - 1);
      let labelsChart4Start = [];
      let taxaChart4Start = [];
      let qtIngChart4Start = [];
      let qtConcChart4Start = [];
      let startTitleChat4 = "";
      let endTitleChat4 = "";
      for (
        let yearCurrent = yearStart;
        yearCurrent <= yearLimit;
        yearCurrent++
      ) {
        let yearEnd = yearCurrent + inepCourse.num_anos - 1;
        labelsChart4Start.push(yearCurrent + "/" + yearEnd);
        if (startTitleChat4 === "") {
          startTitleChat4 = yearCurrent + "/" + yearEnd;
        }
        endTitleChat4 = yearCurrent + "/" + yearEnd;

        qtIngChart4Start.push(
          inepGeralCollectionStart["y" + yearCurrent].qt_ing
        );
        qtConcChart4Start.push(inepGeralCollectionStart["y" + yearEnd].qt_conc);
        taxaChart4Start.push(
          (
            (1 -
              inepGeralCollectionStart["y" + yearEnd].qt_conc /
                inepGeralCollectionStart["y" + yearCurrent].qt_ing) *
            100
          ).toFixed(2)
        );
      }
      setTitleChart4(`${startTitleChat4} a ${endTitleChat4}`);
      setLabelsChart4(labelsChart4Start);
      setTaxaChart4(taxaChart4Start);
      setQtIngChart4(qtIngChart4Start);
      setQtConcChart4(qtConcChart4Start);
    }

    setIsLoading(false);

    // COUNT_TIPO_ADMISSAO

    // Faz apenas para os cursos

    if (inepCourse !== null && nunsChart6.includes(inepCourse.ord)) {
      let countTipoAdmissaoStart2022 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2022,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2022(countTipoAdmissaoStart2022);

      let countTipoAdmissaoStart2021 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2021,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2021(countTipoAdmissaoStart2021);

      let countTipoAdmissaoStart2020 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2020,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2020(countTipoAdmissaoStart2020);

      let countTipoAdmissaoStart2019 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2019,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2019(countTipoAdmissaoStart2019);

      let countTipoAdmissaoStart2018 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2018,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2018(countTipoAdmissaoStart2018);

      let countTipoAdmissaoStart2017 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2017,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2017(countTipoAdmissaoStart2017);

      let countTipoAdmissaoStart2016 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2016,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2016(countTipoAdmissaoStart2016);

      let countTipoAdmissaoStart2015 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2015,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2015(countTipoAdmissaoStart2015);

      let countTipoAdmissaoStart2014 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2014,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2014(countTipoAdmissaoStart2014);

      let countTipoAdmissaoStart2013 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2013,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2013(countTipoAdmissaoStart2013);

      let countTipoAdmissaoStart2012 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2012,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2012(countTipoAdmissaoStart2012);

      let countTipoAdmissaoStart2011 = (
        await clientApi.query({
          query: COUNT_TIPO_ADMISSAO,
          variables: {
            tipoAdimissao: "01",
            year: 2011,
            professionNum: inepCourse.ord,
          },
        })
      ).data.countTipoAdmissao.count;
      setCountTipoAdmissao2011(countTipoAdmissaoStart2011);
    }
  };

  useEffect(async () => {
    console.log("useEffect");
    setIsLoading(true);
    var filterType = "";
    var filterValue = "";

    let getInepRegioesStart = (
      await clientApi.query({
        query: GET_INEP_REGIOES,
      })
    ).data.getInepRegioes;
    console.log("getInepRegioesStart", getInepRegioesStart);
    setInepRegioes(getInepRegioesStart);

    process1();
  }, [
    inepCourseCod,
    tpRede,
    tpCategoriaAdministrativa,
    tpModalidadeEnsino,
    inepRegiao,
    inepUf,
    inepMunicipio,
  ]);

  return (
    <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
      <Flex direction="column" justify="space-between" h="100%">
        <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="20px">
          <Card display="flex" justify="left" align="left" minH="0px">
            <Flex direction="column">
              <Text color={textColor} fontSize="md" fontWeight="bold">
                Evolução dos indicadores gerais da Educação Superior
                (Graduação), para os cursos da saúde.
              </Text>

              <Card display="flex" justify="left" align="left" minH="0px">
                <strong>Filtros</strong>
                <Flex direction="column" justify="space-between" h="100%">
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
                    gap="20px"
                  >
                    <Card display="flex" justify="left" align="left" minH="0px">
                      <strong>Curso:</strong>
                      <Flex align="center" mb="20px">
                        <FormControl>
                          <Select
                            variant="main"
                            placeholder="TOTAL"
                            color="gray.400"
                            fontSize={16}
                            onChange={(i) => {
                              setInepCourseCod(i.target.value);
                              inepCourses.map((item) => {
                                if (item.co_cine_rotulo === i.target.value) {
                                  setInepCourse(item);
                                }
                              });
                            }}
                            defaultValue={"TOTAL"}
                          >
                            {inepCourses.map((item) => (
                              <option value={item.co_cine_rotulo}>
                                {item.no_cine_rotulo}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Flex>
                    </Card>
                    <Card display="flex" justify="left" align="left" minH="0px">
                      <strong>Categoria Administrativa:</strong>
                      <Flex align="center" mb="20px">
                        <FormControl>
                          <Select
                            variant="main"
                            placeholder="TOTAL"
                            color="gray.400"
                            fontSize={16}
                            onChange={(i) => {
                              setTpCategoriaAdministrativa(i.target.value);
                            }}
                            defaultValue={tpCategoriaAdministrativa}
                          >
                            <option value="publica">Pública</option>
                            <option value="privada_lucrativa">
                              Privada com fins lucrativos
                            </option>
                            <option value="privada_nao_lucrativa">
                              Privada sem fins lucrativos
                            </option>
                          </Select>
                        </FormControl>
                      </Flex>
                    </Card>
                    <Card display="flex" justify="left" align="left" minH="0px">
                      <strong>Modalidade de ensino:</strong>
                      <Flex align="center" mb="20px">
                        <FormControl>
                          <Select
                            variant="main"
                            placeholder="TOTAL"
                            color="gray.400"
                            fontSize={16}
                            onChange={(i) => {
                              setTpModalidadeEnsino(i.target.value);
                            }}
                            defaultValue={tpModalidadeEnsino}
                          >
                            <option value={1}>Presencial</option>
                            <option value={2}>Curso a distância</option>
                          </Select>
                        </FormControl>
                      </Flex>
                    </Card>
                  </Grid>
                </Flex>
              </Card>

              {parseInt(tpModalidadeEnsino) !== 2 && (
                <>
                  <Card display="flex" justify="left" align="left" minH="0px">
                    <strong>Filtrar por dimensão geográfica</strong>
                    <Flex direction="column" justify="space-between" h="100%">
                      <Grid
                        templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
                        gap="20px"
                      >
                        <Card
                          display="flex"
                          justify="left"
                          align="left"
                          minH="0px"
                        >
                          <string>Região:</string>
                          <FormControl>
                            <Select
                              variant="main"
                              placeholder="TOTAL"
                              color="gray.400"
                              fontSize={16}
                              onChange={(i) => {
                                _setInepRegiao(i.target.value);
                              }}
                            >
                              {inepRegioes.map((item) => (
                                <option value={item.cod}>{item.title}</option>
                              ))}
                            </Select>
                          </FormControl>
                        </Card>
                        {inepUfs.length > 0 && (
                          <Card
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            <string>UF:</string>
                            <FormControl>
                              <Select
                                variant="main"
                                placeholder="TOTAL"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  _setInepUf(i.target.value);
                                }}
                              >
                                {inepUfs.map((item) => (
                                  <option value={item.cod}>{item.title}</option>
                                ))}
                              </Select>
                            </FormControl>
                          </Card>
                        )}
                        {inepMunicipios.length > 0 && (
                          <Card
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            Município:
                            <FormControl>
                              <Select
                                variant="main"
                                placeholder="TOTAL"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  _setInepMunicipio(i.target.value);
                                }}
                              >
                                {inepMunicipios.map((item) => (
                                  <option value={item.cod}>{item.title}</option>
                                ))}
                              </Select>
                            </FormControl>
                          </Card>
                        )}
                      </Grid>
                    </Flex>
                  </Card>
                </>
              )}

              {isLoading && (
                <Grid
                  templateColumns={{ sm: "1fr", lg: "1fr" }}
                  gap="20px"
                  my="20px"
                >
                  <Flex direction="column" justify="space-between" h="100%">
                    <Grid gap="20px">
                      <Flex direction="column">
                        <Text color="#000" fontSize="3xl" fontWeight="bold">
                          Carregando...
                        </Text>
                        <Text color="#000" fontSize="3xl" fontWeight="bold">
                          Isso poderá levar alguns minutos.
                        </Text>
                      </Flex>
                    </Grid>
                  </Flex>
                </Grid>
              )}
              {!isLoading && (
                <>
                  <br />
                  <br />
                  <ReactApexChart
                    options={dataChart.options}
                    series={dataChart.series}
                    type="line"
                    height={450}
                  />
                  <br />
                  <br />
                  <ReactApexChart
                    options={dataChart2.options}
                    series={dataChart2.series}
                    type="line"
                    height={450}
                  />
                  <br />
                  <br />
                  <ReactApexChart
                    options={dataChart3.options}
                    series={dataChart3.series}
                    type="line"
                    height={450}
                  />
                  <br />
                  <br />
                  {inepCourse !== null && inepCourse.num_anos > 0 && (
                    <>
                      <ReactApexChart
                        options={dataChart4.options}
                        series={dataChart4.series}
                        type="line"
                        height={450}
                      />
                      <br />
                      <br />
                    </>
                  )}
                  <ReactApexChart
                    options={dataChart5.options}
                    series={dataChart5.series}
                    type="line"
                    height={450}
                  />
                  {inepCourse !== null &&
                    nunsChart6.includes(inepCourse.ord) && (
                      <ReactApexChart
                        options={dataChart6.options}
                        series={dataChart6.series}
                        type="line"
                        height={450}
                      />
                    )}
                </>
              )}
            </Flex>
          </Card>
        </Grid>
      </Flex>
    </Grid>
  );
};

export default InepTab2;
