import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  FormControl,
  Select,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import LineBarChart from "components/Charts/LineBarChart";
import {
  profissionaisEtiData,
  profissionaisEtiOptions,
  salarioHoraData,
  salarioHoraOptions,
} from "variables/charts";
import ReactApexChart from "react-apexcharts";
import Tooltip from "react-simple-tooltip";

import {
  // SINAIS_DE_MERACDO,
  // SINAIS_DE_MERACDO_SUS,
  SINAIS_DE_MERACDO_CNES,
  GET_UFS,
  GET_REGIOES_SAUDE,
  GET_REGIOES_SAUDE_MACRO,
  GET_MUNICIPIOS_BY_CODE,
} from "queries";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.pluz.app",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const SinaisDeMercadoTab2 = (props) => {
  const { profession } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedNaturezaJuridica, setselectedNaturezaJuridica] = useState(
    "total"
  );
  const [
    selectedTipoVinculacaoComEstabelecimento,
    setTipoVinculacaoComEstabelecimento,
  ] = useState("total");
  const [
    selectedTipoVinculacaoDeTrabalho,
    setTipoVinculacaoDeTrabalho,
  ] = useState("total");

  const [ufs, setUfs] = useState([]);
  const [regioesSaude, setRegioesSaude] = useState([]);
  const [regioesSaudeMacro, setRegioesSaudeMacro] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  const [ufSelected, setUfSelected] = useState("total");
  const [regiaoSaudeSelected, setRegiaoSaudeSelected] = useState("total");
  const [regiaoSaudeMacroSelected, setRegiaoSaudeMacroSelected] = useState(
    "total"
  );
  const [municipioSelected, setMunicipioSelected] = useState("total");

  const [sinaisDeMercadoSus, setSinaisDeMercadoSus] = useState(null);
  const [sinaisDeMercadoSus2022, setSinaisDeMercadoSus2022] = useState(null);
  const [sinaisDeMercadoSus2021, setSinaisDeMercadoSus2021] = useState(null);
  const [sinaisDeMercadoSus2020, setSinaisDeMercadoSus2020] = useState(null);
  const [sinaisDeMercadoSus2019, setSinaisDeMercadoSus2019] = useState(null);
  const [sinaisDeMercadoSus2018, setSinaisDeMercadoSus2018] = useState(null);
  const [sinaisDeMercadoSus2017, setSinaisDeMercadoSus2017] = useState(null);
  const [sinaisDeMercadoSus2016, setSinaisDeMercadoSus2016] = useState(null);
  const [sinaisDeMercadoSus2015, setSinaisDeMercadoSus2015] = useState(null);
  const [sinaisDeMercadoSus2014, setSinaisDeMercadoSus2014] = useState(null);
  const [sinaisDeMercadoSus2013, setSinaisDeMercadoSus2013] = useState(null);
  const [sinaisDeMercadoSus2012, setSinaisDeMercadoSus2012] = useState(null);
  const [sinaisDeMercadoSus2011, setSinaisDeMercadoSus2011] = useState(null);
  const [sinaisDeMercadoSus2010, setSinaisDeMercadoSus2010] = useState(null);

  const [sinaisDeMercado, setSinaisDeMercado] = useState(null);
  const [sinaisDeMercado2022, setSinaisDeMercado2022] = useState(null);
  const [sinaisDeMercado2021, setSinaisDeMercado2021] = useState(null);
  const [sinaisDeMercado2020, setSinaisDeMercado2020] = useState(null);
  const [sinaisDeMercado2019, setSinaisDeMercado2019] = useState(null);
  const [sinaisDeMercado2018, setSinaisDeMercado2018] = useState(null);
  const [sinaisDeMercado2017, setSinaisDeMercado2017] = useState(null);
  const [sinaisDeMercado2016, setSinaisDeMercado2016] = useState(null);
  const [sinaisDeMercado2015, setSinaisDeMercado2015] = useState(null);
  const [sinaisDeMercado2014, setSinaisDeMercado2014] = useState(null);
  const [sinaisDeMercado2013, setSinaisDeMercado2013] = useState(null);
  const [sinaisDeMercado2012, setSinaisDeMercado2012] = useState(null);
  const [sinaisDeMercado2011, setSinaisDeMercado2011] = useState(null);
  const [sinaisDeMercado2010, setSinaisDeMercado2010] = useState(null);

  const textColor = useColorModeValue("gray.700", "white");

  const [maxValue_eti, setMaxValue_eti] = useState(false);
  const [maxValue_razao_1000_hab, setMaxValue_razao_1000_hab] = useState(false);

  const dataChart = {
    series: [
      {
        name: "Profissionais em ETI",
        type: "column",
        data: [
          sinaisDeMercado2010 === null ? 0 : sinaisDeMercado2010.eti,
          sinaisDeMercado2011 === null ? 0 : sinaisDeMercado2011.eti,
          sinaisDeMercado2012 === null ? 0 : sinaisDeMercado2012.eti,
          sinaisDeMercado2013 === null ? 0 : sinaisDeMercado2013.eti,
          sinaisDeMercado2014 === null ? 0 : sinaisDeMercado2014.eti,
          sinaisDeMercado2015 === null ? 0 : sinaisDeMercado2015.eti,
          sinaisDeMercado2016 === null ? 0 : sinaisDeMercado2016.eti,
          sinaisDeMercado2017 === null ? 0 : sinaisDeMercado2017.eti,
          sinaisDeMercado2018 === null ? 0 : sinaisDeMercado2018.eti,
          sinaisDeMercado2019 === null ? 0 : sinaisDeMercado2019.eti,
          sinaisDeMercado2020 === null ? 0 : sinaisDeMercado2020.eti,
          sinaisDeMercado2021 === null ? 0 : sinaisDeMercado2021.eti,
          sinaisDeMercado2022 === null ? 0 : sinaisDeMercado2022.eti,
          sinaisDeMercado === null ? 0 : sinaisDeMercado.eti,
        ],
      },
      {
        name: "Razão por 1.000 hab.",
        type: "line",
        data: [
          sinaisDeMercado2010 === null ? 0 : sinaisDeMercado2010.razao_1000_hab,
          sinaisDeMercado2011 === null ? 0 : sinaisDeMercado2011.razao_1000_hab,
          sinaisDeMercado2012 === null ? 0 : sinaisDeMercado2012.razao_1000_hab,
          sinaisDeMercado2013 === null ? 0 : sinaisDeMercado2013.razao_1000_hab,
          sinaisDeMercado2014 === null ? 0 : sinaisDeMercado2014.razao_1000_hab,
          sinaisDeMercado2015 === null ? 0 : sinaisDeMercado2015.razao_1000_hab,
          sinaisDeMercado2016 === null ? 0 : sinaisDeMercado2016.razao_1000_hab,
          sinaisDeMercado2017 === null ? 0 : sinaisDeMercado2017.razao_1000_hab,
          sinaisDeMercado2018 === null ? 0 : sinaisDeMercado2018.razao_1000_hab,
          sinaisDeMercado2019 === null ? 0 : sinaisDeMercado2019.razao_1000_hab,
          sinaisDeMercado2020 === null ? 0 : sinaisDeMercado2020.razao_1000_hab,
          sinaisDeMercado2021 === null ? 0 : sinaisDeMercado2021.razao_1000_hab,
          sinaisDeMercado2022 === null ? 0 : sinaisDeMercado2022.razao_1000_hab,
          sinaisDeMercado === null ? 0 : sinaisDeMercado.razao_1000_hab,
        ],
      },
    ],
    options: {
      title: {
        text: "Nº profissionais em ETI e Razão por 1.000 hab. por ano",
      },
      colors: ["#008ffb", "#00e396"],
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
      ],
      xaxis: {
        //type: 'datetime'
        type: "category",
      },
      yaxis: [
        {
          min: 0,
          max: maxValue_eti,
          title: {
            text: "Profissionais em ETI",
          },
        },
        {
          opposite: true,
          min: 0,
          max: maxValue_razao_1000_hab * 1.2,
          title: {
            text: "Razão por 1.000 hab.",
          },
        },
      ],
    },
  };

  const dataChartSus = {
    series: [
      {
        name: "Profissionais em ETI",
        type: "column",
        data: [
          sinaisDeMercadoSus2010 === null ? 0 : sinaisDeMercadoSus2010.eti,
          sinaisDeMercadoSus2011 === null ? 0 : sinaisDeMercadoSus2011.eti,
          sinaisDeMercadoSus2012 === null ? 0 : sinaisDeMercadoSus2012.eti,
          sinaisDeMercadoSus2013 === null ? 0 : sinaisDeMercadoSus2013.eti,
          sinaisDeMercadoSus2014 === null ? 0 : sinaisDeMercadoSus2014.eti,
          sinaisDeMercadoSus2015 === null ? 0 : sinaisDeMercadoSus2015.eti,
          sinaisDeMercadoSus2016 === null ? 0 : sinaisDeMercadoSus2016.eti,
          sinaisDeMercadoSus2017 === null ? 0 : sinaisDeMercadoSus2017.eti,
          sinaisDeMercadoSus2018 === null ? 0 : sinaisDeMercadoSus2018.eti,
          sinaisDeMercadoSus2019 === null ? 0 : sinaisDeMercadoSus2019.eti,
          sinaisDeMercadoSus2020 === null ? 0 : sinaisDeMercadoSus2020.eti,
          sinaisDeMercadoSus2021 === null ? 0 : sinaisDeMercadoSus2021.eti,
          sinaisDeMercadoSus2022 === null ? 0 : sinaisDeMercadoSus2022.eti,
          sinaisDeMercadoSus === null ? 0 : sinaisDeMercadoSus.eti,
        ],
      },
      {
        name: "Razão por 1.000 hab.",
        type: "line",
        data: [
          sinaisDeMercadoSus2010 === null
            ? 0
            : sinaisDeMercadoSus2010.razao_1000_hab,
          sinaisDeMercadoSus2011 === null
            ? 0
            : sinaisDeMercadoSus2011.razao_1000_hab,
          sinaisDeMercadoSus2012 === null
            ? 0
            : sinaisDeMercadoSus2012.razao_1000_hab,
          sinaisDeMercadoSus2013 === null
            ? 0
            : sinaisDeMercadoSus2013.razao_1000_hab,
          sinaisDeMercadoSus2014 === null
            ? 0
            : sinaisDeMercadoSus2014.razao_1000_hab,
          sinaisDeMercadoSus2015 === null
            ? 0
            : sinaisDeMercadoSus2015.razao_1000_hab,
          sinaisDeMercadoSus2016 === null
            ? 0
            : sinaisDeMercadoSus2016.razao_1000_hab,
          sinaisDeMercadoSus2017 === null
            ? 0
            : sinaisDeMercadoSus2017.razao_1000_hab,
          sinaisDeMercadoSus2018 === null
            ? 0
            : sinaisDeMercadoSus2018.razao_1000_hab,
          sinaisDeMercadoSus2019 === null
            ? 0
            : sinaisDeMercadoSus2019.razao_1000_hab,
          sinaisDeMercadoSus2020 === null
            ? 0
            : sinaisDeMercadoSus2020.razao_1000_hab,
          sinaisDeMercadoSus2021 === null
            ? 0
            : sinaisDeMercadoSus2021.razao_1000_hab,
          sinaisDeMercadoSus2022 === null
            ? 0
            : sinaisDeMercadoSus2022.razao_1000_hab,
          sinaisDeMercadoSus === null ? 0 : sinaisDeMercadoSus.razao_1000_hab,
        ],
      },
    ],
    options: {
      title: {
        text: "Nº profissionais em ETI e Razão por 1.000 hab. por ano no SUS",
      },
      colors: ["#008ffb", "#00e396"],
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
      ],
      xaxis: {
        //type: 'datetime'
        type: "category",
      },
      yaxis: [
        {
          min: 0,
          max: maxValue_eti,
          title: {
            text: "Profissionais em ETI",
          },
        },
        {
          opposite: true,
          min: 0,
          max: maxValue_razao_1000_hab * 1.2,
          title: {
            text: "Razão por 1.000 hab.",
          },
        },
      ],
    },
  };

  const process1 = async (filterType, filterValue) => {
    var filterType = "";
    var filterValue = "";

    var sinaisDeMercadoSusStart2022 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2022",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2022(sinaisDeMercadoSusStart2022);

    var sinaisDeMercadoSusStart2021 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2021",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2021(sinaisDeMercadoSusStart2021);

    var sinaisDeMercadoSusStart2020 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2020",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2020(sinaisDeMercadoSusStart2020);

    var sinaisDeMercadoSusStart2019 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2019",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2019(sinaisDeMercadoSusStart2019);

    var sinaisDeMercadoSusStart2018 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2018",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2018(sinaisDeMercadoSusStart2018);

    var sinaisDeMercadoSusStart2017 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2017",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2017(sinaisDeMercadoSusStart2017);

    var sinaisDeMercadoSusStart2016 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2016",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2016(sinaisDeMercadoSusStart2016);

    var sinaisDeMercadoSusStart2015 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2015",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2015(sinaisDeMercadoSusStart2015);
  };

  const process2 = async (filterType, filterValue) => {
    var filterType = "";
    var filterValue = "";

    var sinaisDeMercadoSusStart2014 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2014",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2014(sinaisDeMercadoSusStart2014);

    var sinaisDeMercadoSusStart2013 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2013",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2013(sinaisDeMercadoSusStart2013);

    var sinaisDeMercadoSusStart2012 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2012",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2012(sinaisDeMercadoSusStart2012);

    var sinaisDeMercadoSusStart2011 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2011",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2011(sinaisDeMercadoSusStart2011);

    var sinaisDeMercadoSusStart2010 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2010",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus2010(sinaisDeMercadoSusStart2010);
  };

  const process3 = async () => {
    var filterType = "";
    var filterValue = "";

    let startMaxValue_eti = 0;
    let startMaxValue_razao_1000_hab = 0.0;

    var sinaisDeMercadoStart2022 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2022",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2022(sinaisDeMercadoStart2022);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2022.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2022.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2022.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2022.razao_1000_hab
      );
    }

    var sinaisDeMercadoStart2021 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2021",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2021(sinaisDeMercadoStart2021);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2021.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2021.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2021.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2021.razao_1000_hab
      );
    }

    var sinaisDeMercadoStart2020 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2020",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2020(sinaisDeMercadoStart2020);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2020.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2020.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2020.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2020.razao_1000_hab
      );
    }

    var sinaisDeMercadoStart2019 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2019",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2019(sinaisDeMercadoStart2019);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2019.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2019.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2019.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2019.razao_1000_hab
      );
    }

    var sinaisDeMercadoStart2018 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2018",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2018(sinaisDeMercadoStart2018);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2018.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2018.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2018.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2018.razao_1000_hab
      );
    }

    return [startMaxValue_eti, startMaxValue_razao_1000_hab];
  };

  const process4 = async () => {
    var filterType = "";
    var filterValue = "";

    let startMaxValue_eti = 0;
    let startMaxValue_razao_1000_hab = 0.0;

    var sinaisDeMercadoStart2017 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2017",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2017(sinaisDeMercadoStart2017);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2017.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2017.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2017.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2017.razao_1000_hab
      );
    }

    var sinaisDeMercadoStart2016 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2016",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2016(sinaisDeMercadoStart2016);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2016.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2016.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2016.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2016.razao_1000_hab
      );
    }

    var sinaisDeMercadoStart2015 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2015",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2015(sinaisDeMercadoStart2015);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2015.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2015.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2015.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2015.razao_1000_hab
      );
    }

    var sinaisDeMercadoStart2014 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2014",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2014(sinaisDeMercadoStart2014);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2014.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2014.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2014.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2014.razao_1000_hab
      );
    }

    var sinaisDeMercadoStart2013 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2013",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2013(sinaisDeMercadoStart2013);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2013.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2013.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2013.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2013.razao_1000_hab
      );
    }

    var sinaisDeMercadoStart2012 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2012",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2012(sinaisDeMercadoStart2012);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2012.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2012.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2012.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2012.razao_1000_hab
      );
    }

    var sinaisDeMercadoStart2011 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2011",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2011(sinaisDeMercadoStart2011);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2011.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2011.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2011.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2011.razao_1000_hab
      );
    }

    var sinaisDeMercadoStart2010 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2010",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado2010(sinaisDeMercadoStart2010);

    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart2010.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart2010.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart2010.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart2010.razao_1000_hab
      );
    }

    return [startMaxValue_eti, startMaxValue_razao_1000_hab];
  };

  const getData = async () => {
    setIsLoading(true);

    // GET UFS
    var getUfsStart = (
      await clientApi.query({
        query: GET_UFS,
      })
    ).data.getUfs;
    setUfs(getUfsStart);

    var filterType = "";
    var filterValue = "";
    // if(municipioSelected !== "total") {
    //   filterType = "municipio";
    //   filterValue = municipioSelected;
    // }
    // else if(regiaoSaudeSelected !== "total") {
    //   filterType = "regiaoSaude";
    //   filterValue = regiaoSaudeSelected;
    // }
    // else if(ufSelected !== "total") {
    //   filterType = "uf";
    //   filterValue = ufSelected;
    // }

    // SUS
    process1();
    process2();

    var sinaisDeMercadoSusStart = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2024",
          isSus: "1",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercadoSus(sinaisDeMercadoSusStart);

    // Geral
    process3();
    process4();

    let startMaxValue_eti = 0;
    let startMaxValue_razao_1000_hab = 0.0;

    var sinaisDeMercadoStart = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          cod_vinculacao: selectedTipoVinculacaoComEstabelecimento,
          cod_vinculo_agregado: selectedTipoVinculacaoDeTrabalho,
          profession_code: profession.code,
          year: "2024",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          regiaosaudeMacro_code: regiaoSaudeMacroSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_CNES,
      })
    ).data.sinaisDeMercadoCnes;
    setSinaisDeMercado(sinaisDeMercadoStart);
    // Define o eixo maior para os gráficos
    if (parseInt(sinaisDeMercadoStart.eti) > startMaxValue_eti) {
      startMaxValue_eti = parseInt(sinaisDeMercadoStart.eti);
    }
    if (
      parseFloat(sinaisDeMercadoStart.razao_1000_hab) >
      startMaxValue_razao_1000_hab
    ) {
      startMaxValue_razao_1000_hab = parseFloat(
        sinaisDeMercadoStart.razao_1000_hab
      );
    }

    setMaxValue_eti(startMaxValue_eti);
    setMaxValue_razao_1000_hab(startMaxValue_razao_1000_hab);

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [
    profession.code,
    selectedNaturezaJuridica,
    selectedTipoVinculacaoComEstabelecimento,
    selectedTipoVinculacaoDeTrabalho,
    ufSelected,
    regiaoSaudeMacroSelected,
    regiaoSaudeSelected,
    municipioSelected,
  ]);

  const changeUf = async (v) => {
    setUfSelected(v);
    setRegiaoSaudeSelected("total");
    setRegiaoSaudeMacroSelected("total");
    setMunicipioSelected("total");

    if (v == "total") {
      setRegioesSaude([]);
      setRegioesSaudeMacro([]);
      setMunicipios([]);
    } else {
      var getRegioesDeSuadeStart = (
        await clientApi.query({
          variables: {
            uf: v,
          },
          query: GET_REGIOES_SAUDE,
        })
      ).data.getRegioesSaude;
      setRegioesSaude(getRegioesDeSuadeStart);

      var getRegioesDeSuadeMacroStart = (
        await clientApi.query({
          variables: {
            uf: v,
          },
          query: GET_REGIOES_SAUDE_MACRO,
        })
      ).data.getRegioesSaudeMacro;
      setRegioesSaudeMacro(getRegioesDeSuadeMacroStart);

      var getMunicipiosStart = (
        await clientApi.query({
          variables: {
            uf: v,
          },
          query: GET_MUNICIPIOS_BY_CODE,
        })
      ).data.getMunicipios.municipios;
      setMunicipios(getMunicipiosStart);
    }
  };

  const changeMunicipio = async (v) => {
    setRegiaoSaudeSelected("total");
    setRegiaoSaudeMacroSelected("total");
    setMunicipioSelected(v);
  };

  const changeRegiaoSaude = async (v) => {
    setMunicipioSelected("total");
    setRegiaoSaudeMacroSelected("total");
    setRegiaoSaudeSelected(v);

    var getMunicipiosStart = (
      await clientApi.query({
        variables: {
          uf: ufSelected,
          regiaoSaude: v,
        },
        query: GET_MUNICIPIOS_BY_CODE,
      })
    ).data.getMunicipios.municipios;
    setMunicipios(getMunicipiosStart);
  };

  const changeRegiaoSaudeMacro = async (v) => {
    setMunicipioSelected("total");
    setRegiaoSaudeSelected("total");
    setRegiaoSaudeMacroSelected(v);

    var getMunicipiosStart = (
      await clientApi.query({
        variables: {
          uf: ufSelected,
          regiaoSaudeMacro: v,
        },
        query: GET_MUNICIPIOS_BY_CODE,
      })
    ).data.getMunicipios.municipios;
    setMunicipios(getMunicipiosStart);
  };

  if (sinaisDeMercado === null) {
    return (
      <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
        <Flex direction="column" justify="space-between" h="100%">
          <Grid gap="20px">
            <Flex direction="column">
              <Text color="#fff" fontSize="3xl" fontWeight="bold">
                Carregando...
              </Text>
              <Text color="#fff" fontSize="3xl" fontWeight="bold">
                Isso poderá levar alguns minutos.
              </Text>
            </Flex>
          </Grid>
        </Flex>
      </Grid>
    );
  }

  return (
    <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
      <Flex direction="column" justify="space-between" h="100%">
        <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="20px">
          <Card display="flex" justify="left" align="left" minH="0px">
            <Flex direction="column">
              <Text color="#000" fontSize="md" fontWeight="regular">
                A <strong>oferta</strong> de uma profissão ou ocupação
                corresponde ao nº de pessoas efetivamente ocupadas, as
                desocupadas, as que potencialmente podem se (re)ocupar e as que
                estão em trânsito entre uma profissão/ocupação e outra.
              </Text>
              <Text color="#000" fontSize="md" fontWeight="regular">
                A <strong>demanda</strong> corresponde ao nº de postos de
                trabalho ocupados ou vagos que são proporcionados por empresas,
                instituições e governos. Os postos de trabalho ocupados
                representam a demanda efetiva. Os profissionais que ocupam estes
                postos de trabalho são simultaneamente considerados como oferta
                e demanda.
              </Text>
              <Text color="#000" fontSize="md" fontWeight="regular">
                Esta aba apresenta o dimensionamento da oferta/demanda efetiva
                da profissão ou ocupação no segmento assistencial em saúde. Os
                dados estão discriminados para o{" "}
                <strong>total de ocupados em estabelecimentos de saúde</strong>{" "}
                e apenas para os{" "}
                <strong>ocupados no Sistema Único de Saúde</strong>{" "}
                (administração pública direta ou privado
                conveniado/contratualizado). Para algumas profissões também é
                disponibilizado o número de profissionais com registro ativo no
                respectivo Conselho da Profissão.
              </Text>
              <Text color="#000" fontSize="md" fontWeight="regular">
                Para obter informações mais detalhadas, consulte o menu{" "}
                <a href="/#/admin/applications/sinais-de-mercado-de-trabalho">
                  "Sinais do Mercado de Trabalho em Saúde"
                </a>
              </Text>
            </Flex>
          </Card>
        </Grid>
      </Flex>

      <Flex direction="column" justify="space-between" h="100%">
        <Grid templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }} gap="20px">
          <Card display="flex" justify="left" align="left" minH="0px">
            <strong>Filtrar por Natureza Jurídica:</strong>
            <FormControl>
              <Select
                variant="main"
                placeholder="- Selecione -"
                color="gray.400"
                fontSize={16}
                onChange={(i) => {
                  setselectedNaturezaJuridica(i.target.value);
                }}
                defaultValue={"total"}
              >
                <option value="total">TOTAL</option>
                <option value="publico_municipal">Público Municipal</option>
                <option value="publico_estadual">Público Estadual</option>
                <option value="publico_federal">Público Federal</option>
                <option value="publico_outros">Público Outros</option>
                <option value="publico">Público Total</option>
                <option value="privado">Privado Lucrativo</option>
                <option value="privado_nao_lucrativo">
                  Privado Não Lucrativo
                </option>
              </Select>
            </FormControl>
          </Card>

          <Card display="flex" justify="left" align="left" minH="0px">
            <strong>Tipo de Vinculação de Trabalho:</strong>
            <FormControl>
              <Select
                variant="main"
                placeholder="- Selecione -"
                color="gray.400"
                fontSize={16}
                onChange={(i) => {
                  setTipoVinculacaoDeTrabalho(i.target.value);
                }}
                defaultValue={"total"}
              >
                <option value="total">TOTAL</option>
                <option value="estatutario">Estatutário / RJU</option>
                <option value="celetista">Celetista</option>
                <option value="temporario">Temporário</option>
                <option value="autonomo">Autônomo</option>
                <option value="bolsista">Bolsista</option>
                <option value="cod_vinculo_agregado_outro">Outro</option>
              </Select>
            </FormControl>
          </Card>
          <Card display="flex" justify="left" align="left" minH="0px">
            <strong>Tipo de Vinculação com o Estabelecimento:</strong>
            <FormControl>
              <Select
                variant="main"
                placeholder="- Selecione -"
                color="gray.400"
                fontSize={16}
                onChange={(i) => {
                  setTipoVinculacaoComEstabelecimento(i.target.value);
                }}
                defaultValue={"total"}
              >
                <option value="total">TOTAL</option>
                <option value="1">
                  Contratação Direta com Vínculo Empregatício
                </option>
                <option value="2">
                  Contratação Direta com Vínculo Autônomo
                </option>
                <option value="4">Residência, Estágio ou Bolsa</option>
                <option value="3">
                  Contratação Intermediada por outro Ente/Entidade
                </option>
                <option value="5">Outra</option>
              </Select>
            </FormControl>
          </Card>
        </Grid>
      </Flex>

      <Card display="flex" justify="left" align="left" minH="0px">
        <strong>Filtrar por localidade</strong>
        <Flex direction="column" justify="space-between" h="100%">
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(4, 1fr)" }}
            gap="20px"
          >
            <Card display="flex" justify="left" align="left" minH="0px">
              <string>UF:</string>
              <FormControl>
                <Select
                  variant="main"
                  placeholder="- Selecione -"
                  color="gray.400"
                  fontSize={16}
                  onChange={(i) => {
                    changeUf(i.target.value);
                  }}
                  defaultValue={"total"}
                >
                  <option value="total">TOTAL</option>
                  {ufs.map((uf) => (
                    <option value={uf.cod}>{uf.title}</option>
                  ))}
                </Select>
              </FormControl>
            </Card>
            {regioesSaudeMacro.length > 0 && (
              <Card display="flex" justify="left" align="left" minH="0px">
                <string>Macrorregião de Saúde CIR:</string>
                <FormControl>
                  <Select
                    variant="main"
                    placeholder="- Selecione -"
                    color="gray.400"
                    fontSize={16}
                    onChange={(i) => {
                      changeRegiaoSaudeMacro(i.target.value);
                    }}
                    value={regiaoSaudeMacroSelected}
                  >
                    <option value="total">TOTAL</option>
                    {regioesSaudeMacro.map((regioaoSaude) => (
                      <option value={regioaoSaude.cod}>
                        {regioaoSaude.title}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Card>
            )}
            {regioesSaude.length > 0 && (
              <Card display="flex" justify="left" align="left" minH="0px">
                <string>Microrregião de Saúde CIR:</string>
                <FormControl>
                  <Select
                    variant="main"
                    placeholder="- Selecione -"
                    color="gray.400"
                    fontSize={16}
                    onChange={(i) => {
                      changeRegiaoSaude(i.target.value);
                    }}
                    value={regiaoSaudeSelected}
                  >
                    <option value="total">TOTAL</option>
                    {regioesSaude.map((regioaoSaude) => (
                      <option value={regioaoSaude.cod}>
                        {regioaoSaude.title}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Card>
            )}

            {regioesSaude.length > 0 && (
              <Card display="flex" justify="left" align="left" minH="0px">
                <string>Município:</string>
                <FormControl>
                  <Select
                    variant="main"
                    placeholder="- Selecione -"
                    color="gray.400"
                    fontSize={16}
                    onChange={(i) => {
                      changeMunicipio(i.target.value);
                    }}
                    value={municipioSelected}
                  >
                    <option value="total">TOTAL</option>
                    {municipios.map((municipio) => (
                      <option value={municipio.cod_ibge}>
                        {municipio.nom_mun_acent}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Card>
            )}
          </Grid>
        </Flex>
      </Card>

      {isLoading && (
        <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
          <Flex direction="column" justify="space-between" h="100%">
            <Grid gap="20px">
              <Flex direction="column">
                <Text color="#fff" fontSize="3xl" fontWeight="bold">
                  Carregando...
                </Text>
                <Text color="#fff" fontSize="3xl" fontWeight="bold">
                  Isso poderá levar alguns minutos.
                </Text>
              </Flex>
            </Grid>
          </Flex>
        </Grid>
      )}
      {!isLoading && (
        <>
          <Flex direction="column" justify="space-between" h="100%">
            <Grid
              templateColumns={{
                sm: "1fr",
                md:
                  profession.ind_prof_ativos === "Sim" &&
                  selectedTipoVinculacaoComEstabelecimento === "total" &&
                  selectedNaturezaJuridica === "total" &&
                  ufSelected === "total"
                    ? "repeat(6, 1fr)"
                    : "repeat(5, 1fr)",
              }}
              gap="20px"
            >
              {profession.ind_prof_ativos === "Sim" &&
                selectedTipoVinculacaoComEstabelecimento === "total" &&
                selectedNaturezaJuridica === "total" &&
                ufSelected === "total" && (
                  <>
                    <Tooltip
                      content={
                        <div style={{ width: "200px" }}>
                          <strong>Nº de Profissionais Ativos:</strong> Número de
                          profissionais ativos segundo o conselho profissional
                          (quando disponível)
                        </div>
                      }
                    >
                      <Card
                        display="flex"
                        justify="center"
                        align="center"
                        minH="0px"
                        id="numProfissionaisAtivos"
                      >
                        <Flex direction="column">
                          <Text
                            color="#feb019"
                            fontSize="3xl"
                            fontWeight="bold"
                          >
                            {profession.qtd_prof_ativos}
                          </Text>
                          <Text color="#feb019" fontSize="md" fontWeight="bold">
                            Nº de profissionais ativos
                          </Text>
                          <Text
                            color="gray.400"
                            fontSize="xs"
                            fontWeight="bold"
                          >
                            Dados Conselho Profissional{" "}
                            {profession.dt_prof_ativos}
                          </Text>
                        </Flex>
                      </Card>
                    </Tooltip>
                  </>
                )}

              <Card display="flex" justify="center" align="center" minH="0px">
                <Text mb="10px"></Text>
                <Text color="#000000" fontSize="2xl" fontWeight="bold">
                  Total
                </Text>
                <Text color="#000000" fontSize="1xl" fontWeight="regular">
                  de ocupados em estabelecimentos de saúde
                </Text>
              </Card>

              <Card display="flex" justify="center" align="center" minH="0px">
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>
                        Nº de Profissionais Ocupados em Estabelecimentos de
                        Saúde:
                      </strong>{" "}
                      profissionais com pelo menos um vínculo em estabelecimento
                      de saúde ativo no ano e local selecionados.
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#ff7b8e" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercado.total_cpfs_format}
                    </Text>
                    <Text color="#ff7b8e" fontSize="md" fontWeight="bold">
                      Nº de Profissionais
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados CNES 2024
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>

              <Card display="flex" justify="center" align="center" minH="0px">
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Nº de Profissionais em ETI:</strong> profissionais
                      em Equivalente de Tempo Integral (ETI), calculado a partir
                      da carga horária semanal dos profissionais que atuam em
                      estabelecimentos de saúde. Cada 40 horas semanais
                      registradas equivalem a um profissional em tempo integral.
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#008ffb" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercado.eti_format}
                    </Text>
                    <Text color="#008ffb" fontSize="md" fontWeight="bold">
                      Nº de profissionais
                      <br />
                      em ETI
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados CNES 4
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>
              <Card
                display="flex"
                justify="center"
                align="center"
                minH="0px"
                title="Razão do número de profissionais em ETI por 1.000 habitantes"
              >
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Razão por 1.000 habitantes:</strong> razão entre o
                      número de profissionais em Equivalente de Tempo Integral e
                      a população, expressa a cada 1.000 habitantes.
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#00e396" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercado.razao_1000_hab_format}
                    </Text>
                    <Text color="#00e396" fontSize="md" fontWeight="bold">
                      Razão em ETI por
                      <br /> 1.000 habitantes
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados CNES 2024
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>
              <Card display="flex" justify="center" align="center" minH="0px">
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>
                        Nº de Vínculos em Estabelecimentos de Saúde:
                      </strong>{" "}
                      vínculos de profissionais em estabelecimentos de saúde
                      (vínculos empregatícios ou formas de vinculação autônomas
                      e ou esporádicas para prestação de serviço como plantões,
                      procedimentos, unidades e pacotes de consulta).
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#9683d9" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercado.total_vinculos_format}
                    </Text>
                    <Text color="#9683d9" fontSize="md" fontWeight="bold">
                      Nº de vínculos
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados CNES 2024
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>
            </Grid>
          </Flex>
          <Flex direction="column" justify="space-between" h="100%">
            <Grid
              templateColumns={{
                sm: "1fr",
                md:
                  profession.ind_prof_ativos === "Sim" &&
                  selectedTipoVinculacaoComEstabelecimento === "total" &&
                  selectedNaturezaJuridica === "total" &&
                  ufSelected === "total"
                    ? "repeat(6, 1fr)"
                    : "repeat(5, 1fr)",
              }}
              gap="20px"
            >
              {profession.ind_prof_ativos === "Sim" &&
                selectedTipoVinculacaoComEstabelecimento === "total" &&
                selectedNaturezaJuridica === "total" &&
                ufSelected === "total" && <div></div>}

              <Card
                display="flex"
                justify="center"
                align="center"
                minH="0px"
                title="Profissionais com pelo menos um vínculo em estabelecimento de saúde ativo no ano e local selecionados."
              >
                <Flex direction="column">
                  <Text mb="25px"></Text>
                  <Text color="#000000" fontSize="2xl" fontWeight="bold">
                    Ocupados no <br />
                    SUS
                  </Text>
                </Flex>
              </Card>

              <Card
                display="flex"
                justify="center"
                align="center"
                minH="0px"
                title="Profissionais com pelo menos um vínculo em estabelecimento de saúde ativo no ano e local selecionados."
              >
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Nº de Profissionais Ocupados no SUS:</strong>{" "}
                      profissionais com pelo menos um vínculo com o SUS
                      (administração pública direta ou privado
                      conveniado/contratualizado) no ano e local selecionados.
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#ff7b8e" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercadoSus.total_cpfs_format}
                    </Text>
                    <Text color="#ff7b8e" fontSize="md" fontWeight="bold">
                      Nº de Profissionais
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados CNES 2024
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>
              <Card
                display="flex"
                justify="center"
                align="center"
                minH="0px"
                title="Número de profissionais em ETI ocupados em estabelecimentos de saúde"
              >
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Nº de Profissionais em ETI no SUS:</strong>{" "}
                      profissionais em Equivalente de Tempo Integral (ETI),
                      calculado a partir da carga horária semanal dos vínculos
                      no SUS. Cada 40 horas semanais registradas equivalem a um
                      profissional em tempo integral.
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#008ffb" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercadoSus.eti_format}
                    </Text>
                    <Text color="#008ffb" fontSize="md" fontWeight="bold">
                      Nº de profissionai em ETI
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados CNES 2024
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>
              <Card
                display="flex"
                justify="center"
                align="center"
                minH="0px"
                title="Razão do número de profissionais em ETI por 1.000 habitantes"
              >
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Razão por 1.000 habitantes no SUS:</strong> razão
                      entre o número de profissionais em Equivalente de Tempo
                      Integral no SUS e a população, expressa a cada 1.000
                      habitantes.
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#00e396" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercadoSus.razao_1000_hab_format}
                    </Text>
                    <Text color="#00e396" fontSize="md" fontWeight="bold">
                      Razão em ETI por
                      <br /> 1.000 habitantes
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados CNES 2024
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>
              <Card
                display="flex"
                justify="center"
                align="center"
                minH="0px"
                title="Número de vínculos"
              >
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Nº de Vínculos no SUS:</strong> vínculos de
                      profissionais com o SUS (administração pública direta ou
                      privado conveniado/contratualizado), podendo ser vínculos
                      empregatícios ou formas de vinculação autônomas e ou
                      esporádicas para prestação de serviço como plantões,
                      procedimentos, unidades e pacotes de consulta.
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#9683d9" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercadoSus.total_vinculos_format}
                    </Text>
                    <Text color="#9683d9" fontSize="md" fontWeight="bold">
                      Nº de vínculos
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados CNES 2024
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>
            </Grid>
          </Flex>
          <Flex direction="column" justify="space-between" h="100%">
            <Grid
              templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
              gap="20px"
            >
              <Card display="flex" justify="center" align="center" minH="0px">
                <Flex direction="column">
                  {/* <Text color={textColor} fontSize="md" fontWeight="bold">
                    Nº profissionais em ETI e Razão por 1.000 hab. por ano
                  </Text> */}
                  <ReactApexChart
                    options={dataChart.options}
                    series={dataChart.series}
                    type="line"
                    height={350}
                  />
                </Flex>
              </Card>
              <Card display="flex" justify="center" align="center" minH="0px">
                <Flex direction="column">
                  {/* <Text color={textColor} fontSize="md" fontWeight="bold">
                    Nº profissionais em ETI e Razão por 1.000 hab. por ano no
                    SUS
                  </Text> */}
                  <ReactApexChart
                    options={dataChartSus.options}
                    series={dataChartSus.series}
                    type="line"
                    height={350}
                  />
                </Flex>
              </Card>
            </Grid>
          </Flex>

          <Flex direction="column" justify="space-between" h="100%">
            <Grid
              templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
              gap="20px"
            >
              <Card display="flex" justify="center" align="center" minH="0px">
                <Text
                  color="#000"
                  fontSize="md"
                  fontWeight="regular"
                  justify="left"
                  align="left"
                >
                  Fonte: EPSM-ObservaRH/NESCON/FM/UFMG a partir dos dados do
                  Cadastro Nacional de Estabelecimentos de Saúde (CNES) do
                  Ministério da Saúde (MS).
                </Text>
              </Card>
            </Grid>
          </Flex>
        </>
      )}
    </Grid>
  );
};

export default SinaisDeMercadoTab2;
