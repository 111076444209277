import { gql } from "@apollo/client";

export const CREATE_TAG = gql`
  mutation createTag($tag: createTagInput!) {
    createTag(input: $tag) {
      tag {
        id
        _id
      }
    }
  }
`;

export const GET_TAGS = gql`
  {
    getCollectionTags {
      edges {
        node {
          _id
          name
        }
      }
    }
  }
`;
