import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  FormControl,
  Select,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import LineBarChart from "components/Charts/LineBarChart";
import {
  profissionaisEtiData,
  profissionaisEtiOptions,
  salarioHoraData,
  salarioHoraOptions,
} from "variables/charts";
import ReactApexChart from "react-apexcharts";
import {
  // SINAIS_DE_MERACDO,
  SINAIS_DE_MERACDO_RAIS,
  GET_UFS,
  GET_REGIOES_SAUDE,
  GET_MUNICIPIOS_BY_CODE,
} from "queries";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import Tooltip from "react-simple-tooltip";

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.pluz.app",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const SinaisDeMercadoTab2 = (props) => {
  const { profession } = props;

  const [ufs, setUfs] = useState([]);
  const [regioesSaude, setRegioesSaude] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  const [ufSelected, setUfSelected] = useState("total");
  const [regiaoSaudeSelected, setRegiaoSaudeSelected] = useState("total");
  const [municipioSelected, setMunicipioSelected] = useState("total");

  const [isLoading, setIsLoading] = useState(false);
  const [selectedNaturezaJuridica, setselectedNaturezaJuridica] = useState(
    "total"
  );
  const [selectedTipoDeVinculo, setSelectedTipoDeVinculo] = useState("total");

  const [sinaisDeMercado, setSinaisDeMercado] = useState(null);
  const [sinaisDeMercado2021, setSinaisDeMercado2021] = useState(null);
  const [sinaisDeMercado2020, setSinaisDeMercado2020] = useState(null);
  const [sinaisDeMercado2019, setSinaisDeMercado2019] = useState(null);
  const [sinaisDeMercado2018, setSinaisDeMercado2018] = useState(null);
  const [sinaisDeMercado2017, setSinaisDeMercado2017] = useState(null);
  const [sinaisDeMercado2016, setSinaisDeMercado2016] = useState(null);
  const [sinaisDeMercado2015, setSinaisDeMercado2015] = useState(null);
  const [sinaisDeMercado2014, setSinaisDeMercado2014] = useState(null);
  const [sinaisDeMercado2013, setSinaisDeMercado2013] = useState(null);
  const [sinaisDeMercado2012, setSinaisDeMercado2012] = useState(null);
  const [sinaisDeMercado2011, setSinaisDeMercado2011] = useState(null);
  const [sinaisDeMercado2010, setSinaisDeMercado2010] = useState(null);
  const [sinaisDeMercado2009, setSinaisDeMercado2009] = useState(null);
  const [sinaisDeMercado2008, setSinaisDeMercado2008] = useState(null);
  const [sinaisDeMercado2007, setSinaisDeMercado2007] = useState(null);
  const [sinaisDeMercado2006, setSinaisDeMercado2006] = useState(null);
  const [sinaisDeMercado2005, setSinaisDeMercado2005] = useState(null);
  const [sinaisDeMercado2004, setSinaisDeMercado2004] = useState(null);
  const [sinaisDeMercado2003, setSinaisDeMercado2003] = useState(null);

  const textColor = useColorModeValue("gray.700", "white");

  const dataChart = {
    series: [
      {
        name: "Participação Feminina",
        type: "column",
        data: [
          sinaisDeMercado2003 === null
            ? 0
            : sinaisDeMercado2003.participacao_feminina,
          sinaisDeMercado2004 === null
            ? 0
            : sinaisDeMercado2004.participacao_feminina,
          sinaisDeMercado2005 === null
            ? 0
            : sinaisDeMercado2005.participacao_feminina,
          sinaisDeMercado2006 === null
            ? 0
            : sinaisDeMercado2006.participacao_feminina,
          sinaisDeMercado2007 === null
            ? 0
            : sinaisDeMercado2007.participacao_feminina,
          sinaisDeMercado2008 === null
            ? 0
            : sinaisDeMercado2008.participacao_feminina,
          sinaisDeMercado2009 === null
            ? 0
            : sinaisDeMercado2009.participacao_feminina,
          sinaisDeMercado2010 === null
            ? 0
            : sinaisDeMercado2010.participacao_feminina,
          sinaisDeMercado2011 === null
            ? 0
            : sinaisDeMercado2011.participacao_feminina,
          sinaisDeMercado2012 === null
            ? 0
            : sinaisDeMercado2012.participacao_feminina,
          sinaisDeMercado2013 === null
            ? 0
            : sinaisDeMercado2013.participacao_feminina,
          sinaisDeMercado2014 === null
            ? 0
            : sinaisDeMercado2014.participacao_feminina,
          sinaisDeMercado2015 === null
            ? 0
            : sinaisDeMercado2015.participacao_feminina,
          sinaisDeMercado2016 === null
            ? 0
            : sinaisDeMercado2016.participacao_feminina,
          sinaisDeMercado2017 === null
            ? 0
            : sinaisDeMercado2017.participacao_feminina,
          sinaisDeMercado2018 === null
            ? 0
            : sinaisDeMercado2018.participacao_feminina,
          sinaisDeMercado2019 === null
            ? 0
            : sinaisDeMercado2019.participacao_feminina,
          sinaisDeMercado2020 === null
            ? 0
            : sinaisDeMercado2020.participacao_feminina,
          sinaisDeMercado2021 === null
            ? 0
            : sinaisDeMercado2021.participacao_feminina,
          sinaisDeMercado === null ? 0 : sinaisDeMercado.participacao_feminina,
        ],
      },
      {
        name: "Razão da Remuneração por Sexos (Fem/Masc) em %",
        type: "line",
        data: [
          sinaisDeMercado2003 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2003.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2004 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2004.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2005 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2005.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2006 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2006.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2007 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2007.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2008 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2008.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2009 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2009.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2010 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2010.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2011 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2011.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2012 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2012.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2013 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2013.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2014 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2014.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2015 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2015.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2016 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2016.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2017 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2017.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2018 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2018.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2019 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2019.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2020 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2020.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado2021 === null
            ? 0
            : parseFloat(
                sinaisDeMercado2021.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
          sinaisDeMercado === null
            ? 0
            : parseFloat(
                sinaisDeMercado.remuneracao_media_feminino_por_masculina
              ).toFixed(1),
        ],
      },
    ],
    options: {
      colors: ["#feb019", "#ae0fed"],
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        text:
          "Participação feminina e Razão da Remuneração por Sexos (Fem/Masc) em %",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
      ],
      xaxis: {
        //type: 'datetime'
        type: "category",
      },
      yaxis: [
        {
          min: 0,
          max: 100,
          title: {
            text: "Participação Feminina",
          },
        },
        {
          min: 0,
          max: 100,
          opposite: true,
          title: {
            text: "Razão da Remuneração por Sexos (Fem/Masc) em %",
          },
        },
      ],
    },
  };

  const dataChart2 = {
    series: [
      {
        name: "N. de empregos formais",
        type: "column",
        data: [
          sinaisDeMercado2003 === null
            ? 0
            : sinaisDeMercado2003.num_empregos_formais,
          sinaisDeMercado2004 === null
            ? 0
            : sinaisDeMercado2004.num_empregos_formais,
          sinaisDeMercado2005 === null
            ? 0
            : sinaisDeMercado2005.num_empregos_formais,
          sinaisDeMercado2006 === null
            ? 0
            : sinaisDeMercado2006.num_empregos_formais,
          sinaisDeMercado2007 === null
            ? 0
            : sinaisDeMercado2007.num_empregos_formais,
          sinaisDeMercado2008 === null
            ? 0
            : sinaisDeMercado2008.num_empregos_formais,
          sinaisDeMercado2009 === null
            ? 0
            : sinaisDeMercado2009.num_empregos_formais,
          sinaisDeMercado2010 === null
            ? 0
            : sinaisDeMercado2010.num_empregos_formais,
          sinaisDeMercado2011 === null
            ? 0
            : sinaisDeMercado2011.num_empregos_formais,
          sinaisDeMercado2012 === null
            ? 0
            : sinaisDeMercado2012.num_empregos_formais,
          sinaisDeMercado2013 === null
            ? 0
            : sinaisDeMercado2013.num_empregos_formais,
          sinaisDeMercado2014 === null
            ? 0
            : sinaisDeMercado2014.num_empregos_formais,
          sinaisDeMercado2015 === null
            ? 0
            : sinaisDeMercado2015.num_empregos_formais,
          sinaisDeMercado2016 === null
            ? 0
            : sinaisDeMercado2016.num_empregos_formais,
          sinaisDeMercado2017 === null
            ? 0
            : sinaisDeMercado2017.num_empregos_formais,
          sinaisDeMercado2018 === null
            ? 0
            : sinaisDeMercado2018.num_empregos_formais,
          sinaisDeMercado2019 === null
            ? 0
            : sinaisDeMercado2019.num_empregos_formais,
          sinaisDeMercado2020 === null
            ? 0
            : sinaisDeMercado2020.num_empregos_formais,
          sinaisDeMercado2021 === null
            ? 0
            : sinaisDeMercado2021.num_empregos_formais,
          sinaisDeMercado === null ? 0 : sinaisDeMercado.num_empregos_formais,
        ],
      },
      {
        name: "Remuneração média (R$) por hora",
        type: "line",
        data: [
          sinaisDeMercado2003 === null
            ? 0
            : sinaisDeMercado2003.salario_medio_por_hora,
          sinaisDeMercado2004 === null
            ? 0
            : sinaisDeMercado2004.salario_medio_por_hora,
          sinaisDeMercado2005 === null
            ? 0
            : sinaisDeMercado2005.salario_medio_por_hora,
          sinaisDeMercado2006 === null
            ? 0
            : sinaisDeMercado2006.salario_medio_por_hora,
          sinaisDeMercado2007 === null
            ? 0
            : sinaisDeMercado2007.salario_medio_por_hora,
          sinaisDeMercado2008 === null
            ? 0
            : sinaisDeMercado2008.salario_medio_por_hora,
          sinaisDeMercado2009 === null
            ? 0
            : sinaisDeMercado2009.salario_medio_por_hora,
          sinaisDeMercado2010 === null
            ? 0
            : sinaisDeMercado2010.salario_medio_por_hora,
          sinaisDeMercado2011 === null
            ? 0
            : sinaisDeMercado2011.salario_medio_por_hora,
          sinaisDeMercado2012 === null
            ? 0
            : sinaisDeMercado2012.salario_medio_por_hora,
          sinaisDeMercado2013 === null
            ? 0
            : sinaisDeMercado2013.salario_medio_por_hora,
          sinaisDeMercado2014 === null
            ? 0
            : sinaisDeMercado2014.salario_medio_por_hora,
          sinaisDeMercado2015 === null
            ? 0
            : sinaisDeMercado2015.salario_medio_por_hora,
          sinaisDeMercado2016 === null
            ? 0
            : sinaisDeMercado2016.salario_medio_por_hora,
          sinaisDeMercado2017 === null
            ? 0
            : sinaisDeMercado2017.salario_medio_por_hora,
          sinaisDeMercado2018 === null
            ? 0
            : sinaisDeMercado2018.salario_medio_por_hora,
          sinaisDeMercado2019 === null
            ? 0
            : sinaisDeMercado2019.salario_medio_por_hora,
          sinaisDeMercado2020 === null
            ? 0
            : sinaisDeMercado2020.salario_medio_por_hora,
          sinaisDeMercado2021 === null
            ? 0
            : sinaisDeMercado2021.salario_medio_por_hora,
          sinaisDeMercado === null ? 0 : sinaisDeMercado.salario_medio_por_hora,
        ],
      },
    ],
    options: {
      colors: ["#9683d9", "#ff7b8e"],
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        text: "N. de empregos formais e Remuneração média (R$) por hora",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
      ],
      xaxis: {
        //type: 'datetime'
        type: "category",
      },
      yaxis: [
        {
          min: 0,
          title: {
            text: "N. de empregos formais",
          },
        },
        {
          opposite: true,
          title: {
            text: "Remuneração média por hora",
          },
        },
      ],
    },
  };

  const process1 = async () => {
    let filterType = "";
    let filterValue = "";

    var sinaisDeMercadoStart = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          profession_code: profession.code,
          year: "2022",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado(sinaisDeMercadoStart);

    var sinaisDeMercadoStart2021 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          profession_code: profession.code,
          year: "2021",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2021(sinaisDeMercadoStart2021);

    var sinaisDeMercadoStart2020 = (
      await clientApi.query({
        variables: {
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          profession_code: profession.code,
          year: "2020",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2020(sinaisDeMercadoStart2020);

    var sinaisDeMercadoStart2019 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2019",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2019(sinaisDeMercadoStart2019);

    var sinaisDeMercadoStart2018 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2018",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2018(sinaisDeMercadoStart2018);

    var sinaisDeMercadoStart2017 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2017",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2017(sinaisDeMercadoStart2017);

    setIsLoading(false);
  };

  const process2 = async () => {
    let filterType = "";
    let filterValue = "";

    var sinaisDeMercadoStart2016 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2016",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2016(sinaisDeMercadoStart2016);

    var sinaisDeMercadoStart2015 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2015",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2015(sinaisDeMercadoStart2015);

    var sinaisDeMercadoStart2014 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2014",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2014(sinaisDeMercadoStart2014);

    var sinaisDeMercadoStart2013 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2013",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2013(sinaisDeMercadoStart2013);

    var sinaisDeMercadoStart2012 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2012",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2012(sinaisDeMercadoStart2012);
  };
  const process3 = async () => {
    let filterType = "";
    let filterValue = "";

    var sinaisDeMercadoStart2011 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2011",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2011(sinaisDeMercadoStart2011);

    var sinaisDeMercadoStart2010 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2010",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2010(sinaisDeMercadoStart2010);

    var sinaisDeMercadoStart2009 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2009",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2009(sinaisDeMercadoStart2009);

    var sinaisDeMercadoStart2008 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2008",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2008(sinaisDeMercadoStart2008);

    var sinaisDeMercadoStart2007 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2007",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2007(sinaisDeMercadoStart2007);
  };
  const process4 = async () => {
    let filterType = "";
    let filterValue = "";

    var sinaisDeMercadoStart2006 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2006",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2006(sinaisDeMercadoStart2006);

    var sinaisDeMercadoStart2005 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2005",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2005(sinaisDeMercadoStart2005);

    var sinaisDeMercadoStart2004 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2004",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2004(sinaisDeMercadoStart2004);

    var sinaisDeMercadoStart2003 = (
      await clientApi.query({
        variables: {
          profession_code: profession.code,
          naturezaJuridica: selectedNaturezaJuridica,
          tipo_vinculo: selectedTipoDeVinculo,
          year: "2003",
          uf_code: ufSelected,
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,

          filterType: filterType,
          filterValue: filterValue,
        },
        query: SINAIS_DE_MERACDO_RAIS,
      })
    ).data.sinaisDeMercadoRais;
    setSinaisDeMercado2003(sinaisDeMercadoStart2003);
  };

  useEffect(async () => {
    setIsLoading(true);

    // GET UFS
    var getUfsStart = (
      await clientApi.query({
        query: GET_UFS,
      })
    ).data.getUfs;
    setUfs(getUfsStart);

    var filterType = "";
    var filterValue = "";
    // if(municipioSelected !== "total") {
    //   filterType = "municipio";
    //   filterValue = municipioSelected;
    // }
    // else if(regiaoSaudeSelected !== "total") {
    //   filterType = "regiaoSaude";
    //   filterValue = regiaoSaudeSelected;
    // }
    // else if(ufSelected !== "total") {
    //   filterType = "uf";
    //   filterValue = ufSelected;
    // }

    process1();
    process2();
    process3();
    process4();
  }, [
    profession.code,
    selectedNaturezaJuridica,
    selectedTipoDeVinculo,
    ufSelected,
    regiaoSaudeSelected,
    municipioSelected,
  ]);

  const changeUf = async (v) => {
    setUfSelected(v);
    setRegiaoSaudeSelected("total");
    setMunicipioSelected("total");

    if (v == "total") {
      setRegioesSaude([]);
      setMunicipios([]);

      setRegiaoSaudeSelected("total");
      setMunicipioSelected("total");
    } else {
      var getRegioesDeSuadeStart = (
        await clientApi.query({
          variables: {
            uf: v,
          },
          query: GET_REGIOES_SAUDE,
        })
      ).data.getRegioesSaude;
      setRegioesSaude(getRegioesDeSuadeStart);

      var getMunicipiosStart = (
        await clientApi.query({
          variables: {
            uf: v,
          },
          query: GET_MUNICIPIOS_BY_CODE,
        })
      ).data.getMunicipios.municipios;
      setMunicipios(getMunicipiosStart);
    }
  };

  const changeMunicipio = async (v) => {
    setRegiaoSaudeSelected("total");
    setMunicipioSelected(v);
  };

  const changeRegiaoSaude = async (v) => {
    setMunicipioSelected("total");
    setRegiaoSaudeSelected(v);
  };

  if (sinaisDeMercado === null) {
    return (
      <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
        <Flex direction="column" justify="space-between" h="100%">
          <Grid gap="20px">
            <Flex direction="column">
              <Text color="#fff" fontSize="3xl" fontWeight="bold">
                Carregando...
              </Text>
              <Text color="#fff" fontSize="3xl" fontWeight="bold">
                Isso poderá levar alguns minutos.
              </Text>
            </Flex>
          </Grid>
        </Flex>
      </Grid>
    );
  }

  return (
    <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
      <Flex direction="column" justify="space-between" h="100%">
        <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="20px">
          <Card display="flex" justify="left" align="left" minH="0px">
            <Flex direction="column">
              <Text color="#000" fontSize="md" fontWeight="regular">
                Esta aba apresenta o dimensionamento da demanda efetiva da força
                de trabalho do{" "}
                <strong>mercado de trabalho assalariado formal</strong> no
                segmento da gestão e prestação de serviços de saúde, assim como
                as demais atividades do complexo ou macro setor da saúde, como
                indústria e comércio atacadista e varejista de fármacos, insumos
                e equipamentos de saúde, ensino e pesquisa em saúde e atividades
                de financiamento (planos e seguros de saúde), saneamento, etc.
              </Text>
              <Text
                color="#000"
                fontSize="md"
                fontWeight="regular"
                textDecoration="underline"
              >
                <strong>
                  <a href="/#/admin/applications/sinais-de-mercado-de-trabalho">
                    Para obter informações mais detalhadas, consulte o menu
                    Sinais do Mercado de Trabalho em Saúde
                  </a>
                </strong>
              </Text>
            </Flex>
          </Card>
        </Grid>
      </Flex>

      <Flex direction="column" justify="space-between" h="100%">
        <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }} gap="20px">
          <Card display="flex" justify="left" align="left" minH="0px">
            <strong>Filtrar por Natureza Jurídica:</strong>
            <FormControl>
              <Select
                variant="main"
                placeholder="- Selecione -"
                color="gray.400"
                fontSize={16}
                onChange={(i) => {
                  setselectedNaturezaJuridica(i.target.value);
                }}
                defaultValue={"total"}
              >
                <option value="total">TOTAL</option>
                <option value="publico_municipal">Público Municipal</option>
                <option value="publico_estadual">Público Estadual</option>
                <option value="publico_federal">Público Federal</option>
                <option value="publico_outros">Público Outros</option>
                <option value="publico">Público Total</option>
                <option value="privado">Privado Lucrativo</option>
                <option value="privado_nao_lucrativo">
                  Privado Não Lucrativo
                </option>
              </Select>
            </FormControl>
          </Card>
          <Card display="flex" justify="left" align="left" minH="0px">
            <strong>Tipo de Vínculo:</strong>
            <FormControl>
              <Select
                variant="main"
                placeholder="- Selecione -"
                color="gray.400"
                fontSize={16}
                onChange={(i) => {
                  setSelectedTipoDeVinculo(i.target.value);
                }}
                defaultValue={"total"}
              >
                <option value="total">TOTAL</option>
                <option value="clt">CLT</option>
                <option value="estatutario">Estatutário / RJU</option>
                <option value="temporario">Temporário</option>
                <option value="outros">Outros</option>
              </Select>
            </FormControl>
          </Card>
        </Grid>
      </Flex>
      <Card display="flex" justify="left" align="left" minH="0px">
        <strong>Filtrar por localidade</strong>
        <Flex direction="column" justify="space-between" h="100%">
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(4, 1fr)" }}
            gap="20px"
          >
            <Card display="flex" justify="left" align="left" minH="0px">
              <string>UF:</string>
              <FormControl>
                <Select
                  variant="main"
                  placeholder="- Selecione -"
                  color="gray.400"
                  fontSize={16}
                  onChange={(i) => {
                    changeUf(i.target.value);
                  }}
                  defaultValue={"total"}
                >
                  <option value="total">TOTAL</option>
                  {ufs.map((uf) => (
                    <option value={uf.cod}>{uf.title}</option>
                  ))}
                </Select>
              </FormControl>
            </Card>
            {/* {regioesSaude.length > 0 && (
              <Card display="flex" justify="left" align="left" minH="0px">
                <string>Macrorregião de Saúde CIR:</string>
                <FormControl>
                  <Select
                    variant="main"
                    placeholder="- Selecione -"
                    color="gray.400"
                    fontSize={16}
                    onChange={(i) => {
                      changeRegiaoSaude(i.target.value);
                    }}
                    value={regiaoSaudeSelected}
                  >
                    <option value="total">TOTAL</option>
                    {regioesSaude.map((regioaoSaude) => (
                      <option value={regioaoSaude.cod}>
                        {regioaoSaude.title}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Card>
            )} */}
            {regioesSaude.length > 0 && (
              <Card display="flex" justify="left" align="left" minH="0px">
                <string>Microrregião de Saúde CIR:</string>
                <FormControl>
                  <Select
                    variant="main"
                    placeholder="- Selecione -"
                    color="gray.400"
                    fontSize={16}
                    onChange={(i) => {
                      changeRegiaoSaude(i.target.value);
                    }}
                    value={regiaoSaudeSelected}
                  >
                    <option value="total">TOTAL</option>
                    {regioesSaude.map((regioaoSaude) => (
                      <option value={regioaoSaude.cod}>
                        {regioaoSaude.title}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Card>
            )}

            {regioesSaude.length > 0 && (
              <Card display="flex" justify="left" align="left" minH="0px">
                <string>Município:</string>
                <FormControl>
                  <Select
                    variant="main"
                    placeholder="- Selecione -"
                    color="gray.400"
                    fontSize={16}
                    onChange={(i) => {
                      changeMunicipio(i.target.value);
                    }}
                    value={municipioSelected}
                  >
                    <option value="total">TOTAL</option>
                    {municipios.map((municipio) => (
                      <option value={municipio.cod_ibge}>
                        {municipio.nom_mun_acent}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Card>
            )}
          </Grid>
        </Flex>
      </Card>

      {isLoading && (
        <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
          <Flex direction="column" justify="space-between" h="100%">
            <Grid gap="20px">
              <Flex direction="column">
                <Text color="#fff" fontSize="3xl" fontWeight="bold">
                  Carregando...
                </Text>
                <Text color="#fff" fontSize="3xl" fontWeight="bold">
                  Isso poderá levar alguns minutos.
                </Text>
              </Flex>
            </Grid>
          </Flex>
        </Grid>
      )}
      {!isLoading && (
        <>
          <Flex direction="column" justify="space-between" h="100%">
            <Grid
              templateColumns={{ sm: "1fr", md: "repeat(6, 1fr)" }}
              gap="20px"
            >
              <Card
                display="flex"
                justify="center"
                align="center"
                minH="0px"
                title="Número de vínculos formais de emprego ativos em 31/12"
              >
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Nº de empregos formais:</strong> vínculos formais
                      de emprego ativos em 31 de dezembro (estatutários,
                      celetistas regidos pela CLT ou pelos regimes específicos
                      de servidores da Administração Pública, contratos de
                      trabalho por prazo determinado regidos por legislações
                      específicas etc.).
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#9683d9" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercado.num_empregos_formais_format}
                    </Text>
                    <Text color="#9683d9" fontSize="md" fontWeight="bold">
                      N. de empregos formais
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados RAIS 2022
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>
              <Card display="flex" justify="center" align="center" minH="0px">
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Participação feminina:</strong> proporção de
                      vínculos formais de emprego ativos em 31 de dezembro que
                      são ocupados por profissionais do sexo feminino em relação
                      ao total.
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#feb019" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercado.participacao_feminina_format}
                    </Text>
                    <Text color="#feb019" fontSize="md" fontWeight="bold">
                      Participação feminina
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados RAIS 2022
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>

              <Card display="flex" justify="center" align="center" minH="0px">
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Média de horas contratadas por semana:</strong>{" "}
                      média da carga horária contratada por semana dos vínculos
                      formais de emprego ativos em 31 de dezembro.
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#00e396" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercado.qtd_hora_medio_semanal_format}h
                    </Text>
                    <Text color="#00e396" fontSize="md" fontWeight="bold">
                      Média de horas contratadas
                      <br /> por semana
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados RAIS 2022
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>
              <Card display="flex" justify="center" align="center" minH="0px">
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Remuneração média por mês:</strong> média da
                      remuneração mensal nominal ao longo do ano referente aos
                      vínculos formais de emprego ativos em 31 de dezembro
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#008ffb" fontSize="3xl" fontWeight="bold">
                      <Text as="span" fontSize="sm">
                        R$
                      </Text>{" "}
                      {sinaisDeMercado.salario_medio_por_mes_format}
                    </Text>
                    <Text color="#008ffb" fontSize="md" fontWeight="bold">
                      Remuneração média por mês
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados RAIS 2022
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>

              <Card display="flex" justify="center" align="center" minH="0px">
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Remuneração média por hora:</strong> média da
                      remuneração mensal nominal ao longo do ano, por hora
                      semanal contratada, referente aos vínculos formais de
                      emprego ativos em 31 de dezembro.
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#ff7b8e" fontSize="3xl" fontWeight="bold">
                      <Text as="span" fontSize="sm">
                        R$
                      </Text>{" "}
                      {sinaisDeMercado.salario_medio_por_hora_format}
                    </Text>
                    <Text color="#ff7b8e" fontSize="md" fontWeight="bold">
                      Remuneração média por hora
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados RAIS 2022
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>
              <Card display="flex" justify="center" align="center" minH="0px">
                <Tooltip
                  content={
                    <div style={{ width: "200px" }}>
                      <strong>Razão da Remuneração Fem/Masc: </strong> relação
                      entre a remuneração média por hora dos vínculos de
                      empregados do sexo feminino em relação ao masculino,
                      expresso em percentual.
                    </div>
                  }
                >
                  <Flex direction="column">
                    <Text color="#ae0fed" fontSize="3xl" fontWeight="bold">
                      {sinaisDeMercado.remuneracao_media_feminino_por_masculina}
                      %
                    </Text>
                    <Text color="#ae0fed" fontSize="md" fontWeight="bold">
                      Razão da Remuneração Fem/Masc
                    </Text>
                    <Text color="gray.400" fontSize="xs" fontWeight="bold">
                      Dados RAIS 2022
                    </Text>
                  </Flex>
                </Tooltip>
              </Card>
            </Grid>
          </Flex>
          <Flex direction="column" justify="space-between" h="100%">
            <Grid
              templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
              gap="20px"
            >
              <Card display="flex" justify="center" align="center" minH="0px">
                <Flex direction="column">
                  {/* <Text color={textColor} fontSize="md" fontWeight="bold">
                    N. de empregos formais e Remuneração média (R$) por hora
                  </Text> */}
                  <ReactApexChart
                    options={dataChart2.options}
                    series={dataChart2.series}
                    type="line"
                    height={350}
                  />
                </Flex>
              </Card>
              <Card display="flex" justify="center" align="center" minH="0px">
                <Flex direction="column">
                  {/* <Text color={textColor} fontSize="md" fontWeight="bold">
                    Participação feminina e Razão da Remuneração por Sexos
                    (Fem/Masc) em %
                  </Text> */}
                  <ReactApexChart
                    options={dataChart.options}
                    series={dataChart.series}
                    type="line"
                    height={350}
                  />
                </Flex>
              </Card>
            </Grid>
          </Flex>

          <Flex direction="column" justify="space-between" h="100%">
            <Grid
              templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
              gap="20px"
            >
              <Card display="flex" justify="center" align="center" minH="0px">
                <Text
                  color="#000"
                  fontSize="md"
                  fontWeight="regular"
                  justify="left"
                  align="left"
                >
                  Fonte: EPSM-ObservaRH/NESCON/FM/UFMG a partir dos dados da
                  Relação Anual de Informações Sociais (RAIS) do Ministério do
                  Trabalho e Emprego (MTE).
                </Text>
              </Card>
            </Grid>
          </Flex>
        </>
      )}
    </Grid>
  );
};

export default SinaisDeMercadoTab2;
