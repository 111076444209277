import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client";

//https:// apipainelfts.pluz.app
//http:// localhost:8585

const httpLink = new HttpLink({ uri: "https://apipainelfts.pluz.app" });

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("authToken") || "";
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
