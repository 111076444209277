import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  FormLabel,
  FormControl,
  Select,
  background,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import {
  SALARIO_POR_SEXO,
  GET_UFS,
  GET_REGIOES_SAUDE,
  GET_MUNICIPIOS_BY_CODE,
} from "queries";

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.pluz.app",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const EmpregosSalariosTab2 = (props) => {
  const {
    years,
    // ufs,
  } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const [salarioPorSexo, setSalarioPorSexo] = useState([]);
  const [year, setYear] = useState(2021);
  const [ufCode, setUfCode] = useState("0");

  const [selectItemsTable, setSelectItemsTable] = useState(false);
  const [tableItemsAll, setTableItemsAll] = useState([]);
  const [tableItemsChecked, setTableItemsChecked] = useState([]);

  //#@
  const [isLoading, setIsLoading] = useState(false);
  const [ufs, setUfs] = useState([]);
  const [regioesSaude, setRegioesSaude] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [yearSelected, setYearSelected] = useState("2022");
  const [ufSelected, setUfSelected] = useState("total");
  const [regiaoSaudeSelected, setRegiaoSaudeSelected] = useState("total");
  const [municipioSelected, setMunicipioSelected] = useState("total");

  const changeUf = async (v) => {
    setUfSelected(v);
    var uf_code = v;

    if (v == "total") {
      setRegioesSaude([]);
      setMunicipios([]);

      setRegiaoSaudeSelected("total");
      setMunicipioSelected("total");
    } else {
      var getRegioesDeSuadeStart = (
        await clientApi.query({
          variables: {
            uf: v,
          },
          query: GET_REGIOES_SAUDE,
        })
      ).data.getRegioesSaude;
      setRegioesSaude(getRegioesDeSuadeStart);

      var getMunicipiosStart = (
        await clientApi.query({
          variables: {
            uf: v,
          },
          query: GET_MUNICIPIOS_BY_CODE,
        })
      ).data.getMunicipios.municipios;
      setMunicipios(getMunicipiosStart);
    }
  };

  useEffect(async () => {
    let r = document.querySelector(":root");
    r.style.setProperty("--chakra-colors-blue-500", "#285d7d");

    setIsLoading(true);

    // GET UFS #@
    var getUfsStart = (
      await clientApi.query({
        query: GET_UFS,
      })
    ).data.getUfs;
    setUfs(getUfsStart);

    let salarioPorSexoStart = (
      await clientApi.query({
        query: SALARIO_POR_SEXO,
        variables: {
          year: String(yearSelected),
          uf_code: String(ufSelected),
          regiaosaude_code: regiaoSaudeSelected,
          municipio_code: municipioSelected,
        },
      })
    ).data.salarioPorSexo.salarioPorSexo;
    setSalarioPorSexo(salarioPorSexoStart);
    setTableItemsAll(salarioPorSexoStart);

    let tableItemsCheckedStart = [];
    salarioPorSexoStart.map((itemChecked) => {
      tableItemsCheckedStart.push(itemChecked.title);
    });
    setTableItemsChecked(tableItemsCheckedStart);

    setIsLoading(false);
  }, [yearSelected, ufSelected, regiaoSaudeSelected, municipioSelected]); //#@

  const changeCheckboxTableItemsChecked = (i) => {
    var tableItemsCheckedChange = [];
    // if(i.target.checked) {
    //   i.target.value
    // }
    var valueChecked = i.target.value;
    console.log("tableItemsChecked", tableItemsChecked);

    tableItemsAll.map((tableItem) => {
      if (
        tableItem.title !== valueChecked &&
        tableItemsChecked.includes(tableItem.title)
      ) {
        tableItemsCheckedChange.push(tableItem.title);
      } else if (
        tableItem.title === valueChecked &&
        !tableItemsChecked.includes(tableItem.title)
      ) {
        tableItemsCheckedChange.push(tableItem.title);
      }
    });

    var valuesChange = [];
    tableItemsAll.map((tableItem) => {
      if (tableItemsCheckedChange.includes(tableItem.title)) {
        valuesChange.push(tableItem);
      }
    });

    setTableItemsChecked(tableItemsCheckedChange);
    setSalarioPorSexo(valuesChange);
  };

  return (
    <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
      <Card display="flex" justify="left" align="left" minH="0px">
        <Flex direction="column" justify="space-between" h="100%">
          <Flex direction="column">
            <Flex direction="column" justify="space-between" h="100%">
              <strong>Filtrar por ano e localidade</strong>
              <Flex direction="column" justify="space-between" h="100%">
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(5, 1fr)" }}
                  gap="20px"
                >
                  <Card display="flex" justify="left" align="left" minH="0px">
                    <strong>Ano:</strong>
                    <Flex align="center" mb="20px">
                      <FormControl>
                        <Select
                          variant="main"
                          placeholder="- Selecione -"
                          color="gray.400"
                          fontSize={16}
                          onChange={(i) => {
                            setYearSelected(i.target.value);
                          }}
                          defaultValue={yearSelected}
                        >
                          {years.map((year) => (
                            <option value={year}>{year}</option>
                          ))}
                        </Select>
                      </FormControl>
                    </Flex>
                  </Card>
                  <Card display="flex" justify="left" align="left" minH="0px">
                    <string>UF:</string>
                    <FormControl>
                      <Select
                        variant="main"
                        placeholder="- Selecione -"
                        color="gray.400"
                        fontSize={16}
                        onChange={(i) => {
                          changeUf(i.target.value);
                        }}
                        defaultValue={"total"}
                      >
                        <option value="total">TOTAL</option>
                        {ufs.map((uf) => (
                          <option value={uf.cod}>{uf.title}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Card>
                  {/* {regioesSaude.length > 0 && (
                    <Card display="flex" justify="left" align="left" minH="0px">
                      <string>Macrorregião de Saúde CIR:</string>
                      <FormControl>
                        <Select
                          variant="main"
                          placeholder="- Selecione -"
                          color="gray.400"
                          fontSize={16}
                          onChange={(i) => {
                            setRegiaoSaudeSelected(i.target.value);
                            setMunicipioSelected("total");
                          }}
                          value={regiaoSaudeSelected}
                        >
                          <option value="total">TOTAL</option>
                          {regioesSaude.map((regioaoSaude) => (
                            <option value={regioaoSaude.cod}>
                              {regioaoSaude.title}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Card>
                  )} */}
                  {regioesSaude.length > 0 && (
                    <Card display="flex" justify="left" align="left" minH="0px">
                      <string>Microrregião de Saúde CIR:</string>
                      <FormControl>
                        <Select
                          variant="main"
                          placeholder="- Selecione -"
                          color="gray.400"
                          fontSize={16}
                          onChange={(i) => {
                            setRegiaoSaudeSelected(i.target.value);
                            setMunicipioSelected("total");
                          }}
                          value={regiaoSaudeSelected}
                        >
                          <option value="total">TOTAL</option>
                          {regioesSaude.map((regioaoSaude) => (
                            <option value={regioaoSaude.cod}>
                              {regioaoSaude.title}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Card>
                  )}
                  {regioesSaude.length > 0 && (
                    <Card display="flex" justify="left" align="left" minH="0px">
                      <string>Município:</string>
                      <FormControl>
                        <Select
                          variant="main"
                          placeholder="- Selecione -"
                          color="gray.400"
                          fontSize={16}
                          onChange={(i) => {
                            setRegiaoSaudeSelected("total");
                            setMunicipioSelected(i.target.value);
                          }}
                          value={municipioSelected}
                        >
                          <option value="total">TOTAL</option>
                          {municipios.map((municipio) => (
                            <option value={municipio.cod_ibge}>
                              {municipio.nom_mun_acent}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Card>
                  )}
                  <div className="clear"></div>
                </Grid>
              </Flex>
              <br />
              <Flex direction="column">
                <br />
                Profissões e Ocupações:
                {!selectItemsTable && (
                  <Button
                    variant="light"
                    mt="24px"
                    w="220px"
                    h="35px"
                    onClick={() => {
                      setSelectItemsTable(true);
                    }}
                  >
                    Selecionar Profissões e Ocupações
                  </Button>
                )}
                {selectItemsTable && (
                  <Button
                    variant="light"
                    mt="24px"
                    w="150px"
                    h="35px"
                    onClick={() => {
                      setSelectItemsTable(false);
                    }}
                  >
                    Ocultar
                  </Button>
                )}
                {selectItemsTable && (
                  <>
                    {tableItemsChecked.length > 0 && (
                      <Button
                        variant="light"
                        mt="24px"
                        w="100px"
                        h="35px"
                        onClick={() => {
                          setTableItemsChecked([]);
                          setSalarioPorSexo([]);
                        }}
                      >
                        Remover Todos
                      </Button>
                    )}
                    {tableItemsChecked.length === 0 && (
                      <Button
                        variant="light"
                        mt="24px"
                        w="100px"
                        h="35px"
                        onClick={() => {
                          setTableItemsChecked(
                            tableItemsAll.map((item) => item.title)
                          );
                          setSalarioPorSexo(tableItemsAll);
                        }}
                      >
                        Selecionar todos
                      </Button>
                    )}
                    <div className="form-checkbox">
                      {tableItemsAll.map((tableItem, i) => (
                        <FormControl key={i}>
                          <input
                            type="checkbox"
                            value={tableItem.title}
                            checked={tableItemsChecked.includes(
                              tableItem.title
                            )}
                            onChange={(i) => changeCheckboxTableItemsChecked(i)}
                          />{" "}
                          {tableItem.title}
                        </FormControl>
                      ))}
                    </div>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
          <br />
          <hr />
          <br />
        </Flex>
        <Flex align="flex-end" mb="0px">
          <div>
            <strong>
              Remuneração média, remuneração média por hora (R$) de trabalho e
              índice salarial por profissões ou ocupações de saúde, segundo
              sexo.
            </strong>
            <br />
            {isLoading && (
              <Grid
                templateColumns={{ sm: "1fr", lg: "1fr" }}
                gap="20px"
                my="20px"
              >
                <Flex direction="column" justify="space-between" h="100%">
                  <Grid gap="20px">
                    <Flex direction="column">
                      <Text color="#000" fontSize="3xl" fontWeight="bold">
                        Carregando...
                      </Text>
                      <Text color="#000" fontSize="3xl" fontWeight="bold">
                        Isso poderá levar alguns minutos.
                      </Text>
                    </Flex>
                  </Grid>
                </Flex>
              </Grid>
            )}
            {!isLoading && (
              <table className="table-filter width-auto">
                <tr>
                  <th></th>
                  <th colSpan={3}>Remuneração Média</th>
                  <th colSpan={3} className="cell-background">
                    Remuneração média por
                    <br /> hora (R$)
                  </th>
                  <th colSpan={3}>Índice Salarial (%)</th>
                </tr>
                <tr>
                  <th className="cell-background">Ocupação</th>
                  <th>Masculino</th>
                  <th>Feminino</th>
                  <th>Total</th>
                  <th className="cell-background">Masculino</th>
                  <th className="cell-background">Feminino</th>
                  <th className="cell-background">Total</th>
                  <th>Masculino</th>
                  <th>Feminino</th>
                  <th>Total</th>
                </tr>
                {salarioPorSexo.map((salarioPorSexo_item) => (
                  <tr key={salarioPorSexo_item.code}>
                    <td className="cell-background">
                      {salarioPorSexo_item.title}
                    </td>
                    <td align="center">
                      <strong>
                        {parseFloat(
                          salarioPorSexo_item.remuneracaoMedia.masculino
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </strong>
                    </td>
                    <td align="center">
                      <strong>
                        {parseFloat(
                          salarioPorSexo_item.remuneracaoMedia.feminino
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </strong>
                    </td>
                    <td align="center">
                      <strong>
                        {parseFloat(
                          salarioPorSexo_item.remuneracaoMedia.total
                        ).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </strong>
                    </td>
                    <td align="center" className="cell-background">
                      <strong>
                        {parseFloat(
                          salarioPorSexo_item.remuneracaoHoraMedia.masculino
                        ).toLocaleString("pt-br", { minimumFractionDigits: 2 })}
                      </strong>
                    </td>
                    <td align="center" className="cell-background">
                      <strong>
                        {parseFloat(
                          salarioPorSexo_item.remuneracaoHoraMedia.feminino
                        ).toLocaleString("pt-br", { minimumFractionDigits: 2 })}
                      </strong>
                    </td>
                    <td align="center" className="cell-background">
                      <strong>
                        {parseFloat(
                          salarioPorSexo_item.remuneracaoHoraMedia.total
                        ).toLocaleString("pt-br", { minimumFractionDigits: 2 })}
                      </strong>
                    </td>
                    <td align="center">
                      <strong>
                        {parseFloat(
                          salarioPorSexo_item.percentualPorMedico.masculino
                        ).toLocaleString("pt-br", { minimumFractionDigits: 2 })}
                      </strong>
                    </td>
                    <td align="center">
                      <strong>
                        {parseFloat(
                          salarioPorSexo_item.percentualPorMedico.feminino
                        ).toLocaleString("pt-br", { minimumFractionDigits: 2 })}
                      </strong>
                    </td>
                    <td align="center">
                      <strong>
                        {parseFloat(
                          salarioPorSexo_item.percentualPorMedico.total
                        ).toLocaleString("pt-br", { minimumFractionDigits: 2 })}
                      </strong>
                    </td>
                  </tr>
                ))}
              </table>
            )}
          </div>
        </Flex>
      </Card>
    </Grid>
  );
};

export default EmpregosSalariosTab2;
