import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  FormControl,
  FormLabel,
  Select,
  StatLabel,
  SimpleGrid,
  Stat,
} from "@chakra-ui/react";
import bgWeather from "assets/img/BgMusicCard.png";
import bgWeatherDark from "assets/img/bgMusicCardDark.png";
import smartHome from "assets/img/smart-home.png";
import sunBehindCloud from "assets/img/sun-behind-cloud.png";
import Card from "components/Card/Card";
import BarChart from "components/Charts/BarChart";
import { HSeparator } from "components/Separator/Separator";
import VisxPieChart from "components/VisxPieChart/VisxPieChart";
import CircularSlider from "react-circular-slider-svg";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiWater, BiWifi } from "react-icons/bi";
import { BsFillRecordCircleFill, BsThermometerHigh } from "react-icons/bs";
import { FaPlus, FaSnowflake } from "react-icons/fa";
import { IoBulbOutline, IoEllipsisVerticalSharp } from "react-icons/io5";
import {
  barChartDataDashboardPage,
  barChartOptionsDashboardPage,
} from "variables/charts";
import { rooms } from "variables/general";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

import EmpregosSalariosTab1 from "./components/EmpregosSalarios/Tab1";
import EmpregosSalariosTab2 from "./components/EmpregosSalarios/Tab2";
import EmpregosSalariosTab3 from "./components/EmpregosSalarios/Tab3";
import EmpregosSalariosTab4 from "./components/EmpregosSalarios/Tab4";
import EmpregosSalariosTab5 from "./components/EmpregosSalarios/Tab5";
import EmpregosSalariosTab6 from "./components/EmpregosSalarios/Tab6";
import EmpregosSalariosTab7 from "./components/EmpregosSalarios/Tab7";
import EmpregosSalariosTab8 from "./components/EmpregosSalarios/Tab8";
import EmpregosSalariosTab9 from "./components/EmpregosSalarios/Tab9";
import EmpregosSalariosTab10 from "./components/EmpregosSalarios/Tab10";
import EmpregosSalariosTab11 from "./components/EmpregosSalarios/Tab11";
import EmpregosSalariosTab12 from "./components/EmpregosSalarios/Tab12";
import MercadoPofissionalOcupacionalCnes from "./components/cnes/MercadoPofissionalOcupacionalCnes";

import Applications from "views/Pages/Applications/Applications.js";

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.pluz.app",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const DashboardPage = () => {
  const { colorMode } = useColorMode();
  const [distritc, setDistrict] = useState("");
  const [typeValue, setTypeValue] = useState("1");
  const [activeTab, setActiveTab] = useState("1");
  const bgButtonGroup = useColorModeValue("gray.200", "navy.700");
  const bgActiveButton = useColorModeValue("#fff", "navy.800");

  const years = [
    2022,
    2021,
    2020,
    2019,
    2018,
    2017,
    2016,
    2015,
    2014,
    2013,
    2012,
    2011,
    2010,
    2009,
    2008,
    2007,
    2006,
    2005,
    2004,
    2003,
  ];

  const ufs = [
    { code: "11", name: "RO" },
    { code: "12", name: "AC" },
    { code: "13", name: "AM" },
    { code: "14", name: "RR" },
    { code: "15", name: "PA" },
    { code: "16", name: "AP" },
    { code: "17", name: "TO" },
    { code: "21", name: "MA" },
    { code: "22", name: "PI" },
    { code: "23", name: "CE" },
    { code: "24", name: "RN" },
    { code: "25", name: "PB" },
    { code: "26", name: "PE" },
    { code: "27", name: "AL" },
    { code: "28", name: "SE" },
    { code: "29", name: "BA" },
    { code: "31", name: "MG" },
    { code: "32", name: "ES" },
    { code: "33", name: "RJ" },
    { code: "35", name: "SP" },
    { code: "41", name: "PR" },
    { code: "42", name: "SC" },
    { code: "43", name: "RS" },
    { code: "50", name: "MS" },
    { code: "51", name: "MT" },
    { code: "52", name: "GO" },
    { code: "53", name: "DF" },
  ];

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "0px" }}>
      <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px">
        <Card>
          <Card>
            <Flex direction="column">
              <SimpleGrid columns={{ sm: 1, xl: 1 }}>
                <Stat>
                  <StatLabel
                    fontSize="24"
                    color="#285D7D"
                    fontWeight="bold"
                    textTransform="uppercase"
                    textAlign="center"
                  >
                    Sinais do Mercado de Trabalho em Saúde
                  </StatLabel>
                </Stat>
              </SimpleGrid>
            </Flex>
          </Card>
          <br />
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify="space-between"
            align={{ md: "center" }}
            mb="0px"
          >
            <Flex align="center">
              <Flex bg={bgButtonGroup} borderRadius="10px" p="6px" me="10px">
                <Button
                  variant="no-effects"
                  w={{ sm: "fit-content", xl: "170px" }}
                  h="40px"
                  fontSize="xs"
                  boxShadow={
                    activeTab === "1"
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={activeTab === "1" ? bgActiveButton : "transparent"}
                  onClick={() => setActiveTab("1")}
                >
                  MERCADO SETORIAL
                </Button>
                {/* <Button
                  variant='no-effects'
                  w={{ sm: "fit-content", xl: "300px" }}
                  h='40px'
                  fontSize='xs'
                  boxShadow={
                    activeTab === "2"
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={activeTab === "2" ? bgActiveButton : "transparent"}
                  onClick={() =>
                    setActiveTab("2")
                  }>
                  ATIVIDADE ECONÔMICA DO ESTABELECIMENTO
                </Button> */}
                <Button
                  variant="no-effects"
                  w={{ sm: "fit-content", xl: "300px" }}
                  h="40px"
                  fontSize="xs"
                  boxShadow={
                    activeTab === "1-cnes" ||
                    activeTab === "2" ||
                    activeTab === "3" ||
                    activeTab === "4" ||
                    activeTab === "7" ||
                    activeTab === "8" ||
                    activeTab === "11"
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={
                    activeTab === "1-cnes" ||
                    activeTab === "2" ||
                    activeTab === "3" ||
                    activeTab === "4" ||
                    activeTab === "7" ||
                    activeTab === "8" ||
                    activeTab === "11"
                      ? bgActiveButton
                      : "transparent"
                  }
                  onClick={() => setActiveTab("2")}
                >
                  MERCADO PROFISSIONAL OU OCUPACIONAL
                </Button>
                {/* <Button
                  variant='no-effects'
                  w={{ sm: "fit-content", xl: "180px" }}
                  h='40px'
                  fontSize='xs'
                  boxShadow={
                    activeButton.tab4
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={activeButton.tab4 ? bgActiveButton : "transparent"}
                  onClick={() =>
                    setActiveButton({
                      tab1: false,
                      tab2: false,
                      tab3: false,
                      tab4: true,
                      tab5: false,
                      tab6: false,
                    })
                  }>
                    SEXO
                </Button> */}

                {/* <Button
                  variant="no-effects"
                  w={{ sm: "fit-content", xl: "240px" }}
                  h="40px"
                  fontSize="xs"
                  boxShadow={
                    activeTab === "mercado-profissional-cnes"
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={
                    activeTab === "mercado-profissional-cnes"
                      ? bgActiveButton
                      : "transparent"
                  }
                  onClick={() => setActiveTab("mercado-profissional-cnes")}
                >
                  MERCADO PROFISSIONAL
                  <br />
                  OU OCUPACIONAL - CNES
                </Button> */}

                <Button
                  variant="no-effects"
                  w={{ sm: "fit-content", xl: "150px" }}
                  h="40px"
                  fontSize="xs"
                  boxShadow={
                    activeTab === "5" ||
                    activeTab === "6" ||
                    activeTab === "9" ||
                    activeTab === "10" ||
                    activeTab === "12"
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={
                    activeTab === "5" ||
                    activeTab === "6" ||
                    activeTab === "9" ||
                    activeTab === "10" ||
                    activeTab === "12"
                      ? bgActiveButton
                      : "transparent"
                  }
                  onClick={() => setActiveTab("5")}
                >
                  REMUNERAÇÃO
                </Button>
                {/* <Button
                  variant="no-effects"
                  w={{ sm: "fit-content", xl: "150px" }}
                  h="40px"
                  fontSize="xs"
                  boxShadow={
                    activeTab === "matriz-tipo-de-servicos"
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={
                    activeTab === "matriz-tipo-de-servicos"
                      ? bgActiveButton
                      : "transparent"
                  }
                  onClick={() => setActiveTab("matriz-tipo-de-servicos")}
                >
                  MATRIZ DE TIPOS DE <br />
                  SERVIÇOS - CNES
                </Button> */}
              </Flex>
            </Flex>
          </Flex>
        </Card>
        <Card>
          {(activeTab === "1-cnes" ||
            activeTab === "2" ||
            activeTab === "3" ||
            activeTab === "4" ||
            activeTab === "7" ||
            activeTab === "8" ||
            activeTab === "11") && (
            <Grid
              templateColumns={{ sm: "1fr", lg: "1fr" }}
              gap="20px"
              my="20px"
            >
              <Card display="flex" justify="left" align="left" minH="0px">
                <Card>
                  <Flex
                    direction={{ sm: "column", md: "row" }}
                    justify="space-between"
                    align={{ md: "center" }}
                    mb="0px"
                  >
                    <Flex align="center">
                      <Flex
                        bg={bgButtonGroup}
                        borderRadius="10px"
                        p="6px"
                        me="10px"
                      >
                        <Button
                          variant="no-effects"
                          w={{ sm: "fit-content", xl: "230px" }}
                          h="40px"
                          fontSize="xs"
                          boxShadow={
                            activeTab === "2" ||
                            activeTab === "3" ||
                            activeTab === "4" ||
                            activeTab === "7" ||
                            activeTab === "8" ||
                            activeTab === "11"
                              ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                              : "none"
                          }
                          bg={
                            activeTab === "2" ||
                            activeTab === "3" ||
                            activeTab === "4" ||
                            activeTab === "7" ||
                            activeTab === "8" ||
                            activeTab === "11"
                              ? bgActiveButton
                              : "transparent"
                          }
                          onClick={() => setActiveTab("2")}
                        >
                          MERCADO FORMAL - RAIS
                        </Button>
                        <Button
                          variant="no-effects"
                          w={{ sm: "fit-content", xl: "270px" }}
                          h="40px"
                          fontSize="xs"
                          boxShadow={
                            activeTab === "1-cnes"
                              ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                              : "none"
                          }
                          bg={
                            activeTab === "1-cnes"
                              ? bgActiveButton
                              : "transparent"
                          }
                          onClick={() => setActiveTab("1-cnes")}
                        >
                          ESTABELECIMENTOS DE SAÚDE - CNES
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                </Card>

                <Flex direction="column">
                  {activeTab === "1-cnes" ? (
                    <div>
                      <Text color="#000" fontSize="md" fontWeight="regular">
                        Nesta aba são apresentados dados dos mercados de
                        trabalho das profissões e ocupações de saúde,
                        considerando o recorte do segmento da assistência,
                        vigilância e gestão da saúde. Para tanto são utilizados
                        os dados do Cadastro Nacional de Estabelecimentos de
                        Saúde (CNES) do Ministério da Saúde (MS).
                      </Text>
                    </div>
                  ) : (
                    <div>
                      <Text color="#000" fontSize="md" fontWeight="regular">
                        Nesta aba são apresentados dados das profissões e
                        ocupações de saúde, que são categorias utilizadas para
                        se referir a grupos de trabalhadores que desempenham
                        funções, atribuições, ações ou procedimentos que
                        requerem formação ou capacitação prática ou acadêmica
                        específica na área da saúde. Navegue pelos campos abaixo
                        para definir a tabela de consulta.
                      </Text>
                      <Text
                        color="#000"
                        fontSize="md"
                        fontWeight="bold"
                        textDecoration="underline"
                      >
                        <a href="/#/admin/applications/atlas-das-profissoes-e-ocupacoes-de-saude">
                          Para mais detalhes, consulte o menu Panorama por
                          profissão e ocupação de saúde
                        </a>
                      </Text>
                      <br />
                      <hr />
                      <br />
                      Selecione o conteúdo das colunas:
                      <FormControl>
                        <Select
                          variant="main"
                          placeholder="- Selecione -"
                          color="gray.400"
                          fontSize={16}
                          onChange={(i) => {
                            setActiveTab(i.target.value);
                          }}
                          defaultValue={activeTab}
                        >
                          <option value="2">
                            ATIVIDADE ECONÔMICA DO ESTABELECIMENTO
                          </option>
                          <option value="3">
                            NATUREZA JURÍDICA DO ESTABELECIMENTO
                          </option>
                          <option value="11">TIPO DE VÍNCULO</option>
                          <option value="4">SEXO</option>
                          <option value="7">RAÇA/COR</option>
                          <option value="8">SEXO + RAÇA/COR</option>
                        </Select>
                      </FormControl>
                      {(activeTab === "2" ||
                        activeTab === "3" ||
                        activeTab === "7" ||
                        activeTab === "8") && (
                        <>
                          Selecione a medida (N e %):
                          <FormControl>
                            <Select
                              variant="main"
                              placeholder="- Selecione -"
                              color="gray.400"
                              fontSize={16}
                              onChange={(i) => {
                                setTypeValue(i.target.value);
                              }}
                              defaultValue={typeValue}
                            >
                              <option value="1">NÚMERO ABSOLUTO</option>
                              <option value="2">PORCENTAGEM</option>
                            </Select>
                          </FormControl>
                        </>
                      )}
                    </div>
                  )}
                </Flex>
              </Card>
            </Grid>
          )}
          {(activeTab === "5" ||
            activeTab === "6" ||
            activeTab === "9" ||
            activeTab === "10" ||
            activeTab === "12") && (
            <Grid
              templateColumns={{ sm: "1fr", lg: "1fr" }}
              gap="20px"
              my="20px"
            >
              <Card display="flex" justify="left" align="left" minH="0px">
                <Flex direction="column">
                  <Text color="#000" fontSize="md" fontWeight="regular">
                    Nesta aba são apresentados dados de remuneração das
                    profissões e ocupações de saúde no segmento da economia
                    formal a partir dos dados da Relação Anual de Informações
                    Sociais (RAIS) do Ministério do Trabalho e Emprego (MTE).
                  </Text>
                </Flex>
              </Card>
              <Card display="flex" justify="left" align="left" minH="0px">
                <Flex direction="column">
                  Selecione o conteúdo das colunas:
                  <FormControl>
                    <Select
                      variant="main"
                      placeholder="- Selecione -"
                      color="gray.400"
                      fontSize={16}
                      onChange={(i) => {
                        setActiveTab(i.target.value);
                      }}
                      defaultValue={activeTab}
                    >
                      <option value="5">
                        NATUREZA JURÍDICA DO ESTABELECIMENTO
                      </option>
                      <option value="12">TIPO DE VÍNCULO</option>
                      <option value="6">SEXO</option>
                      <option value="9">RAÇA/COR</option>
                      <option value="10">SEXO + RAÇA/COR</option>
                    </Select>
                  </FormControl>
                </Flex>
              </Card>
            </Grid>
          )}

          {activeTab === "1" && (
            <EmpregosSalariosTab1 years={years} ufs={ufs} />
          )}
          {activeTab === "2" && (
            <EmpregosSalariosTab2
              years={years}
              ufs={ufs}
              typeValue={typeValue}
            />
          )}

          {
            // Vínculos
          }
          {activeTab === "3" && (
            <EmpregosSalariosTab3
              years={years}
              ufs={ufs}
              typeValue={typeValue}
            />
          )}
          {activeTab === "4" && (
            <EmpregosSalariosTab4 years={years} ufs={ufs} />
          )}
          {activeTab === "7" && (
            <EmpregosSalariosTab7
              years={years}
              ufs={ufs}
              typeValue={typeValue}
            />
          )}
          {activeTab === "8" && (
            <EmpregosSalariosTab8
              years={years}
              ufs={ufs}
              typeValue={typeValue}
            />
          )}
          {activeTab === "11" && (
            <EmpregosSalariosTab11
              years={years}
              ufs={ufs}
              typeValue={typeValue}
            />
          )}

          {
            // REMUNERAÇÃO
          }
          {activeTab === "5" && (
            <EmpregosSalariosTab5 years={years} ufs={ufs} />
          )}
          {activeTab === "6" && (
            <EmpregosSalariosTab6 years={years} ufs={ufs} />
          )}
          {activeTab === "9" && (
            <EmpregosSalariosTab9
              years={years}
              ufs={ufs}
              typeValue={typeValue}
            />
          )}
          {activeTab === "10" && (
            <EmpregosSalariosTab10
              years={years}
              ufs={ufs}
              typeValue={typeValue}
            />
          )}
          {activeTab === "12" && (
            <EmpregosSalariosTab12
              years={years}
              ufs={ufs}
              typeValue={typeValue}
            />
          )}

          {activeTab === "1-cnes" && (
            <MercadoPofissionalOcupacionalCnes
              years={years}
              ufs={ufs}
              typeValue={typeValue}
            />
          )}

          <br />
          <hr />
          <br />
          {activeTab === "1-cnes" ? (
            <Text color="#000" fontSize="md" fontWeight="regular">
              Fonte: EPSM-ObservaRH/NESCON/FM/UFMG a partir dos dados do
              Cadastro Nacional de Estabelecimentos de Saúde (CNES) do Minstério
              da Saúde (MS).
            </Text>
          ) : (
            <Text color="#000" fontSize="md" fontWeight="regular">
              Fonte: Relação Anual de Informações Sociais (RAIS) do Ministério
              do Trabalho e Emprego (MTE).
            </Text>
          )}
        </Card>
      </Grid>
    </Flex>
  );
};

export default DashboardPage;
