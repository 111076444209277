import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/provider";
import theme from "theme/theme.js";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import LandingLayout from "layouts/Landing";
import { ApolloProvider } from "@apollo/client/react";
import client from "services/apolloClient";
import { RecoilRoot } from "recoil";

ReactDOM.render(
  <RecoilRoot>
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme} resetCss={false} position="relative">
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            <Route path={`/landing`} component={LandingLayout} />
            {/* <Redirect from={`/`} to="/auth/login" /> */}
            <Redirect from={`/`} to="/admin/applications/home" />
          </Switch>
        </HashRouter>
      </ChakraProvider>
    </ApolloProvider>
  </RecoilRoot>,
  document.getElementById("root")
);
