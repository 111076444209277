import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation loginUser($input: loginUserInput!) {
    loginUser(input: $input) {
      user {
        firstName
        lastName
        country
        token
      }
    }
  }
`;

export const LOGIN_FACE_ID = gql`
  mutation loginFaceImageUser($input: loginFaceImageUserInput!) {
    loginFaceImageUser(input: $input) {
      user {
        firstName
        lastName
        country
        token
      }
    }
  }
`;

export const GET_AUTH_USER = gql`
  {
    getAuthUser {
      country
      email
      firstName
      lastName
      groups {
        edges {
          node {
            _id
            name
          }
        }
      }
      account {
        _id
        limitUsers
        modules {
          edges {
            node {
              _id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_USERS_BY_AUTH_USER = gql`
  {
    getAuthUser {
      country
      email
      firstName
      lastName
      account {
        _id
        limitUsers
        users {
          edges {
            node {
              id
              _id
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

// export const GET_USERS = gql`
//   {
//     getUsers {
//       id
//       name
//       email
//     }
//   }
// `;

export const VIEW_USERS = gql`
  query($id: Int) {
    getUserInfo(id: $id) {
      id
      name
      email
    }
  }
`;

export const ADD_USER = gql`
  mutation($name: String, $email: String, $password: String) {
    createUser(name: $name, email: $email, password: $password)
  }
`;
