import { gql } from "@apollo/client";

export const GET_INEP_GERAL_COLLECTION = gql`
  query getInepGeralCollection(
    $year: Int
    $tp_rede: Int
    $tp_categoria_administrativa: String
    $tp_modalidade_ensino: Int
    $co_regiao: Int
    $co_uf: Int
    $co_municipio: Int
  ) {
    getInepGeralCollection(
      year: $year
      tp_rede: $tp_rede
      tp_categoria_administrativa: $tp_categoria_administrativa
      tp_modalidade_ensino: $tp_modalidade_ensino
      co_regiao: $co_regiao
      co_uf: $co_uf
      co_municipio: $co_municipio
    ) {
      inepGeral {
        co_cine_rotulo
        no_cine_rotulo
        count_co_ies
        qt_curso
        qt_vg_total
        qt_inscrito_total
        qt_ing
        qt_mat
        qt_conc
        razaoSexo
        taxaAproveitamento
        taxaInscritosPorVaga
        taxaAbandono
        abandono

        qt_vg_total_format
        qt_inscrito_total_format
        qt_ing_format
        qt_mat_format
        qt_conc_format
        razaoSexo_format
        taxaAproveitamento_format
        taxaInscritosPorVaga_format
        taxaAbandono_format
      }
    }
  }
`;

export const GET_INEP_REGIOES = gql`
  {
    getInepRegioes {
      cod
      title
    }
  }
`;

export const GET_INEP_UFS = gql`
  query getInepUfs($cod_regiao: Int) {
    getInepUfs(cod_regiao: $cod_regiao) {
      cod
      title
    }
  }
`;

export const GET_INEP_MUNICIPIOS = gql`
  query getInepMunicipios($cod_uf: Int) {
    getInepMunicipios(cod_uf: $cod_uf) {
      cod
      title
    }
  }
`;

export const COUNT_TIPO_ADMISSAO = gql`
  query countTipoAdmissao(
    $year: Int!
    $tipoAdimissao: String!
    $professionNum: Int!
  ) {
    countTipoAdmissao(
      year: $year
      tipoAdimissao: $tipoAdimissao
      professionNum: $professionNum
    ) {
      count
    }
  }
`;

export const GET_INEP_COURSES = gql`
  {
    getInepCourses {
      ord
      co_cine_rotulo
      no_cine_rotulo
      num_anos
    }
  }
`;

export const GET_INEP_MAP_CIRCLES = gql`
  query getInepMapCircles($co_cine_rotulo: String) {
    getInepMapCircles(co_cine_rotulo: $co_cine_rotulo) {
      lat
      long
      co_cine_rotulo
      no_cine_rotulo
      count_co_ies
      qt_curso
      qt_vg_total
      qt_inscrito_total
      qt_ing
      qt_mat
      qt_conc
      razaoSexo
      taxaAproveitamento
      taxaInscritosPorVaga
      taxaAbandono
      abandono

      qt_vg_total_format
      qt_inscrito_total_format
      qt_ing_format
      qt_mat_format
      qt_conc_format
      razaoSexo_format
      taxaAproveitamento_format
      taxaInscritosPorVaga_format
      taxaAbandono_format
    }
  }
`;
