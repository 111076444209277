import { gql } from "@apollo/client";

const CREATE_TESTE = gql`
  mutation createTeste($name: String!) {
    createTeste(input: { name: $name }) {
      teste {
        _id
        name
      }
    }
  }
`;

const GET_TESTES = gql`
  query {
    testes {
      totalCount
      edges {
        node {
          _id
          name
        }
      }
    }
  }
`;

export { CREATE_TESTE, GET_TESTES };
