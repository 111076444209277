/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Icon,
  Image,
  Input,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

import logo from "assets/img/logo.png";

import logoNescon from "assets/img/logos/logo-nescon.png";
import logoFaculdadeMedicina from "assets/img/logos/logoFaculdadeMedicina.png";
import logoUFMG from "assets/img/logos/logoUFMG.jpg";
import logoFiocruz from "assets/img/logos/logoFiocruz.jpg";
import logoMinisterioSaude from "assets/img/logos/logoMinisterioSaude.png";
import logoRedeObservaRH from "assets/img/logos/logoRedeObservaRH.jpg";
import logoRedeColaborativa from "assets/img/logos/logoRedeColaborativa.png";

import avatar10 from "assets/img/avatars/avatar10.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import handBg from "assets/img/hand-background.png";
import teamsImage from "assets/img/teams-image.png";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import { BitcoinLogo } from "components/Icons/Icons";
// Custom icons
import {
  AdobexdLogo,
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  JiraLogo,
  RocketIcon,
  SettingsIcon,
  SlackLogo,
  SpotifyLogo,
  StatsIcon,
  Table2,
  Table1,
} from "components/Icons/Icons.js";
import { HSeparator } from "components/Separator/Separator";
import TablesReportsRow from "components/Tables/TablesReportsRow";
import TablesTableRow from "components/Tables/TablesTableRow";
import React, { useState, useEffect } from "react";
import { AiFillLike, AiOutlinePlus } from "react-icons/ai";
import {
  FaChevronDown,
  FaChevronUp,
  FaCommentDots,
  FaUser,
} from "react-icons/fa";
import { IoMdShareAlt } from "react-icons/io";
import { IoBulb } from "react-icons/io5";
import { RiArrowDropRightLine } from "react-icons/ri";
import {
  lineChartDataDefault,
  lineChartOptionsDefault,
} from "variables/charts";
import { tablesReportsData, tablesTableData } from "variables/general";

export default function Default() {
  // Chakra Color Mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const cardColor = useColorModeValue("gray.800", "navy.800");
  const bgBox = useColorModeValue("gray.800", "blue.500");

  const { colorMode } = useColorMode();

  useEffect(async () => {
    //#285d7d
    let r = document.querySelector(":root");
    r.style.setProperty("--chakra-colors-blue-500", "#285d7d");
  }, []);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "0" }}>
      <Grid
        templateColumns={{ sm: "1fr", xl: "1fr" }}
        templateRows={{ xl: "auto auto" }}
        gap="20px"
        mb="20px"
      >
        <Card minH="125px">
          <Flex direction="column">
            <SimpleGrid columns={{ sm: 1, xl: 3 }}>
              <img align="right" src={logo} width={289} />
              <Stat me="auto">
                <StatLabel
                  fontSize="24"
                  color="#285D7D"
                  fontWeight="bold"
                  textTransform="uppercase"
                  textAlign="center"
                >
                  PAINEL DA FORÇA DE TRABALHO <br />
                  EM SAÚDE NO BRASIL
                </StatLabel>
              </Stat>
              <div></div>
            </SimpleGrid>
          </Flex>
        </Card>
      </Grid>
      <SimpleGrid columns={{ sm: 1, md: 3, xl: 3 }} spacing="24px" mb="20px">
        <Card minH="125px" _hover={{ bg: "blue.50" }}>
          <a href="/#/admin/applications/atlas-das-profissoes-e-ocupacoes-de-saude">
            <Flex direction="column">
              <Flex
                flexDirection="row"
                align="center"
                justify="center"
                w="100%"
                mb="25px"
              >
                <Stat me="auto">
                  <StatLabel
                    fontSize="lg"
                    color="#000"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Panorama por profissão e ocupação de saúde
                  </StatLabel>
                </Stat>
                <IconBox
                  borderRadius="50%"
                  as="box"
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}
                >
                  <StatsIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              <Text color="gray.400" fontSize="sm">
                <Text as="span" color="green.400" fontWeight="bold">
                  Abrir
                </Text>
              </Text>
            </Flex>
          </a>
        </Card>

        <Card minH="125px" _hover={{ bg: "blue.50" }}>
          <a href="/#/admin/applications/sinais-de-mercado-de-trabalho">
            <Flex direction="column">
              <Flex
                flexDirection="row"
                align="center"
                justify="center"
                w="100%"
                mb="25px"
              >
                <Stat me="auto">
                  <StatLabel
                    fontSize="lg"
                    color="#000"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Sinais do Mercado de Trabalho em Saúde
                  </StatLabel>
                </Stat>
                <IconBox
                  borderRadius="50%"
                  as="box"
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}
                >
                  <Table2 h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              <Text color="gray.400" fontSize="sm">
                <Text as="span" color="green.400" fontWeight="bold">
                  Abrir
                </Text>
              </Text>
            </Flex>
          </a>
        </Card>

        <Card minH="125px" _hover={{ bg: "blue.50" }}>
          <a href="/#/admin/applications/matriz-de-tipos-de-servicos">
            <Flex direction="column">
              <Flex
                flexDirection="row"
                align="center"
                justify="center"
                w="100%"
                mb="25px"
              >
                <Stat me="auto">
                  <StatLabel
                    fontSize="lg"
                    color="#000"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Matriz de tipos de serviços - CNES
                  </StatLabel>
                </Stat>
                <IconBox
                  borderRadius="50%"
                  as="box"
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}
                >
                  <Table1 h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              <Text color="gray.400" fontSize="sm">
                <Text as="span" color="green.400" fontWeight="bold">
                  Abrir
                </Text>
              </Text>
            </Flex>
          </a>
        </Card>
      </SimpleGrid>

      <SimpleGrid columns={{ sm: 1, md: 3, xl: 3 }} spacing="24px" mb="20px">
        <Card minH="125px" _hover={{ bg: "blue.50" }}>
          <a href="/#/admin/applications/fluxo-formacao">
            <Flex direction="column">
              <Flex
                flexDirection="row"
                align="center"
                justify="center"
                w="100%"
                mb="25px"
              >
                <Stat me="auto">
                  <StatLabel
                    fontSize="lg"
                    color="#000"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Fluxos da Formação
                  </StatLabel>
                </Stat>
                <IconBox
                  borderRadius="50%"
                  as="box"
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}
                >
                  <Table1 h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              <Text color="gray.400" fontSize="sm">
                <Text as="span" color="green.400" fontWeight="bold">
                  Abrir
                </Text>
              </Text>
            </Flex>
          </a>
        </Card>
        <Card minH="125px" _hover={{ bg: "blue.50" }}>
          <a>
            <Flex direction="column">
              <Flex
                flexDirection="row"
                align="center"
                justify="center"
                w="100%"
                mb="25px"
              >
                <Stat me="auto">
                  <StatLabel
                    fontSize="lg"
                    color="#000"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Tabelas Dinâmicas
                  </StatLabel>
                </Stat>
                <IconBox
                  borderRadius="50%"
                  as="box"
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}
                >
                  <StatsIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              <Text color="gray.400" fontSize="sm">
                <Text as="span" color="orange" fontWeight="bold">
                  Em breve
                </Text>
              </Text>
            </Flex>
          </a>
        </Card>

        <Card minH="125px" _hover={{ bg: "blue.50" }}>
          <a>
            <Flex direction="column">
              <Flex
                flexDirection="row"
                align="center"
                justify="center"
                w="100%"
                mb="25px"
              >
                <Stat me="auto">
                  <StatLabel
                    fontSize="lg"
                    color="#000"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Regulação Profissional em Saúde
                  </StatLabel>
                </Stat>
                <IconBox
                  borderRadius="50%"
                  as="box"
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}
                >
                  <Table2 h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              <Text color="gray.400" fontSize="sm">
                <Text as="span" color="orange" fontWeight="bold">
                  Em breve
                </Text>
              </Text>
            </Flex>
          </a>
        </Card>

        {/* 
        <Card minH="125px" _hover={{ bg: "blue.50" }}>
          <a>
            <Flex direction="column">
              <Flex
                flexDirection="row"
                align="center"
                justify="center"
                w="100%"
                mb="25px"
              >
                <Stat me="auto">
                  <StatLabel
                    fontSize="lg"
                    color="#000"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    Desequilíbrios e iniquidades na distribuição da FTS
                  </StatLabel>
                </Stat>
                <IconBox
                  borderRadius="50%"
                  as="box"
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}
                >
                  <Table1 h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
              <Text color="gray.400" fontSize="sm">
                <Text as="span" color="green.400" fontWeight="bold">
                  EM BREVE
                </Text>
              </Text>
            </Flex>
          </a>
        </Card> 
        */}
      </SimpleGrid>

      <Grid
        templateColumns={{ sm: "1fr", xl: "1fr" }}
        templateRows={{ xl: "auto auto" }}
        gap="20px"
        mb="20px"
      >
        <Card p="28px 10px 0px 0px" mb={{ sm: "26px", lg: "0px" }}>
          <Flex direction="column" mb="20px" ps="22px">
            {/*
            <Text color="#000" fontSize='md' fontWeight='regular' mb="24px">
              A análise e o monitoramento dos dados sobre a Força de Trabalho em Saúde (FTS) e sua disponibilização em tempo oportuno são essenciais para orientar a ação de gestores governamentais, trabalhadores da saúde, pesquisadores e acadêmicos, organizações profissionais e do trabalho, provedores de serviços e usuários do sistema de saúde.
              </Text>
            */}

            <Text color="#000" fontSize="md" fontWeight="regular" mb="24px">
              Este painel fornece informações de fontes públicas e atualizadas
              sobre a Força de Trabalho em Saúde (FTS) no Brasil. Na versão
              atual são disponibilizados dados sobre a estrutura e dinâmica dos
              mercados de trabalho setoriais e profissionais ou ocupacionais da
              saúde e sobre os fluxos da formação das profissões de saúde de
              nível superior. Informações sobre a regulamentação das profissões
              de saúde e medidas e métricas para identificar desequilíbrios e
              desigualdades na distribuição da FTS também serão disponibilizadas
              em breve.
            </Text>
            <Text color="#000" fontSize="md" fontWeight="regular" mb="24px">
              O painel foi desenvolvido pela{" "}
              <u>
                <a
                  target="_blank"
                  href="http://epsm.nescon.medicina.ufmg.br/epsm/"
                >
                  Estação de Pesquisa de Sinais de Mercado (EPSM)/ObservaRH
                </a>
              </u>
              , um centro de pesquisa acadêmica e de mercado, que integra o
              Núcleo de Educação em Saúde Coletiva da Faculdade de Medicina da
              Universidade Federal de Minas Gerais (NESCON/FM/UFMG) e a Rede de
              Observatórios de Recursos Humanos em Saúde da OPAS/OMS. Criada em
              1999, a EPSM tem como missão o monitoramento dos sinais de mercado
              de trabalho em saúde e o desenvolvimento de metodologias de
              pesquisa e avaliação na área da FTS.
            </Text>
            <Text color="#000" fontSize="md" fontWeight="regular" mb="24px">
              Críticas e sugestões são bem-vindas no e-mail
              epsm@nescon.medicina.ufmg.br
            </Text>

            <SimpleGrid
              columns={{ sm: 1, md: 7, xl: 7 }}
              spacing="5px"
              mb="20px"
            >
              <img src={logoNescon} className="logoFooter" />
              <img src={logoFaculdadeMedicina} className="logoFooter" />
              <img src={logoUFMG} className="logoFooter" />
              <img src={logoFiocruz} className="logoFooter" />
              <img src={logoMinisterioSaude} className="logoFooter" />
              <img src={logoRedeObservaRH} className="logoFooter" />
              <img src={logoRedeColaborativa} className="logoFooter" />
            </SimpleGrid>
          </Flex>
        </Card>
      </Grid>
    </Flex>
  );
}
