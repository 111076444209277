import { gql } from "@apollo/client";

export const CREATE_QUANTY = gql`
  mutation createQuanty($quanty: createQuantyInput!) {
    createQuanty(input: $quanty) {
      quanty {
        _id
      }
    }
  }
`;

export const GET_QUANTITIES = gql`
  {
    cnesReport {
      name
      numRows
      items {
        name
        numRows
        items {
          name
          total
        }
      }
    }
  }
`;
