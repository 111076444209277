import {
  Table2,
  HomeIcon,
  PersonIcon,
  StatsIcon,
  SettingsIcon,
  Table1,
} from "components/Icons/Icons";

import Applications from "views/Pages/Applications/Applications.js";
import SinaisDeMercado from "views/Dashboard/SinaisDeMercado.js";
import EmpregosSalarios from "views/Dashboard/EmpregosSalarios.js";
import Default from "views/Dashboard/Default.js";
import FluxoFormacao from "views/Dashboard/FluxoFormacao.js";
import DynamicDashboard from "views/Dashboard/DynamicDashboard.js";

const dashRoutes = [
  {
    name: "MENU",
    category: "pages",
    items: [
      {
        name: "Primeira página",
        component: Default,
        path: "/applications/home",
        layout: "/admin",
        icon: <HomeIcon color="inherit" />,
        collapse: false,
      },
      {
        name: "Panorama por profissão e ocupação de saúde",
        component: SinaisDeMercado,
        path: "/applications/atlas-das-profissoes-e-ocupacoes-de-saude",
        layout: "/admin",
        icon: <StatsIcon color="inherit" />,
        collapse: false,
      },
      {
        name: "Sinais do Mercado de Trabalho em Saúde",
        component: EmpregosSalarios,
        path: "/applications/sinais-de-mercado-de-trabalho",
        layout: "/admin",
        icon: <Table2 color="inherit" />,
        collapse: false,
      },
      {
        name: "Matriz de tipos de serviços - CNES",
        component: Applications,
        path: "/applications/matriz-de-tipos-de-servicos",
        layout: "/admin",
        icon: <Table1 color="inherit" />,
        collapse: false,
      },
      {
        name: "Fluxos de formação",
        component: FluxoFormacao,
        path: "/applications/fluxo-formacao",
        layout: "/admin",
        icon: <Table2 color="inherit" />,
        collapse: false,
      },
      // {
      //   name: "Painel dinâmico",
      //   component: DynamicDashboard,
      //   path: "/applications/dynamic-dashboard",
      //   layout: "/admin",
      //   icon: <Table2 color="inherit" />,
      //   collapse: false,
      // },
    ],
  },
];

export default dashRoutes;
