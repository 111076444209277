import { gql } from "@apollo/client";

export const DYNAMIC_REPORT = gql`
  query dynamicReport(
    $database: String!
    $tableCol: String!
    $tableRow: String!
    $contentValue: String!
    $contentCalc: String!
  ) {
    dynamicReport(
      database: $database
      tableCol: $tableCol
      tableRow: $tableRow
      contentValue: $contentValue
      contentCalc: $contentCalc
    ) {
      title
      table {
        title
        rows {
          cels {
            value
          }
        }
      }
    }
  }
`;

export const GET_UFS = gql`
  {
    getUfs {
      cod
      title
    }
  }
`;

export const GET_REGIOES_SAUDE = gql`
  query getRegioesSaude($uf: String!) {
    getRegioesSaude(uf: $uf) {
      cod
      title
    }
  }
`;

export const GET_REGIOES_SAUDE_MACRO = gql`
  query getRegioesSaudeMacro($uf: String!) {
    getRegioesSaudeMacro(uf: $uf) {
      cod
      title
    }
  }
`;

export const CNES_VINCULOS = gql`
  query cnaeVinculos($year: String, $uf_code: String) {
    cnaeVinculos(year: $year, uf_code: $uf_code) {
      name
      total
      totalPrevious
      increment
    }
  }
`;

export const SALARIO_POR_NATUREZA_JURIDICA = gql`
  query salarioPorNaturezaJuridica(
    $year: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    salarioPorNaturezaJuridica(
      year: $year
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      salarioPorNaturezaJuridica {
        title
        code
        remuneracaoMedia {
          total
          privado
          privado_nao_lucrativo
          publico
          publico_municipal
          publico_estadual
          publico_federal
          publico_outros
        }
        remuneracaoHoraMedia {
          total
          privado
          privado_nao_lucrativo
          publico
          publico_municipal
          publico_estadual
          publico_federal
          publico_outros
        }
        percentualPorMedico {
          total
          privado
          privado_nao_lucrativo
          publico
          publico_municipal
          publico_estadual
          publico_federal
          publico_outros
        }
      }
    }
  }
`;

export const SALARIO_TIPO_VINCULO_AGREGACAO = gql`
  query salarioPorTipoVinculoAgregacao(
    $year: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    salarioPorTipoVinculoAgregacao(
      year: $year
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      salarioPorTipoVinculoAgregacao {
        title
        code
        remuneracaoMedia {
          total
          clt
          estatutario
          temporario
        }
        remuneracaoHoraMedia {
          total
          clt
          estatutario
          temporario
        }
        percentualPorMedico {
          total
          clt
          estatutario
          temporario
        }
      }
    }
  }
`;

export const SALARIO_POR_SEXO = gql`
  query salarioPorSexo(
    $year: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    salarioPorSexo(
      year: $year
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      salarioPorSexo {
        title
        code
        remuneracaoMedia {
          total
          masculino
          feminino
        }
        remuneracaoHoraMedia {
          total
          masculino
          feminino
        }
        percentualPorMedico {
          total
          masculino
          feminino
        }
      }
    }
  }
`;

export const SALARIO_POR_RACA_COR = gql`
  query salarioPorRacaCor(
    $year: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    salarioPorRacaCor(
      year: $year
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      salarioPorRacaCor {
        title
        code
        remuneracaoMedia {
          total
          branca
          indigena
          preta
          amarela
          parda
          nao_ident
        }
        remuneracaoHoraMedia {
          total
          branca
          indigena
          preta
          amarela
          parda
          nao_ident
        }
        percentualPorMedico {
          total
          branca
          indigena
          preta
          amarela
          parda
          nao_ident
        }
      }
    }
  }
`;

export const SALARIO_POR_SEXO_RACA_COR = gql`
  query salarioPorSexoRacaCor(
    $year: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    salarioPorSexoRacaCor(
      year: $year
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      salarioPorSexoRacaCor {
        title
        code
        remuneracaoMedia {
          feminino_total
          feminino_branca
          feminino_indigena
          feminino_preta
          feminino_amarela
          feminino_parda
          feminino_nao_ident

          masculino_total
          masculino_branca
          masculino_indigena
          masculino_preta
          masculino_amarela
          masculino_parda
          masculino_nao_ident
        }
        remuneracaoHoraMedia {
          feminino_total
          feminino_branca
          feminino_indigena
          feminino_preta
          feminino_amarela
          feminino_parda
          feminino_nao_ident

          masculino_total
          masculino_branca
          masculino_indigena
          masculino_preta
          masculino_amarela
          masculino_parda
          masculino_nao_ident
        }
        percentualPorMedico {
          feminino_total
          feminino_branca
          feminino_indigena
          feminino_preta
          feminino_amarela
          feminino_parda
          feminino_nao_ident

          masculino_total
          masculino_branca
          masculino_indigena
          masculino_preta
          masculino_amarela
          masculino_parda
          masculino_nao_ident
        }
      }
    }
  }
`;

export const SINAIS_DE_MERACDO_CNES = gql`
  query sinaisDeMercadoCnes(
    $year: String
    $profession_code: String
    $naturezaJuridica: String
    $cod_vinculacao: String
    $cod_vinculo_agregado: String
    $isSus: String
    $uf_code: String
    $regiaosaude_code: String
    $regiaosaudeMacro_code: String
    $municipio_code: String
    $filterType: String
    $filterValue: String
  ) {
    sinaisDeMercadoCnes(
      year: $year
      profession_code: $profession_code
      naturezaJuridica: $naturezaJuridica
      cod_vinculacao: $cod_vinculacao
      cod_vinculo_agregado: $cod_vinculo_agregado
      isSus: $isSus
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      regiaosaudeMacro_code: $regiaosaudeMacro_code
      municipio_code: $municipio_code
      filterType: $filterType
      filterValue: $filterValue
    ) {
      eti
      eti_format
      total_cpfs
      total_cpfs_format
      razao_1000_hab
      razao_1000_hab_format
      total_vinculos
      total_vinculos_format
    }
  }
`;

export const SINAIS_DE_MERACDO_RAIS = gql`
  query sinaisDeMercadoRais(
    $year: String
    $profession_code: String
    $naturezaJuridica: String
    $tipo_vinculo: String
    $isSus: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
    $filterType: String
    $filterValue: String
  ) {
    sinaisDeMercadoRais(
      year: $year
      profession_code: $profession_code
      naturezaJuridica: $naturezaJuridica
      tipo_vinculo: $tipo_vinculo
      isSus: $isSus
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
      filterType: $filterType
      filterValue: $filterValue
    ) {
      num_empregos_formais
      salario_medio_por_hora
      num_empregos_formais_format
      salario_medio_por_hora_format
      salario_medio_por_mes
      qtd_hora_medio_semanal
      participacao_feminina
      remuneracao_media_feminino_por_masculina
      salario_medio_por_mes_format
      qtd_hora_medio_semanal_format
      participacao_feminina_format
      remuneracao_media_feminino_por_masculina_format
    }
  }
`;

export const SINAIS_DE_MERACDO = gql`
  query sinaisDeMercado(
    $year: String
    $profession_code: String
    $naturezaJuridica: String
    $isSus: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
    $filterType: String
    $filterValue: String
  ) {
    sinaisDeMercado(
      year: $year
      profession_code: $profession_code
      naturezaJuridica: $naturezaJuridica
      isSus: $isSus
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
      filterType: $filterType
      filterValue: $filterValue
    ) {
      eti
      razao_1000_hab
      num_empregos_formais
      salario_medio_por_hora
      eti_format
      razao_1000_hab_format
      num_empregos_formais_format
      salario_medio_por_hora_format

      total_vinculos
      total_cpfs
      salario_medio_por_mes
      qtd_hora_medio_semanal
      participacao_feminina
      remuneracao_media_feminino_por_masculina

      total_vinculos_format
      total_cpfs_format
      salario_medio_por_mes_format
      qtd_hora_medio_semanal_format
      participacao_feminina_format
      remuneracao_media_feminino_por_masculina_format
    }
  }
`;

export const SINAIS_DE_MERACDO_SUS = gql`
  query sinaisDeMercadoSus(
    $year: String
    $profession_code: String
    $naturezaJuridica: String
    $filterType: String
    $filterValue: String
  ) {
    sinaisDeMercadoSus(
      year: $year
      profession_code: $profession_code
      naturezaJuridica: $naturezaJuridica
      filterType: $filterType
      filterValue: $filterValue
    ) {
      razao_1000_hab
      razao_1000_hab_format
      eti
      eti_format
      total_vinculos
      total_vinculos_format
      total_cpfs
      total_cpfs_format
    }
  }
`;

export const MACRO_SETOR = gql`
  {
    macroSetor {
      macroSetor {
        title
        values {
          year
          gender {
            title
            value
          }
        }
        subitems {
          title
          subitems {
            title
            values {
              year
              gender {
                title
                value
              }
            }
          }
          values {
            year
            gender {
              title
              value
            }
          }
        }
      }
    }
  }
`;

export const CNES_MERCADO_PROFISSIONAL_OCUPACIONAL = gql`
  query cnesMercadoProfissionalOuOcupaacional(
    $type_content: String
    $year: String
    $ind_sus: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    cnesMercadoProfissionalOuOcupaacional(
      type_content: $type_content
      year: $year
      ind_sus: $ind_sus
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      cnesMercadoProfissionalOuOcupaacional {
        title
        code
        cnesMercadoProfissionalOuOcupaacional {
          total
          sus
          nao_sus
          publico
          publico_municipal
          publico_estadual
          publico_federal
          publico_outros
          privado
          privado_nao_lucrativo
          natureza_juridica_outros

          ubs
          hospital
          pa
          ambulatorio
          sadt
          cod_tipo_agregado_outros

          vinculo_empregaticio
          vinculo_autonomo
          residencia_estagio
          intermediada
          cod_vinculacao_outros

          estatutario
          celetista
          temporario
          autonomo
          bolsista
          cod_vinculo_agregado_outro
        }
      }
    }
  }
`;

export const IBGE_SUBSETOR = gql`
  query ibgeSubsetor(
    $year: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    ibgeSubsetor(
      year: $year
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      ibgeSubsetor {
        title
        code
        ibgeSubsetor {
          total
          outros
          ibgesub_21
          ibgesub_22
          ibgesub_23
          ibgesub_24
          outrosPercent
          ibgesub_21Percent
          ibgesub_22Percent
          ibgesub_23Percent
          ibgesub_24Percent
        }
      }
    }
  }
`;

export const VINCULOS_NATUREZA_JURIDICA = gql`
  query naturezaJuridica(
    $year: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    naturezaJuridica(
      year: $year
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      naturezaJuridica {
        title
        code
        naturezaJuridica {
          total
          outros
          outrosPercentual

          publico
          publicoPercentual

          publico_municipal
          publico_municipalPercentual

          publico_estadual
          publico_estadualPercentual

          publico_federal
          publico_federalPercentual

          publico_outros
          publico_outrosPercentual

          privado
          privadoPercentual
          privado_nao_lucrativo
          privado_nao_lucrativoPercentual
        }
      }
    }
  }
`;

export const VINCULOS_TIPO_VINCULO_AGREGACAO = gql`
  query tipoVinculoAgregacao(
    $year: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    tipoVinculoAgregacao(
      year: $year
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      tipoVinculoAgregacao {
        title
        code
        tipoVinculoAgregacao {
          total
          outros
          outrosPercentual
          clt
          cltPercentual
          estatutario
          estatutarioPercentual
          temporario
          temporarioPercentual
        }
      }
    }
  }
`;

export const VINCULOS_SEXO = gql`
  query vinculosSexo(
    $year: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    vinculosSexo(
      year: $year
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      vinculosSexo {
        title
        code
        vinculosSexo {
          total
          outros
          outrosPercentual
          masculino
          masculinoPercentual
          feminino
          femininoPercentual
        }
      }
    }
  }
`;

export const VINCULOS_RACA_COR = gql`
  query vinculosRacaCor(
    $year: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    vinculosRacaCor(
      year: $year
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      vinculosRacaCor {
        title
        code
        vinculosRacaCor {
          total
          outros
          total
          branca
          brancaPercentual
          indigena
          indigenaPercentual
          preta
          pretaPercentual
          amarela
          amarelaPercentual
          parda
          pardaPercentual
          nao_ident
          nao_identPercentual
        }
      }
    }
  }
`;

export const VINCULOS_SEXO_RACA_COR = gql`
  query vinculosSexoRacaCor(
    $year: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
  ) {
    vinculosSexoRacaCor(
      year: $year
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
    ) {
      vinculosSexoRacaCor {
        title
        code
        vinculosSexoRacaCor {
          feminino_total
          feminino_outros
          feminino_total
          feminino_branca
          feminino_brancaPercentual
          feminino_indigena
          feminino_indigenaPercentual
          feminino_preta
          feminino_pretaPercentual
          feminino_amarela
          feminino_amarelaPercentual
          feminino_parda
          feminino_pardaPercentual
          feminino_nao_ident
          feminino_nao_identPercentual
          masculino_total
          masculino_outros
          masculino_total
          masculino_branca
          masculino_brancaPercentual
          masculino_indigena
          masculino_indigenaPercentual
          masculino_preta
          masculino_pretaPercentual
          masculino_amarela
          masculino_amarelaPercentual
          masculino_parda
          masculino_pardaPercentual
          masculino_nao_ident
          masculino_nao_identPercentual
        }
      }
    }
  }
`;

export const CNES_REPORT = gql`
  query cnesReport($columns: [Filter], $filter: [Filter]) {
    cnesReport(columns: $columns, filter: $filter) {
      report {
        name
        numRows
        items {
          name
          numRows
          items {
            name
            numRows
            items {
              name
              items {
                name
                total
              }
            }
          }
        }
      }
      filters {
        profession
        specialty
      }
    }
  }
`;

export const CNES_REPORT2 = gql`
  query cnesReport2($columns: [Filter], $filter: [Filter], $type: String) {
    cnesReport2(columns: $columns, filter: $filter, type: $type) {
      report {
        name
        numRows
        items {
          name
          numRows
          items {
            name
            numRows
            items {
              name
              total
            }
          }
        }
      }
      filters {
        profession
        specialty
      }
    }
  }
`;

export const CNES_REPORT3 = gql`
  query cnesReport3(
    $columns: [Filter]
    $filter: [Filter]
    $type: String
    $year: String
    $profession_code: String
    $natureza_juridica: String
    $occupation_code: String
    $uf_code: String
    $regiaosaude_code: String
    $municipio_code: String
    $isSus: String
    $tipoVinculacaoComEstabelecimento: String
  ) {
    cnesReport3(
      columns: $columns
      filter: $filter
      type: $type
      year: $year
      profession_code: $profession_code
      natureza_juridica: $natureza_juridica
      occupation_code: $occupation_code
      uf_code: $uf_code
      regiaosaude_code: $regiaosaude_code
      municipio_code: $municipio_code
      isSus: $isSus
      tipoVinculacaoComEstabelecimento: $tipoVinculacaoComEstabelecimento
    ) {
      report {
        name
        numRows
        items {
          name
          numRows
          items {
            name
            numRows
            items {
              name
              total
            }
          }
        }
      }
      filters {
        profession
        specialty
      }
    }
  }
`;

export const GET_SPECIALTIES = gql`
  query getSpecialties($type: String!) {
    getSpecialties(type: $type) {
      specialties {
        id
        cod
        name
      }
    }
  }
`;

export const GET_PROFESSIONS = gql`
  query getProfessions {
    getProfessions {
      professions {
        id
        title
        code
        ind_prof_ativos
        tex_descricao
        tex_formacao
        qtd_prof_ativos
        txt_ocupacoes
        tex_atuacao
        dt_prof_ativos
      }
    }
  }
`;

export const GET_MUNICIPIOS = gql`
  query getMunicipios($uf: String!, $regiaoSaude: String) {
    getMunicipios(uf: $uf, regiaoSaude: $regiaoSaude) {
      municipios {
        nom_mun_acent
        cod_ibge7
        nom_mun_acent
        num_area
        num_lat_long
      }
    }
  }
`;
export const GET_MUNICIPIOS_BY_CODE = gql`
  query getMunicipios(
    $uf: String!
    $regiaoSaudeMacro: String
    $regiaoSaude: String
  ) {
    getMunicipios(
      uf: $uf
      byCode: true
      regiaoSaudeMacro: $regiaoSaudeMacro
      regiaoSaude: $regiaoSaude
    ) {
      municipios {
        nom_mun_acent
        cod_ibge7
        cod_ibge
        nom_mun_acent
        num_area
        num_lat_long
      }
    }
  }
`;

export const GET_CBO_FAMILY_COLLECTION = gql`
  query {
    getCboFamlilyCollection {
      cboFamily {
        code_family
        occupational_family
      }
    }
  }
`;

export const GET_CBO_OCCUPACION_COLLECTION = gql`
  query getCboOccupationCollection($code_family: String) {
    getCboOccupationCollection(code_family: $code_family) {
      cbo {
        code
        code_num
        occupation
      }
    }
  }
`;

export const GET_CBO_OCCUPACION_BY_CODE_INDEX_COLLECTION = gql`
  query getCboOccupationByIndexCollection($code_index: String) {
    getCboOccupationByIndexCollection(code_index: $code_index) {
      cbo {
        code
        code_num
        occupation
      }
    }
  }
`;
