import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  FormLabel,
  FormControl,
  Select,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { MACRO_SETOR } from "queries";

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.pluz.app",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const EmpregosSalariosTab2 = (props) => {
  const { ufs } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const [macroSetor, setmacroSetor] = useState([]);
  const [year, setYear] = useState(2021);
  const [ufCode, setUfCode] = useState("0");

  const [selectItemsTable, setSelectItemsTable] = useState(false);
  const [tableItemsAll, setTableItemsAll] = useState([]);
  const [tableItemsChecked, setTableItemsChecked] = useState([]);

  useEffect(async () => {
    let r = document.querySelector(":root");
    r.style.setProperty("--chakra-colors-blue-500", "#285d7d");

    let macroSetorStart = (
      await clientApi.query({
        query: MACRO_SETOR,
      })
    ).data.macroSetor.macroSetor;
    console.log("macroSetorStart", macroSetorStart);
    setmacroSetor(macroSetorStart);
    setTableItemsAll(macroSetorStart);
  }, [year, ufCode]);

  return (
    <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
      <Card display="flex" justify="left" align="left" minH="0px">
        <Flex direction="column" justify="space-between" h="100%">
          <Flex direction="column">
            <Flex direction="column" justify="space-between" h="100%">
              <Text color="#000" fontSize="md" fontWeight="regular">
                Nesta aba são apresentados dados para o conjunto das atividades
                do denominado <strong>Macrossetor Saúde</strong>. Para tanto,
                são apresentados os números de postos de trabalho em{" "}
                <strong>
                  atividades diretas de prestação de serviços de saúde
                </strong>
                , como hospitais, unidades básicas de saúde, pronto atendimentos
                etc., mas também em <strong>atividades indiretas</strong>, como
                indústria e comércio de fármacos, insumos e equipamentos de
                saúde, serviços de saneamento, ensino e pesquisa em saúde e
                atividades de financiamento (planos e seguros de saúde).
              </Text>
              <Text color="#000" fontSize="md" fontWeight="regular">
                Os dados consideram o recorte do segmento da economia formal e
                foram coletados da Relação Anual de Informações Sociais (RAIS)
                do Ministério do Trabalho e Emprego (MTE).
              </Text>

              <hr />
              <br />

              <Text color="#000" fontSize="md" fontWeight="bold">
                Nº de vínculos formais de emprego segundo atividades do
                Macrossetor Saúde por ano e incremento no período.
              </Text>
              <br />

              <Flex align="flex-end" mb="0px">
                <div>
                  <table className="table-filter width-auto">
                    <tr>
                      <th colSpan={2} rowSpan={2} className="cell-background">
                        Classe de atividade econômica
                      </th>
                      <th colSpan={3}>2010</th>
                      <th colSpan={3} className="cell-background">
                        2022
                      </th>
                      <th colSpan={3}>Incremento Bruto (%)</th>
                    </tr>
                    <tr>
                      <th>Masc</th>
                      <th className="cell-background">Fem</th>
                      <th>Total</th>
                      <th className="cell-background">Masc</th>
                      <th>Fem</th>
                      <th className="cell-background">Total</th>
                      <th>Inc. Bruto Masc</th>
                      <th className="cell-background">Inc. Bruto Fem</th>
                      <th>Inc. Bruto Total</th>
                    </tr>
                    {macroSetor.map((macroSetorItem, i) => (
                      <React.Fragment>
                        <tr key={i}>
                          <td
                            width={250}
                            colSpan={2}
                            className="cell-background"
                          >
                            <string className="bold">
                              {macroSetorItem.title}
                            </string>
                          </td>
                          {macroSetorItem.values.map((value, iiii) => (
                            <React.Fragment key={iiii} className="no-padding">
                              {value.gender.map((valueGender, iiiii) => (
                                <td
                                  key={iiiii}
                                  width={120}
                                  className={
                                    "text-right td-value " +
                                    ((iiii % 2 === 0
                                      ? iiiii % 2 !== 0
                                      : iiiii % 2 === 0) && "cell-background")
                                  }
                                >
                                  {/* y: {value.year}<br />
                                        g: {valueGender.title}<br /> */}
                                  <strong>
                                    {new Intl.NumberFormat("pt-BR").format(
                                      parseInt(Math.round(valueGender.value))
                                    )}
                                  </strong>
                                </td>
                              ))}
                            </React.Fragment>
                          ))}
                          <td>
                            {
                              // Inc. Bruto Masc
                            }
                            <strong>
                              {parseFloat(
                                (macroSetorItem.values[1].gender[0].value /
                                  macroSetorItem.values[0].gender[0].value -
                                  1) *
                                  100
                              ).toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                              })}
                            </strong>
                          </td>
                          <td className="cell-background">
                            {
                              // Inc. Bruto Fem
                            }
                            <strong>
                              {parseFloat(
                                (macroSetorItem.values[1].gender[1].value /
                                  macroSetorItem.values[0].gender[1].value -
                                  1) *
                                  100
                              ).toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                              })}
                            </strong>
                          </td>
                          <td>
                            {
                              // Inc. Bruto Total
                            }
                            <strong>
                              {parseFloat(
                                (macroSetorItem.values[1].gender[2].value /
                                  macroSetorItem.values[0].gender[2].value -
                                  1) *
                                  100
                              ).toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                              })}
                            </strong>
                          </td>
                        </tr>

                        {typeof macroSetorItem.subitems !== "undefined" &&
                          macroSetorItem.subitems !== null &&
                          macroSetorItem.subitems.map((subitem, ii) => (
                            <React.Fragment key={ii}>
                              <tr>
                                <td></td>
                                <td className="cell-background" width={400}>
                                  <strong>{subitem.title}</strong>
                                </td>
                                {subitem.values.map((value, iiii) => (
                                  <React.Fragment
                                    key={iiii}
                                    className="no-padding"
                                  >
                                    {value.gender.map((valueGender, iiiii) => (
                                      <td
                                        key={iiiii}
                                        width={120}
                                        className={
                                          "text-right td-value " +
                                          ((iiii % 2 === 0
                                            ? iiiii % 2 !== 0
                                            : iiiii % 2 === 0) &&
                                            "cell-background")
                                        }
                                      >
                                        {/* y: {value.year}<br />
                                                g: {valueGender.title}<br /> */}
                                        {new Intl.NumberFormat("pt-BR").format(
                                          parseInt(valueGender.value)
                                        )}
                                      </td>
                                    ))}
                                  </React.Fragment>
                                ))}

                                {
                                  // Incremto Bruto
                                }
                                <td>
                                  {
                                    // Inc. Bruto Masc
                                  }
                                  {parseFloat(
                                    (subitem.values[1].gender[0].value /
                                      subitem.values[0].gender[0].value -
                                      1) *
                                      100
                                  ).toLocaleString("pt-BR", {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                                <td className="cell-background">
                                  {
                                    // Inc. Bruto Fem
                                  }
                                  {parseFloat(
                                    (subitem.values[1].gender[1].value /
                                      subitem.values[0].gender[1].value -
                                      1) *
                                      100
                                  ).toLocaleString("pt-BR", {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                                <td>
                                  {
                                    // Inc. Bruto Total
                                  }
                                  {parseFloat(
                                    (subitem.values[1].gender[2].value /
                                      subitem.values[0].gender[2].value -
                                      1) *
                                      100
                                  ).toLocaleString("pt-BR", {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                              {subitem.subitems !== null &&
                                subitem.subitems.map((sub_subitem, iii) => (
                                  <tr key={iii}>
                                    <td></td>
                                    <td className="cell-background" width={250}>
                                      <string>{sub_subitem.title}</string>
                                    </td>
                                    {sub_subitem.values.map((value, iiii) => (
                                      <React.Fragment
                                        key={iiii}
                                        className="no-padding"
                                      >
                                        {value.gender.map(
                                          (valueGender, iiiii) => (
                                            <td
                                              key={iiiii}
                                              width={120}
                                              className={
                                                "text-right td-value " +
                                                ((iiii % 2 === 0
                                                  ? iiiii % 2 !== 0
                                                  : iiiii % 2 === 0) &&
                                                  "cell-background")
                                              }
                                            >
                                              {/* y: {value.year}<br />
                                                g: {valueGender.title}<br /> */}
                                              {new Intl.NumberFormat(
                                                "pt-BR"
                                              ).format(
                                                parseInt(valueGender.value)
                                              )}
                                            </td>
                                          )
                                        )}
                                      </React.Fragment>
                                    ))}
                                    {
                                      // Incremto Bruto
                                    }
                                    <td>
                                      {
                                        // Inc. Bruto Masc
                                      }
                                      {parseFloat(
                                        (sub_subitem.values[1].gender[0].value /
                                          sub_subitem.values[0].gender[0]
                                            .value -
                                          1) *
                                          100
                                      ).toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                    <td className="cell-background">
                                      {
                                        // Inc. Bruto Fem
                                      }
                                      {parseFloat(
                                        (sub_subitem.values[1].gender[1].value /
                                          sub_subitem.values[0].gender[1]
                                            .value -
                                          1) *
                                          100
                                      ).toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                    <td>
                                      {
                                        // Inc. Bruto Total
                                      }
                                      {parseFloat(
                                        (sub_subitem.values[1].gender[2].value /
                                          sub_subitem.values[0].gender[2]
                                            .value -
                                          1) *
                                          100
                                      ).toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                  </tr>
                                ))}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                  </table>
                </div>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Grid>
  );
};

export default EmpregosSalariosTab2;
