import { gql } from "@apollo/client";

export const CREATE_PROJECT = gql`
  mutation createProject($project: createProjectInput!) {
    createProject(input: $project) {
      project {
        id
        _id
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  {
    getCollectionProjects {
      edges {
        node {
          _id
          name
          owner {
            firstName
            lastName
            email
          }
          dateCreated
        }
      }
    }
  }
`;
