import { gql } from "@apollo/client";

export const ADD_MODULE_ACCOUNT = gql`
  mutation addModuleAccount($accountModule: addModuleAccountInput!) {
    addModuleAccount(input: $accountModule) {
      account {
        id
        _id
      }
    }
  }
`;

export const REMOVE_MODULE_ACCOUNT = gql`
  mutation removeModuleAccount($accountModule: removeModuleAccountInput!) {
    removeModuleAccount(input: $accountModule) {
      account {
        id
        _id
      }
    }
  }
`;

export const UPDATE_LIMIT_USERS_ACCOUNT = gql`
  mutation updateLimitUsersAccount($account: updateLimitUsersAccountInput!) {
    updateLimitUsersAccount(input: $account) {
      account {
        id
        _id
      }
    }
  }
`;

export const GET_ACCOUNT = gql`
  query account($id: ID!) {
    account(id: $id) {
      _id
      limitUsers
      modules {
        edges {
          node {
            _id
            name
          }
        }
      }
    }
  }
`;
