import { gql } from "@apollo/client";

export const CREATE_ACCOUNT = gql`
  mutation createAccount($account: createAccountInput!) {
    createAccount(input: $account) {
      account {
        id
        _id
      }
    }
  }
`;

export const CREATE_USER_IN_ACCOUNT = gql`
  mutation createInAccountUser($user: createInAccountUserInput!) {
    createInAccountUser(input: $user) {
      user {
        id
        _id
      }
    }
  }
`;

export const CREATE_ACCOUNT_USER = gql`
  mutation createAccountUser($user: createAccountUserInput!) {
    createAccountUser(input: $user) {
      user {
        id
        _id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($user: updateUserInput!) {
    updateUser(input: $user) {
      user {
        id
        _id
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($user: createUserInput!) {
    createUser(input: $user) {
      user {
        id
        _id
      }
    }
  }
`;

export const CREATE_USER_SECURITY_ANSWER = gql`
  mutation createUserSecurityAnswer($input: createUserSecurityAnswerInput!) {
    createUserSecurityAnswer(input: $input) {
      userSecurityAnswer {
        _id
      }
    }
  }
`;

export const USER_REGISTER_FACE_ID = gql`
  mutation registerFaceImageUser($input: registerFaceImageUserInput!) {
    registerFaceImageUser(input: $input) {
      user {
        firstName
        lastName
        country
        token
      }
    }
  }
`;

export const GET_ACCOUNTS_USERS = gql`
  {
    accounts {
      edges {
        node {
          _id
          name
          users {
            edges {
              node {
                _id
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  {
    getCollectionUsers {
      edges {
        node {
          id
          _id
          email
          firstName
          lastName
        }
      }
    }
  }
`;
