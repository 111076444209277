import React, { useState } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
} from "@chakra-ui/react";
import Card from "components/Card/Card";

const SinaisDeMercadoTab2 = (props) => {
  const { profession } = props;
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px" my="20px">
      <Card display="flex" justify="left" align="left" minH="0px">
        <Flex direction="column">
          <Text color="#000" fontSize="3xl" fontWeight="bold">
            Descrição sumária
          </Text>
          <Text color="#000" fontSize="md" fontWeight="regular">
            <div className="pre">{profession.tex_descricao}</div>
          </Text>
          <Text color="#000" fontSize="3xl" fontWeight="bold">
            Formação e experiência
          </Text>
          <Text color="#000" fontSize="md" fontWeight="regular">
            <div className="pre">{profession.tex_formacao}</div>
          </Text>
          <Text color="#000" fontSize="3xl" fontWeight="bold">
            <div className="pre">Condições gerais de exercício</div>
          </Text>
          <Text color="#000" fontSize="md" fontWeight="regular">
            <div className="pre">{profession.tex_atuacao}</div>
          </Text>
          <Text color="#000" fontSize="3xl" fontWeight="bold">
            Ocupações
          </Text>
          <Text color="#000" fontSize="md" fontWeight="regular">
            <div className="pre">{profession.txt_ocupacoes}</div>
          </Text>
          <br />
          <hr />
          <br />
          <Text color="#000" fontSize="md" fontWeight="regular">
            Fonte: EPSM-ObservaRH/NESCON/FM/UFMG a partir dos dados da
            Classificação Brasileira de Ocupações (CBO) do Ministério do
            Trabalho e Emprego (MTE). Algumas informações foram editadas em
            relação ao conteúdo original e outras foram complementadas com
            informações dos sites das entidades profissionais (conselhos,
            associações etc.).
          </Text>
        </Flex>
      </Card>
    </Grid>
  );
};

export default SinaisDeMercadoTab2;
